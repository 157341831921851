import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import * as UserAction from '../actions/useraction';
import { bindActionCreators } from 'redux';
import { sha3_512 } from 'js-sha3';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_choice_values, OpenApiBody, MimeTypes, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiLineEdit, LiDatePicker, LiBreadCrumbs, LiSelectList, LiSwitch, LiFileUpload, LiComboBox, LiComboList, LiLabel, LiStatusManager, LiStatusContainer, LiReactDialog, LiForm, LiCheckBox } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';
import { LiTable } from 'lisecdependantcomp';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import ChangeLogView from './changelogview';

const passwordPlaceHolder = 'TexT$$##**^^^^APOKaanaM****'
const baseUrl = window.location;
const theme = (conversionLib.getLocalStorageValue("core.app.theme") !== null) ? conversionLib.getLocalStorageValue("core.app.theme") : "LightTheme";
const userAvatarIcon = require('./images/' + theme + '/Avatar.png');
const userEditDataVal = {
    "interfaceInfo": {
        "type": "MT_USER_DATA",
        "version": 1
    },
    "userData": {
        "interfaceInfo": {
            "type": "MT_MASTER_DATA",
            "version": 1,
            "item_type": "ITEM_TYPE_MASTER_USER"
        },
    },
    "avatar": {
        "key": {
            "useCase": 11,
            "dataSource": "avatar",
            "dataType": "CFCT_BINARY"
        },
        "fileName": "asdf.png",
    }
}

const HeaderArrUserRolesChangeLog = {
    "auditId": ['99030638', 'Change ID'],
    "keyValues": ['99001073', 'Key Value'],
    "auditUser": ['99001942', 'User'],
    "auditType": ['99030639', 'Change type'],
    "auditDateTime": ['99030690', 'Modified date'],

};

const tableUserRolesChangeLogConfig = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.user_management_user_roles_change_log") !== null ? conversionLib.getLocalStorageValue("table.pagesize.user_management_user_roles_change_log") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": {
            "auditId": ['', 'Change ID'],
            "keyValues": ['', 'Key Value'],
            "auditUser": ['', 'User'],
            "auditType": ['', 'Change type'],
            "auditDateTime": ['', 'Modified date'],
        },
        "defaultSortingColumn": "auditDateTime",
        "defaultSortingOrder": "DESC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
        },
        "receiverFunction": "getDataFromUserRolesChangeLogTable"
    },
    "dataTypeConfiguration": {
        "auditId": "link",
        "keyValues": "text",
        "auditUser": "text",
        "auditType": "text",
        "auditDateTime": "text",

    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};

class UserEditComp extends Component {

    constructor() {
        super();
        this.state = {
            currentData: {},
            avatar_upload_open: false,
            image_binary_data: '',
            edit_save_disabled: false,
            delete_confirm_yes_disabled: false,
            edit_delete_open: false,
            allRole: [],
            allJobTitles: [],
            allRoleDetailObj: {},

            selectedUserRole: [],
            selectedJobTitles: [],

            entityIdArr: [],
            temp_avatar_val: '',
            addUserRoleDailog: false,
            addUserJobTitlesDailog: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            allselectedJobTitles: [],
            allselectedUserRole: [],
            reloadData: false,
            allRoleDelete: false,
            allRoleSelect: false,
            curUserID: 2,
            comboChoice: {},
            redirectPathUE: '',

            selectedRoleIDs: [],
            selectedJobTitleIDs: [],

            infoMessage: "",
            infoDailog: false,
            showInfoDialogUE: false,
            passwordField: passwordPlaceHolder,
            hwKeyField: passwordPlaceHolder,


            userRoleChangeLogDialog: false,
            userRolesChangeLog: [],
            showViewUserRolesChangeLogDialog: false,
            changeIdViewUserRolesChangeLogDialog: null,
        };

        this.count = 0;

        this.dataObjBreadCrumbsUE = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Users": {
                "label": <LiMultiterm textId="99028960" textVal="Users" />,
                "path": "/users_ng"
            },
            "Edit User": {
                "label": <LiMultiterm textId="99025173" textVal="Edit User" />,
                "path": "#"
            }
        }

        this.handleUploadImageCloseUserEdit = this.handleUploadImageCloseUserEdit.bind(this);
        this.closeInfoDailogUserEdit = this.closeInfoDailogUserEdit.bind(this);
        this.handleCloseResponseUserEdit = this.handleCloseResponseUserEdit.bind(this);

    }

    callbackWithArgFromEdit(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {

                this.props.resetUserDataAdd();
                this.props.setUserDataAddObject(jsonData.userData);
                this.props.setAvatarData(jsonData.avatar !== undefined ? jsonData.avatar.data : '');

                this.setState({
                    reloadData: true,
                    modifiedField: jsonData.userData.modifiedField,
                });

                this.setInitialRoles(jsonData.userData.masterUserRolesSubdata);
                this.setInitialJobTitles(jsonData.userData.masterUserJobTitleSubdata);
            }

            // let siteName = getSiteName();
            // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2107']);

            var roleTypeForUser = 'default';
            if (parseInt(this.props.usermgmt.userAddFieldObj.userTypeField) === 1) {
                roleTypeForUser = 'operator';
            }

            let siteName = getSiteName();
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserEdit.bind(this), [roleTypeForUser]);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.enableSaveButtonUserEdit.bind(this));
        }
    }

    setInitialRoles(masterUserRolesSubdata) {
        var roleArr = [];
        if (masterUserRolesSubdata.length > 0) {
            masterUserRolesSubdata.forEach((eachObj, index) => {
                roleArr.push(eachObj.roleIdField);
            });
        }

        this.setState({
            selectedRoleIDs: roleArr
        });
    }

    setInitialJobTitles(masterUserJobTitleSubdata) {
        var jobTitleArr = [];
        if (masterUserJobTitleSubdata.length > 0) {
            masterUserJobTitleSubdata.forEach((eachObj, index) => {
                jobTitleArr.push(eachObj.jobTitleIdField);
            });
        }

        this.setState({
            selectedJobTitleIDs: jobTitleArr
        });
    }





    enableSaveButtonUserEdit() {
        this.setState({ isSubmit: false });
    }

    handleUploadImageOpenUserEdit() {
        this.setState({
            avatar_upload_open: true,
            temp_avatar_val: this.props.usermgmt.imageBinaryDataView
        });
    }

    handleUploadImageCloseUserEdit() {
        this.setState({
            avatar_upload_open: false
        });
        this.props.setAvatarData(this.state.temp_avatar_val);
    }

    // userTypeMenuItems() {
    //     return Object.keys(this.state.comboChoice).map((name) => (
    //         <LiComboList key={name} value={parseInt(name)} className="LiCombo_selected_dark" > {<LiMultiterm textId={String(this.state.comboChoice[name][1])} textVal={this.state.comboChoice[name][0]} />} </LiComboList>
    //     ));
    // }

    enitityMenuItemsUserEdit() {
        return Object.keys(this.props.usermgmt.siteDeptArr).map((name) => (
            <LiComboList key={name} value={parseInt(name)} className="LiCombo_selected_dark">{this.props.usermgmt.siteDeptArr[name]}</LiComboList>
        ));
    }

    handleChangeUserTypeComboUserEdit = (value, name, event) => {
        this.props.setUserDataAdd('userTypeField', parseInt(value));

        this.setState({
            allRole: [],
            selectedRoleIDs: []
        });

        var roleTypeForUser = 'default';
        if (parseInt(value[0]) === 1) {
            roleTypeForUser = 'operator';
        }

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserEdit.bind(this), [roleTypeForUser]);
    }

    handleChangeEntityComboUserEdit = (value, name, event) => {
        this.props.setUserDataAdd('entityIdField', parseInt(value));
    }

    handleChangeEdit(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : (target.value);
        const name = event.target.name;

        this.props.setUserDataAdd(name, value);

        if (name === 'passwordField') {
            this.setState({ passwordField: value })
        }
        if (name === 'hwKeyField') {
            this.setState({ hwKeyField: value })
        }
    }


    handleBirtDateChangeUserEdit = (date) => {
        this.props.setUserDataAdd('birthdateField', date);
    }

    handleDateRestrictionFromChangeUserEdit = (date) => {
        this.props.setUserDataAdd('dateRestrictionFromField', usrLib.formatDate(date));
    }

    handleDateRestrictionToChangeUserEdit = (date) => {
        this.props.setUserDataAdd('dateRestrictionToField', usrLib.formatDate(date));
    }

    callbackWithArgForRoleObjUserEdit(responseData) {
        this.props.loader.showLoader({ "GET_Sites": false });
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var tempRolesDetArr = [], tempAllRoleArr = {}, jsonRoles = jsonData.roles;
                jsonRoles.forEach((eachRole) => {
                    var tempAllRoleArr1 = {}, checkVal = false;
                    tempAllRoleArr1['desc'] = eachRole.roleDescField;
                    if ((this.props.usermgmt.userExistingAssignArr).length > 0) {
                        var index = this.props.usermgmt.userExistingAssignArr.indexOf(eachRole.roleIdField);
                        if (index > -1) {
                            checkVal = true;
                        }
                    }
                    tempAllRoleArr1['checked'] = checkVal;
                    tempAllRoleArr1['subdata'] = this.props.usermgmt.siteDeptArr[eachRole.entityIdField] !== undefined ? this.props.usermgmt.siteDeptArr[eachRole.entityIdField] : '';
                    tempRolesDetArr.push(eachRole)
                    tempAllRoleArr[eachRole.roleIdField] = tempAllRoleArr1;
                })
                this.props.setAllUserArr(tempRolesDetArr);
                this.setState({ allRole: tempAllRoleArr, selectedUserRole: this.props.usermgmt.userExistingAssignArr });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForJobTitlesObjUserEdit(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var tempJobTitlesDetArr = [], tempJobTitlesArr = {}, jsonJobTitles = jsonData.jobTitles;
                jsonJobTitles.forEach((eachJobTitles) => {
                    var tempJobTitlesArr1 = {}, checkVal = false, index = this.props.usermgmt.eachUserJobTitles.indexOf(eachJobTitles.jobTitleIdField);
                    tempJobTitlesArr1['desc'] = eachJobTitles.jobTitleDescField;

                    if (index > -1) { checkVal = true; }

                    tempJobTitlesArr1['checked'] = checkVal;
                    tempJobTitlesDetArr.push(eachJobTitles)
                    tempJobTitlesArr[eachJobTitles.jobTitleIdField] = tempJobTitlesArr1;
                })
                this.props.setAllUserJobTitle(tempJobTitlesDetArr);
                this.setState({ selectedJobTitles: [], allJobTitles: tempJobTitlesArr })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackGetSiteUserEdit(responseDataSiteUE) {
        // LiLayoutActions.showLoader(false);
        // this.props.loader.showLoader({"GET_Sites": false});

        if (responseDataSiteUE.state.response.status === 200) {
            var jsonDataSiteUE = conversionLib.convertToJSON(responseDataSiteUE.state.response.data);
            if (jsonDataSiteUE !== undefined && Object.keys(jsonDataSiteUE).length !== 0) {
                var tempArrSiteUE = {};
                Object.keys(jsonDataSiteUE.companyEntities).forEach(function (k, item) {
                    tempArrSiteUE[jsonDataSiteUE.companyEntities[k].entityIdField] = jsonDataSiteUE.companyEntities[k].entityDescField
                })

                this.props.setUserSiteOrDept(tempArrSiteUE);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataSiteUE.state.response.status, responseDataSiteUE.state.response.message);
        }
    }

    callBackForGetUserRoles(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            var assignedRoleObject = {};

            var tempExistingAssignRoleArr = [];
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {

                Object.keys(jsonData.userRoles).forEach(function (k, item) {
                    tempExistingAssignRoleArr.push(jsonData.userRoles[k].roleIdField);
                    assignedRoleObject[jsonData.userRoles[k].roleIdField] = jsonData.userRoles[k];
                });

                //this.props.setExistingAssignUserRolesArr(tempExistingAssignRoleArr);
            }
            // else {
            //     //this.props.setExistingAssignUserRolesArr(tempExistingAssignRoleArr);
            // }

            this.setState({ assignedRoleDetails: assignedRoleObject });

            this.props.setExistingAssignUserRolesArr(tempExistingAssignRoleArr);

            // let siteName = getSiteName();
            // OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserEdit.bind(this));
        }
        else {
            this.props.setExistingAssignUserRolesArr([]);
            if (responseData.state.response.status !== 404 && responseData.state.response.status !== 204) {
                LiStatusManager.showStatus(responseData.state.response.status + " ", responseData.state.response.statusText !== undefined && responseData.state.response.statusText !== '' ? responseData.state.response.statusText : 'No Response')
            }
        }
    }

    callBackForGetUserJobTitles(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            var assignedJobTitleObject = {};

            var tempExistingJobTitleArr = []
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                Object.keys(jsonData.userJobTitles).forEach(function (k, item) {
                    tempExistingJobTitleArr.push(jsonData.userJobTitles[k].jobTitleIdField);
                    assignedJobTitleObject[jsonData.userJobTitles[k].jobTitleIdField] = jsonData.userJobTitles[k];
                })
                //this.props.setEachUserJobTitle(tempExistingJobTitleArr);
                this.props.setFullEachUserJobTitle(jsonData.userJobTitles);
            }
            // else {
            //     //this.props.setEachUserJobTitle(tempExistingJobTitleArr);
            // }

            this.setState({ assignedJobTitleDetails: assignedJobTitleObject });

            this.props.setEachUserJobTitle(tempExistingJobTitleArr);

            let siteName = getSiteName();
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_JobTitles(this.callbackWithArgForJobTitlesObjUserEdit.bind(this));
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }

    // callbackWithChoices = (responseData) => {
    //     if (responseData.state.response.status === 200) {
    //         var result = conversionLib.convertToJSON((responseData.state.response.data));
    //         this.setChoices(result.choice);
    //     }
    //     else {
    //         LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
    //     }

    //     var roleTypeForUser = 'default';
    //     if(parseInt(this.props.usermgmt.userAddFieldObj.userTypeField) === 1){
    //         roleTypeForUser = 'operator';
    //     }

    //     let siteName = getSiteName();
    //     OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserEdit.bind(this),[roleTypeForUser]);
    // }

    // setChoices = (choices) => {
    //     // var combo = {};
    //     if (choices[0].choiceEntry !== undefined) {
    //         var choiceArr = {};
    //         choices[0].choiceEntry.forEach((ChoiceText, index) => {
    //             choiceArr[ChoiceText.keyInt] = [ChoiceText.displayToken[0].tokenTxtfallback,ChoiceText.displayToken[0].tokenTxtno]
    //         })
    //     }
    //     this.setState({comboChoice : choiceArr})
    // }

    async componentDidMount() {
        var userID = (conversionLib.getParameterByName('userid', baseUrl.href) !== null && conversionLib.getParameterByName('userid', baseUrl.href) !== '') ? parseInt(conversionLib.getParameterByName('userid', baseUrl.href)) : 2;
        this.setState({ curUserID: userID });
        // LiLayoutActions.showLoader(true);
        this.props.loader.showLoader({ "GET_Sites": true });

        let num = [99002987, 99024415, 99008007, 99003381]
        let responseData = await getMultitermTexts(num)
        if (responseData.state.response !== undefined && responseData.state.response.data !== undefined && responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                transTexts: jsonData
            })
        }

        let siteName = getSiteName();

        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId_role(this.callBackForGetUserRoles.bind(this), userID);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId_jobTitle(this.callBackForGetUserJobTitles.bind(this), userID)
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId(this.callbackWithArgFromEdit.bind(this), userID);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackGetSiteUserEdit.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjEdit.bind(this));

        this.props.resetUserDataAdd();
    }

    componentWillUnmount() {
        this.props.resetUserDataAdd();
    }

    callbackWithArgForRoleObjEdit(responseDataRoleObjUE) {
        if (responseDataRoleObjUE.state.response.status === 200) {
            var jsonDataRoleObjUE = conversionLib.convertToJSON(responseDataRoleObjUE.state.response.data);
            if (jsonDataRoleObjUE !== undefined && Object.keys(jsonDataRoleObjUE).length !== 0) {
                var roleValObjUE = {};
                Object.keys(jsonDataRoleObjUE.roles).forEach(function (k, item) {
                    roleValObjUE[jsonDataRoleObjUE.roles[k].roleIdField] = jsonDataRoleObjUE.roles[k].roleDescField;
                })

                this.setState({ allRoleDetailObj: roleValObjUE });
            }
        }
        else {
            LiStatusManager.showStatus(responseDataRoleObjUE.state.response.status, responseDataRoleObjUE.state.response.message);
        }
    }

    callBackHandleUserAddData(responseData) {
        if (responseData.state.response.status === 200) {
            if (responseData.state.response.data !== undefined) {
                // var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
                if (responseData.state.response.status === 200) {
                    // LiLayoutActions.showLoader(false);
                    this.props.loader.showLoader({ "PUT_Users": false });

                    this.setState({
                        isSubmit: true,
                    });

                    // this.clearAddForm();

                    // var basename = conversionLib.getAppBaseNameRoute();
                    // window.location.href = basename + "users_ng";
                    this.setState({ redirectPathUE: "users_ng" });
                }
            }

            if (responseData.state.response.response !== undefined) {
                if (responseData.state.response.response.status === 500) {
                    // LiLayoutActions.showLoader(false);
                    this.props.loader.showLoader({ "PUT_Users": false });

                    //alert('Failed to modify user');
                    this.setState({ infoDailog: true, infoMessage: "Failed to modify user" });
                    return;
                }
            }
        }
        else {
            this.props.loader.showLoader({ "PUT_Users": false });
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    handleUserEditData(type, allTrue) {
        // console.log(this.state.selectedRoleIDs);
        if (!allTrue) {
            return
        }

        var userIdField = (conversionLib.getParameterByName('userid', baseUrl.href) !== null && conversionLib.getParameterByName('userid', baseUrl.href) !== '') ? parseInt(conversionLib.getParameterByName('userid', baseUrl.href)) : 2;

        var firstNameField = this.props.usermgmt.userAddFieldObj.firstNameField;
        var lastNameField = this.props.usermgmt.userAddFieldObj.lastNameField;
        var loginNameField = this.props.usermgmt.userAddFieldObj.loginNameField;
        var personalNumberField = this.props.usermgmt.userAddFieldObj.personalNumberField;
        var abbreviationField = this.props.usermgmt.userAddFieldObj.abbreviationField;
        var pwAlgorithmField = this.props.usermgmt.userAddFieldObj.pwAlgorithmField;

        if (this.state.passwordField !== '' && this.state.passwordField !== passwordPlaceHolder) {
            var arrayBuffer = sha3_512.arrayBuffer(this.state.passwordField);
            var base64StringPassword = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
            userEditDataVal.userData.passwordField = base64StringPassword;
        }
        if (this.state.hwKeyField !== '' && this.state.hwKeyField !== passwordPlaceHolder) {
            var arrayBuffer = sha3_512.arrayBuffer(this.state.hwKeyField);
            var base64StringPassword = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
            userEditDataVal.userData.hwKeyField = base64StringPassword;
        }

        var emailField = this.props.usermgmt.userAddFieldObj.emailField;
        var osuserYnField = this.props.usermgmt.userAddFieldObj.osuserYnField;
        var adminYnField = this.props.usermgmt.userAddFieldObj.adminYnField;
        var lockedYnField = this.props.usermgmt.userAddFieldObj.lockedYnField;
        var disabledField = this.props.usermgmt.userAddFieldObj.disabledField;
        var dateRestrictionField = this.props.usermgmt.userAddFieldObj.dateRestrictionField;
        var birthdateField = this.props.usermgmt.userAddFieldObj.birthdateField;
        var dateRestrictionFromField = this.props.usermgmt.userAddFieldObj.dateRestrictionFromField;
        var dateRestrictionToField = this.props.usermgmt.userAddFieldObj.dateRestrictionToField;
        var userTypeField = this.props.usermgmt.userAddFieldObj.userTypeField;
        var entityIdField = this.props.usermgmt.userAddFieldObj.entityIdField;
        var pwdChangeReqField = this.props.usermgmt.userAddFieldObj.pwdChangeReqField;

        this.setState({ isSubmit: true });

        if (lastNameField.trim() === "" || firstNameField.trim() === "" || emailField.trim() === "" || loginNameField.trim() === "") {
            this.setState({ showInfoDialogUE: true });
            return;
        }

        if (userTypeField === -99) {
            this.setState({ infoDailog: true, infoMessage: <LiMultiterm textId="99030935" textVal="Select user type" /> });
            return;
        }

        if (userIdField !== '') {
            userEditDataVal.userData.userIdField = userIdField;
        }
        else {
            userEditDataVal.userData.userIdField = -1;
        }

        userEditDataVal.userData.firstNameField = firstNameField;
        userEditDataVal.userData.lastNameField = lastNameField;
        userEditDataVal.userData.loginNameField = loginNameField;
        userEditDataVal.userData.personalNumberField = personalNumberField;
        userEditDataVal.userData.abbreviationField = abbreviationField;
        userEditDataVal.userData.pwAlgorithmField = pwAlgorithmField;
        userEditDataVal.userData.emailField = emailField;
        userEditDataVal.userData.osuserYnField = osuserYnField;
        userEditDataVal.userData.adminYnField = adminYnField;
        userEditDataVal.userData.lockedYnField = lockedYnField;
        userEditDataVal.userData.disabledField = disabledField;
        userEditDataVal.userData.dateRestrictionField = dateRestrictionField;
        userEditDataVal.userData.birthdateField = birthdateField;
        userEditDataVal.userData.userTypeField = userTypeField;
        userEditDataVal.userData.pwdChangeReqField = pwdChangeReqField;

        userEditDataVal.userData.modifiedField = this.state.modifiedField;

        if (dateRestrictionField !== 0) {
            userEditDataVal.userData.dateRestrictionFromField = dateRestrictionFromField;
            userEditDataVal.userData.dateRestrictionToField = dateRestrictionToField;
        }

        if (entityIdField !== 0) {
            userEditDataVal.userData.entityIdField = entityIdField;
        }

        if (this.editor) {
            var canvasData = this.editor.getImageScaledToCanvas().toDataURL();
            userEditDataVal.avatar.data = canvasData.replace(/^data:image\/[a-z]+;base64,/, "");
        }

        if (this.props.usermgmt.imageBinaryDataView !== '') {
            userEditDataVal.avatar.data = this.props.usermgmt.imageBinaryDataView.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        else {
            userEditDataVal.avatar.data = this.props.usermgmt.imageBinaryDataView;
        }

        var finalRoleArrUE = [];
        if (this.state.selectedRoleIDs.length > 0) {
            this.state.selectedRoleIDs.forEach((eachRoleID, index) => {
                var eachRoleObjUE = {
                    "roleIdField": parseInt(eachRoleID),
                    "userIdField": userIdField,
                    // "masterRoleSubdata": this.state.assignedRoleDetails[parseInt(eachRoleID)],
                    "interfaceInfo": {
                        "type": "MT_MASTER_DATA",
                        "version": 0,
                        "itemType": "ITEM_TYPE_MASTER_USER_ROLES"
                    }
                };

                finalRoleArrUE.push(eachRoleObjUE);
            });
        }

        userEditDataVal.userData.masterUserRolesSubdata = finalRoleArrUE;

        var finalJobTitleArrUE = [];
        if (this.state.selectedJobTitleIDs.length > 0) {
            var cntr = 1;
            this.state.selectedJobTitleIDs.forEach((eachJobTitleID, index) => {
                var eachJobTitleObjUE = {
                    "jobTitleIdField": parseInt(eachJobTitleID),
                    "userIdField": userIdField,
                    "fromDateField": "2018/08/08",
                    "jobTitleSeqField": cntr,
                    // "masterJobTitleSubdata": this.state.assignedJobTitleDetails[parseInt(eachJobTitleID)],
                    "interfaceInfo": {
                        "type": "MT_MASTER_DATA",
                        "version": 0,
                        "itemType": "ITEM_TYPE_MASTER_USER_JOB_TITLE"
                    }
                };

                finalJobTitleArrUE.push(eachJobTitleObjUE);
                cntr++;
            });
        }

        userEditDataVal.userData.masterUserJobTitleSubdata = finalJobTitleArrUE;

        if (type === 2) {
            // LiLayoutActions.showLoader(true);
            this.props.loader.showLoader({ "PUT_Users": true });

            const key = "usermgmt.userlist.purusers";
            // LiLayoutActions.cancelRequestKey(key);
            window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

            let siteName = getSiteName();

            OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Users(this.callBackHandleUserAddData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, userEditDataVal), true, key);
        }
    }


    handleUserRoleUserEdit(ev, value, isCheckall, tempp, allAvailable, currentStatus) {
        let roleIDArr = this.state.selectedRoleIDs;
        if (isCheckall === 1) {
            roleIDArr = allAvailable;
        }
        else if (isCheckall === 0) {
            roleIDArr = [];
        }
        else {
            if (currentStatus) {
                roleIDArr.push(parseInt(value)); // add item
            }
            else {
                let index = roleIDArr.indexOf(parseInt(value));
                if (index > -1) {
                    roleIDArr.splice(index, 1); // remove item
                }
            }
        }

        this.setState({ selectedRoleIDs: roleIDArr });
    }

    callbackWithArgFromChangeLog(responseData) {
        this.props.loader.showLoader({ "GET_change_log_loader": false });

        if (responseData.state.response.status === 200) {
            this.setState({ userRoleChangeLogDialog: true })

            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            if (jsonData && jsonData !== undefined) {
                for (let i = 0; i < jsonData.length; i = i + 1) {
                    jsonData[i].auditDateTime = usrLib.formatDate(jsonData[i].auditDateTime)
                }

                let transObj = {}
                for (let i = 0; i < this.state.transTexts.length; i = i + 1) {
                    transObj[this.state.transTexts[i].number] = this.state.transTexts[i].text;
                }
                const transObj1 = {
                    "0": '99003381',
                    "1": '99024415',
                    "2": '99002987',
                    "3": '99008007',
                }

                jsonData.map(element => {
                    element.auditType = transObj[transObj1[element.auditType]]
                });

                this.setState({ userRolesChangeLog: jsonData });
            }
            else {
                this.setState({ userRolesChangeLog: [] });
            }

        }
        else if (responseData.state.response.status === 204) {
            this.setState({ userRoleChangeLogDialog: true })
            this.setState({ userRolesChangeLog: [] });
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }
    handleChangeLogButtonClick() {
        let siteName = getSiteName();

        this.props.loader.showLoader({ "GET_change_log_loader": true });
        this.state.curUserID && OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_UserRoles_partialFilteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "UserIdField": this.state.curUserID }));
    }
    closeUserRoleChangeLog() {
        this.setState({ userRoleChangeLogDialog: false })
    }
    closeUserRoleViewChangeLog() {
        this.setState({ showViewUserRolesChangeLogDialog: false })
    }

    getDataFromUserRolesChangeLogTable(reasonRowID, recData, actionName, dummy1, dummy2, dummy3, actions) {
        if (actions.key === "View") {
            this.setState({
                showViewUserRolesChangeLogDialog: true,
                changeIdViewUserRolesChangeLogDialog: recData[reasonRowID].value.auditId,
            });
        }
    }



    userRoleChangeLogDialog() {
        return (
            <LiReactDialog
                enableStoring="user_management_user_roles_change_log"
                title={<LiMultiterm textId="" textVal="User Roles Change Log" />}
                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                onClose={this.closeUserRoleChangeLog.bind(this)}
                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                width={1200} height={600} buttons={
                    [
                        {
                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                            onClick: () => this.closeUserRoleChangeLog(),
                            className: "LiGeneral_raisedButton"
                        }
                    ]
                }
            >
                <LiTable
                    tableId="user_management_user_roles_change_log"
                    config={tableUserRolesChangeLogConfig}
                    colHeaderArr={HeaderArrUserRolesChangeLog}
                    rowArr={this.state.userRolesChangeLog}
                    receiverFunction={this.getDataFromUserRolesChangeLogTable.bind(this)}
                    handleData={() => { }}
                    subTable={false}
                    enableColSearch={true}
                    linkData={this.linkData.bind(this)}
                />
            </LiReactDialog>
        )
    }

    showViewUserRolesChangeLogDialog() {
        return (
            <LiReactDialog
                enableStoring="user_management_user_roles_view_change_log"
                title={<LiMultiterm textId="" textVal="User Roles View Change Log" />}
                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                onClose={this.closeUserRoleViewChangeLog.bind(this)}
                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                width={1000} height={600} buttons={
                    [
                        {
                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                            onClick: () => this.closeUserRoleViewChangeLog(),
                            className: "LiGeneral_raisedButton"
                        }
                    ]
                }
            >
                <ChangeLogView auditId={this.state.changeIdViewUserRolesChangeLogDialog} dataType="userroles" loader={this.props.loader} />
            </LiReactDialog>
        )
    }

    handleJobTitlesUserEdit(ev, value, isCheckall, tempp, allAvailable, currentStatus) {

        let jobTitleIDArr = this.state.selectedJobTitleIDs;
        if (isCheckall === 1) {
            jobTitleIDArr = allAvailable;
        }
        else if (isCheckall === 0) {
            jobTitleIDArr = [];
        }
        else {
            if (currentStatus) {
                jobTitleIDArr.push(parseInt(value)); // add item
            }
            else {
                let index = jobTitleIDArr.indexOf(parseInt(value));
                if (index > -1) {
                    jobTitleIDArr.splice(index, 1); // remove item
                }
            }
        }

        this.setState({ selectedJobTitleIDs: jobTitleIDArr });
    }

    onUploadCompletedUserEdit = (file, content) => {
    }

    onAvatarUploadedUserEdit = (file, content) => {
    }

    getAvatarImageUserEdit = (image) => {
        this.props.setAvatarData(image.replace(/^data:image\/[a-z]+;base64,/, ""));
        this.setState({
            avatar_upload_open: false
        });
    }


    handleCloseResponseUserEdit() {
        // this.setState({isSubmit: false});

        this.setState({ showInfoDialogUE: false });
    }

    closeInfoDailogUserEdit() {
        this.setState({ infoDailog: false })
    }

    linkData(rowData) {
        this.setState({ currentAction: 'View' });

        this.setState({
            showViewUserRolesChangeLogDialog: true,
            changeIdViewUserRolesChangeLogDialog: rowData.value,
        });


    }


    render() {
        let openuserRoleChangeLogDialog = this.state.userRoleChangeLogDialog ? this.userRoleChangeLogDialog() : null
        let openViewUserRolesChangeLogDialogDialog = this.state.showViewUserRolesChangeLogDialog ? this.showViewUserRolesChangeLogDialog() : null


        const stateRequiredUE = [
            { "FirstName": [<LiMultiterm textId="99024423" textVal="First Name" />, this.props.usermgmt.userAddFieldObj.firstNameField] },
            { "LastName": [<LiMultiterm textId="99024422" textVal="Last Name" />, this.props.usermgmt.userAddFieldObj.lastNameField] },
            { "LoginName": [<LiMultiterm textId="99010629" textVal="Login Name" />, this.props.usermgmt.userAddFieldObj.loginNameField] },
            { "Password": [<LiMultiterm textId="6001858" textVal="Password" />, this.state.passwordField] },
            { "Email": [<LiMultiterm textId="99021171" textVal="Email" />, this.props.usermgmt.userAddFieldObj.emailField] },
            { "UserType": [<LiMultiterm textId="99024421" textVal="User Type" />, this.props.usermgmt.userAddFieldObj.userTypeField] },

        ]

        let self = this;


        if (this.props.usermgmt.userAddFieldObj !== undefined) {
            let activeDirectoryUser = this.props.usermgmt.userAddFieldObj.activeDirectoryUser;
            return (
                <div>
                    {this.state.redirectPathUE !== undefined && this.state.redirectPathUE !== '' ? <Redirect to={this.state.redirectPathUE} /> : null}
                    <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsUE} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                    <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 200)} containerID="LiUsrMgmtUserEditSub1" containerScrollY={true} containerScrollX={false} >

                        <div className={this.state.theme + " userMgmtGridContainer"} >

                            <div className="userMgmtGridContainerItem" style={{ textAlign: 'center' }}>
                                <img alt="avatar" id="userAva" src={this.props.usermgmt.imageBinaryDataView !== '' ? `data:image/jpeg;base64,${this.props.usermgmt.imageBinaryDataView}` : userAvatarIcon} border="0" onClick={this.handleUploadImageOpenUserEdit.bind(this)} />
                            </div>
                            <div className="userMgmtGridContainerItem">
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="" textVal="Active Directory User" />} liEditDisable={true} isMandatory={false} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiCheckBox LiCheckBoxID="c1" LiCheckBoxClassName="c1" LiCheckBoxChecked={activeDirectoryUser ? true : false} />
                                    </div>
                                </div>
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99024423" textVal="First Name" />} liEditDisable={true} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="firstNameField" liEditName="firstNameField" liReadOnly={activeDirectoryUser ? true : false} liEditValue={this.props.usermgmt.userAddFieldObj.firstNameField !== undefined ? this.props.usermgmt.userAddFieldObj.firstNameField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99024422" textVal="Last Name" />} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="lastNameField" liEditName="lastNameField" liReadOnly={activeDirectoryUser ? true : false} liEditValue={this.props.usermgmt.userAddFieldObj.lastNameField !== undefined ? this.props.usermgmt.userAddFieldObj.lastNameField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>
                                </div>

                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99010629" textVal="Login Name" />} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="loginNameField" liEditName="loginNameField" liReadOnly={activeDirectoryUser ? true : false} liEditValue={this.props.usermgmt.userAddFieldObj.loginNameField !== undefined ? this.props.usermgmt.userAddFieldObj.loginNameField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="6001858" textVal="Password" />} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="passwordField" liEditName="passwordField" liEditValue={this.state.passwordField !== undefined ? this.state.passwordField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} liPassword={true} liEditDisable={false} />
                                    </div>
                                </div>
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99028908" textVal="Personnel Number" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="personalNumberField" liEditName="personalNumberField" liEditValue={this.props.usermgmt.userAddFieldObj.personalNumberField !== undefined ? this.props.usermgmt.userAddFieldObj.personalNumberField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99023790" textVal="Abbreviation" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="abbreviationField" liEditName="abbreviationField" liEditValue={this.props.usermgmt.userAddFieldObj.abbreviationField !== undefined ? this.props.usermgmt.userAddFieldObj.abbreviationField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>
                                </div>

                                <div className="userMgmtGridContainerInner">
                                    {/* <div className="userMgmtGridContainerInnerItem" style={{verticalAlign:'center'}}>
                                <LiLabel labelText="Password Algorithm" />
                            </div>
                            <div className="userMgmtGridContainerInnerItem">
                                <LiLineEdit liEditID="pwAlgorithmField" liEditName="pwAlgorithmField" liEditValue={this.props.usermgmt.userAddFieldObj.pwAlgorithmField !== undefined ? this.props.usermgmt.userAddFieldObj.pwAlgorithmField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)}  liReadOnly={true} />
                            </div> */}
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99021171" textVal="Email" />} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="emailField" liEditName="emailField" liReadOnly={activeDirectoryUser ? true : false} liEditValue={this.props.usermgmt.userAddFieldObj.emailField !== undefined ? this.props.usermgmt.userAddFieldObj.emailField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} />
                                    </div>

                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99028796" textVal="Hardware ID" />} />
                                    </div>

                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLineEdit liEditID="hwKeyField" liEditName="hwKeyField" liEditValue={this.state.hwKeyField !== undefined ? this.state.hwKeyField : ''} liEditOnChange={this.handleChangeEdit.bind(this)} liEditOnKeyUp={this.handleChangeEdit.bind(this)} liPassword={true} liEditDisable={false}/>
                                    </div>
                                </div>
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99028910" textVal="OS User" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.state.reloadData ?
                                                <LiSwitch switchName="osuserYnField" switchId="osuserYnField" switchChecked={this.props.usermgmt.userAddFieldObj.osuserYnField === 1 ? true : false} switchDisabled={false} switchVal="osuserYnField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99004030" textVal="Administrator" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.state.reloadData ?
                                                <LiSwitch switchName="adminYnField" switchId="adminYnField" switchChecked={this.props.usermgmt.userAddFieldObj.adminYnField === 1 ? true : false} switchDisabled={false} switchVal="adminYnField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99028585" textVal="Locked" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.state.reloadData ?
                                                <LiSwitch switchName="lockedYnField" switchId="lockedYnField" switchChecked={this.props.usermgmt.userAddFieldObj.lockedYnField === 1 ? true : false} switchDisabled={false} switchVal="lockedYnField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99020812" textVal="Disabled" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.state.reloadData ?
                                                <LiSwitch switchName="disabledField" switchId="disabledField" switchChecked={this.props.usermgmt.userAddFieldObj.disabledField === 1 ? true : false} switchDisabled={false} switchVal="disabledField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99012483" textVal="Date Restriction" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.state.reloadData ?
                                                <LiSwitch switchName="dateRestrictionField" switchId="dateRestrictionField" switchChecked={this.props.usermgmt.userAddFieldObj.dateRestrictionField === 1 ? true : false} switchDisabled={false} switchVal="dateRestrictionField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99028911" textVal="Date of Birth" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.props.usermgmt.userAddFieldObj.birthdateField !== '' ?
                                                <LiDatePicker dateFormat="YYYY/MM/DD" id="birthdateField" onDateChanged={this.handleBirtDateChangeUserEdit.bind(this)} dateVal={this.props.usermgmt.userAddFieldObj.birthdateField} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                                {
                                    this.props.usermgmt.userAddFieldObj.dateRestrictionField === 1 ?
                                        <div className="userMgmtGridContainerInner">
                                            <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                                <LiLabel labelText={<LiMultiterm textId="99028926" textVal="Date Restriction From" />} />
                                            </div>
                                            <div className="userMgmtGridContainerInnerItem">
                                                {
                                                    this.props.usermgmt.userAddFieldObj.dateRestrictionFromField !== '' ?
                                                        <LiDatePicker id="daterestrictionfrom" onDateChanged={this.handleDateRestrictionFromChangeUserEdit.bind(this)} dateVal={this.props.usermgmt.userAddFieldObj.dateRestrictionFromField} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                                        :
                                                        ''
                                                }
                                            </div>
                                            <div className="userMgmtGridContainerInnerItem">
                                                <LiLabel labelText={<LiMultiterm textId="99029990" textVal="Date restriction to" />} />
                                            </div>
                                            <div className="userMgmtGridContainerInnerItem">
                                                {
                                                    this.props.usermgmt.userAddFieldObj.dateRestrictionToField !== '' || this.props.usermgmt.userAddFieldObj.dateRestrictionToField !== undefined ?
                                                        <LiDatePicker id="daterestrictionto" onDateChanged={this.handleDateRestrictionToChangeUserEdit.bind(this)} dateVal={this.props.usermgmt.userAddFieldObj.dateRestrictionToField} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                }
                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} isMandatory={true} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.props.usermgmt.userAddFieldObj.entityIdField !== 0
                                                ?
                                                <LiComboBox id="userTypeField" name="userTypeField" disabled={activeDirectoryUser ? true : false} value={parseInt(this.props.usermgmt.userAddFieldObj.userTypeField)} multiSelect={false} onSelection={this.handleChangeUserTypeComboUserEdit.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>
                                                    <LiComboList key={"0"} value={0}>{<LiMultiterm textId="99001446" textVal="Default" />}</LiComboList>
                                                    <LiComboList key={"1"} value={1}>{<LiMultiterm textId="99030183" textVal="Shopfloor Operator" />}</LiComboList>
                                                    <LiComboList key={"2"} value={2}>{<LiMultiterm textId="99030184" textVal="Shopfloor Workstation" />}</LiComboList>
                                                    {/* {this.userTypeMenuItems()}    */}
                                                </LiComboBox>
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            this.props.usermgmt.userAddFieldObj.entityIdField !== 0
                                                ?
                                                <LiComboBox value={(this.props.usermgmt.userAddFieldObj.entityIdField)} id="entityIdField" name="entityIdField" multiSelect={false} onSelection={this.handleChangeEntityComboUserEdit.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>
                                                    {this.enitityMenuItemsUserEdit()}
                                                </LiComboBox>
                                                :
                                                ''
                                        }
                                    </div>
                                </div>

                                <div className="userMgmtGridContainerInner">
                                    <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                        <LiLabel labelText={<LiMultiterm textId="99031611" textVal="Password change required" />} />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        {
                                            <LiSwitch switchName="pwdChangeReqField" switchId="pwdChangeReqField" switchChecked={this.props.usermgmt.userAddFieldObj.pwdChangeReqField === 1 ? true : false} switchDisabled={false} switchVal="pwdChangeReqField" switchOnChangeHandler={this.handleChangeEdit.bind(this)} />
                                        }
                                    </div>
                                </div>

                                <div className="userMgmtGridContainerInnerSub">
                                    <div className="userMgmtGridContainerInnerItem">
                                        &#160;
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        <br />
                                        {activeDirectoryUser ?
                                                <LiSelectList
                                                selectlistdata={this.state.allRole}
                                                selectListName="selectlist_roles"
                                                selectListTitle={<LiMultiterm textId="" textVal="User roles" />}
                                                selectListTitleButton={<LiMultiterm textId="99025943" textVal="Change log" />}
                                                selectListTitleButtonClick={this.handleChangeLogButtonClick.bind(this)}
                                                selectListonChange={this.handleUserRoleUserEdit.bind(this)}
                                            />
                                            :
                                            <LiSelectList
                                                selectlistdata={this.state.allRole}
                                                selectListName="selectlist_roles"
                                                selectListTitle={<LiMultiterm textId="99029991" textVal="User roles" />}
                                                selectListTitleButton={<LiMultiterm textId="99025943" textVal="Change log" />}
                                                selectListTitleButtonClick={this.handleChangeLogButtonClick.bind(this)}
                                                selectListonChange={this.handleUserRoleUserEdit.bind(this)}
                                            />
                                        }
                                        <br />
                                    </div>
                                    <div className="userMgmtGridContainerInnerItem">
                                        &#160;
                                    </div>
                                </div>
                            </div>

                        </div>
                    </LiDivContainer>

                    <LiDivContainer containerHeight="70" containerID="LiUsrMgmtUserEditSub2" containerScrollY={false} containerScrollX={false} >
                        <hr className={this.state.theme + " LiUsrMgmtBottom"} />
                        <div className="userMgmtGridContainerFull">
                            <div className="userMgmtGridContainerFullItem">
                                {/* <LiButton
                                buttonOnClickHandler={this.handleUserEditData.bind(this, 2)}
                                buttonLabel={<LiMultiterm textId="99004199" textVal="Save Changes" />}
                            /> */}
                                <LiForm
                                    height={350}
                                    formDialogTitle={<LiMultiterm textId="4000070" textVal="Warning" />}
                                    formDialogCloseText={<LiMultiterm textId="99000136" textVal="OK" />}
                                    requiredByState={stateRequiredUE}
                                    requiredById={[]}
                                    allRequiredFilled={this.handleUserEditData.bind(this, 2)}
                                    buttonLabel={<LiMultiterm textId="99004199" textVal="Save Changes" />}
                                    formMessage={<LiMultiterm textId="99030257" textVal="Please fill all the required fields" />}
                                />
                                <Link to="/users_ng">
                                    <LiButton
                                        // buttonOnClickHandler={this.handleUseCancel.bind(this)}
                                        buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                    />
                                </Link>
                            </div>
                        </div>
                        {
                            this.state.avatar_upload_open
                                ?
                                <LiReactDialog
                                    enableStoring="li.user.avatar.upload"
                                    title={<LiMultiterm textId="99031019" textVal="Upload Avatar" />}
                                    actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                    modal={true}
                                    hasCloseIcon={true}
                                    onClose={this.handleUploadImageCloseUserEdit.bind(this)}
                                    isResizable={true}
                                    isDraggable={true}
                                    height={650}
                                    width={700}
                                    buttons={
                                        [
                                            {
                                                text: <LiMultiterm textId="1001439" textVal="Close" />,
                                                onClick: () => this.handleUploadImageCloseUserEdit()
                                            }
                                        ]
                                    }
                                >
                                    <div className="LiuserEditMainDiv" style={{ width: "90%", margin: "auto" }}>
                                        <LiFileUpload
                                            onUploadCompleted={this.onUploadCompletedUserEdit.bind(this)}
                                            onAvatarUploaded={this.onAvatarUploadedUserEdit.bind(this)}
                                            fileFormat={['image/gif', 'image/jpeg', 'image/png']}
                                            isMultiple={false}
                                            theme={this.state.theme}
                                            getAvatarImage={this.getAvatarImageUserEdit.bind(this)}
                                            openavatar={true}
                                        />
                                    </div>
                                </LiReactDialog>
                                : ''
                        }

                        {
                            this.state.infoDailog
                                ?
                                <LiReactDialog
                                    type="Response"
                                    enableStoring="li.user.edit.infodailog"
                                    title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                    modal={true}
                                    isResizable={false}
                                    hasCloseIcon={false}
                                    width={300}
                                    height={200}
                                    buttons={
                                        [{
                                            text: <LiMultiterm textId="99000136" textVal="OK" />,
                                            onClick: () => this.closeInfoDailogUserEdit()
                                        }]
                                    }
                                    onClose={this.closeInfoDailogUserEdit.bind(this)}
                                    isFooterEnable={false}
                                >
                                    <p>{this.state.infoMessage}</p>
                                </LiReactDialog>
                                :
                                ""
                        }
                        {
                            this.state.showInfoDialogUE
                                ?
                                <LiReactDialog
                                    enableStoring="server.response.user.edit"
                                    title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                    modal={true}
                                    isResizable={false}
                                    hasCloseIcon={false}
                                    width={300}
                                    height={200}
                                    buttons={
                                        [{
                                            text: <LiMultiterm textId="99000136" textVal="OK" />,
                                            onClick: () => this.handleCloseResponseUserEdit()
                                        }]
                                    }
                                    onClose={this.handleCloseResponseUserEdit.bind(this)}
                                    isFooterEnable={false}
                                >
                                    <p><LiMultiterm textId="99030257" textVal="Please fill all the required fields" />.</p>
                                    <p><LiMultiterm textId="99030605" textVal="Mandatory fields are marked with" /> <span className="user_mandatoryFieldNote">*</span></p>
                                </LiReactDialog>
                                :
                                ""
                        }
                        {openuserRoleChangeLogDialog}
                        {openViewUserRolesChangeLogDialogDialog}


                        <LiStatusContainer />
                    </LiDivContainer>
                </div >
            );
        }
        else {
            return (
                <div>xxxxx</div>
            )
        }
    }
}


function mapStateToProps(state) {
    return {
        usermgmt: state.usermgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserRole: UserAction.setUserRole,
        setUserJobTitles: UserAction.setUserJobTitles,
        setUserDataAdd: UserAction.setUserDataAdd,
        resetUserDataAdd: UserAction.resetUserDataAdd,
        setUserDataAddObject: UserAction.setUserDataAddObject,
        setAvatarData: UserAction.setAvatarData,
        setAvailUserRolesArr: UserAction.setAvailUserRolesArr,
        setAssignUserRolesArr: UserAction.setAssignUserRolesArr,
        setExistingAssignUserRolesArr: UserAction.setExistingAssignUserRolesArr,
        setEachUserJobTitle: UserAction.setEachUserJobTitle,
        setAllUserArr: UserAction.setAllUserArr,
        setAllUserJobTitle: UserAction.setAllUserJobTitle,
        setUserSiteOrDept: UserAction.setUserSiteOrDept,
        setFullEachUserJobTitle: UserAction.setFullEachUserJobTitle,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditComp);