import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as SiteAction from '../actions/siteaction';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiBreadCrumbs, LiStatusManager } from 'liseccomponents';
import { LiMasterDataOverview } from 'lisecdependantcomp';

import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';

var deleteCount = 0;
/*--------------------------- Table Configuration -------------------------------*/
const siteTableConfiguration = {
    "getRowDataConfiguration": {
        "enableRowData": true,
    },
    "linkConfiguration": {
        "enableLink": false,
        "link": "/site_edit_ng?entityid="
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.site.list") !== null ? conversionLib.getLocalStorageValue("table.pagesize.site.list") : 20,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": { "entityIdField": ["99001692", "ID #"], "entityDescField": ["6001104", "Name"], "entityShdescField": ["99002657", "Short Desc"], "disabledField": ["99023501", "Disabled"], "hostIpField": ["99028779", "Host IP"], "portOffsetField": ["99030902", "Base Port"], "dbTypeField": ["99028780", "DB Type"] },                      //Only Column Header Name
        "defaultSortingColumn": "entityIdField",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["entityIdField", "entityDescField", "entityShdescField", "disabledField", "hostIpField", "portOffsetField", "dbTypeField"],           //Only Row Key 
        "searchOptionColumns": { "status": { "option1": "Online", "option2": "Offline" }, "dept": { "option1": "Accounting", "option2": "Machinery", "option3": "Software", "option4": "IT" } }
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
            "Edit": ['6001743', "Edit"],
            "Delete": ['99003381', "Delete"],
            "Change log": ['99025943', "Change log"],
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "entityIdField": "link",
        "entityDescField": "text",
        "entityShdescField": "text",
        "disabledField": "number",
    },
    "multiSelectable": {
        "enabled": true,
        "showCheckboxes": true
    },
    "enableTrigger": false
};

class SiteList extends Component {
    constructor() {
        super();
        this.state = {
            reloadSiteData: false,
            site_list_delete_confirm_yes_disabled: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            selectedRowData: [],
            enableRouteSL: {},
            currentActionSL: '',
            deleteAllSite: false,
            multitermTextCommon: {
                yesText: <LiMultiterm textId="99001613" textVal="Yes" />,
                noText: <LiMultiterm textId="99000054" textVal="No" />,
            },
            multitermText: {
                deleteSingleTitle: <LiMultiterm textId="99031020" textVal="Delete Site" />,
                deleteSingleText: <LiMultiterm textId="99031022" textVal="Do you want to delete the site" />,
                deleteSelectedTitle: <LiMultiterm textId="99031020" textVal="Delete Site" />,
                deleteSelectedText: <LiMultiterm textId="99031021" textVal="Do you want to delete selected sites ?" />,
            }
        }
        this.getDataFromTable = this.getDataFromTable.bind(this);
        this.handleDialogCloseSiteList = this.handleDialogCloseSiteList.bind(this);
        this.handleLoadSiteList = this.handleLoadSiteList.bind(this);
        this.handleDeleteSiteData = this.handleDeleteSiteData.bind(this);
        this.callbackWithArgForDeleteSiteData = this.callbackWithArgForDeleteSiteData.bind(this);
        this.callbackhandleDeleteAllSiteData = this.callbackhandleDeleteAllSiteData.bind(this);

        this.dataObjBreadCrumbsSL = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Sites": {
                "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                "path": "/sites_ng"
            }
        }
    }

    callBackHandlGetLanguageLangID3TextSiteList(callbackAfterSet, responseData) {
        if (responseData.state.response !== undefined && responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            var tempArr = {};
            Object.keys(jsonData).forEach(function (k, item) {
                tempArr[jsonData[k].number] = jsonData[k].text
            })
            this.setState({ multilangDataVal: tempArr })
            callbackAfterSet(tempArr)
            this.props.loader.showLoader({ "qp_multilang": false });
        }
        else {
            this.props.loader.showLoader({ "qp_multilang": false });
            console.log("RESPONSE : " + responseData.state.response);
        }
    }

    async callbackWithArgForSiteList(responseData) {
        this.props.loader.showLoader({ "GET_Site": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            Object.keys(this.props.sitemgmt.siteHeaderArr).forEach(headKey => {
                jsonData.companyEntities.forEach((user) => {
                    if (user[headKey] === undefined) {
                        user[headKey] = ''
                    }
                });
            });

            var textNumberArr = ['99001613', '99000054'];

            const callbackAfterSet = (tempArr) => {
                if (jsonData.companyEntities !== undefined && Object.keys(jsonData.companyEntities).length !== 0) {
                    Object.keys(jsonData.companyEntities).forEach(function (k, item) {
                        jsonData.companyEntities[k].disabledField = jsonData.companyEntities[k].disabledField === 0 ? tempArr['99000054'] : tempArr['99001613'];
                    })
                }
            }

            if (textNumberArr.length > 0) {
                this.props.loader.showLoader({ "qp_multilang": true });
                var tmpArrayofText = await getMultitermTexts(textNumberArr)
                this.callBackHandlGetLanguageLangID3TextSiteList(callbackAfterSet, tmpArrayofText)
            }
            else {
                callbackAfterSet([])
            }


            // for(var headKey in this.props.sitemgmt.siteHeaderArr){
            //     jsonData.companyEntities.forEach((user) => {
            //         if(user[headKey] === undefined ){
            //             user[headKey] = ''
            //         }   
            //     });
            // }

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.props.setResponseDataSite(usrLib.convertToObject(jsonData, "companyEntities"));
                this.setState({
                    reloadSiteData: true
                })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForDeleteSiteData(responseDataDeleteSL) {
        this.props.loader.showLoader({ "DELETE_Site_entityId": false });

        if (responseDataDeleteSL.state.response.status === 200) {
            this.handleLoadSiteList();
            this.handleDialogCloseSiteList();
            this.setState({ site_list_delete_confirm_yes_disabled: false });
        }
        else {
            this.handleDialogCloseSiteList();

            let message = "";
            if (responseDataDeleteSL.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseDataDeleteSL.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }

            LiStatusManager.showStatus(responseDataDeleteSL.state.response.status, message + responseDataDeleteSL.state.response.statusText);
        }
    }

    componentDidMount() {
        this.props.loader.showLoader({ "GET_Site": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackWithArgForSiteList.bind(this));
    }

    handleDialogCloseSiteList() {
        if (this.props.sitemgmt.site_delete_open) { this.props.setDeleteDialogSite(false) }
        if (this.props.sitemgmt.add_open) { this.props.setAddDialogSite(false) }
        if (this.state.deleteAllSite) { this.setState({ deleteAllSite: false }) }
    }

    getDataFromTable = function (rowID, userRecObj, actionName, dummy1, dummy2, dummy3, actions) {
        if (rowID !== undefined) {
            this.props.setSiteID(this.props.sitemgmt.siteRowArr[rowID].entityIdField);
            this.props.setRowIDSite(rowID);
        }
        if (actions.key === "View") {
            this.props.setRowIDSite(rowID);
            this.setState({ currentActionSL: 'view' })
            var enableRouteSL = this.state.enableRouteSL;
            enableRouteSL['view'] = { status: true, link: '/site_view_ng?entityid=' + this.props.sitemgmt.siteRowArr[rowID].entityIdField }
            this.setState({ enableRouteSL });
        }
        if (actions.key === "Change log") {
            this.props.setRowIDSite(rowID);
            this.setState({ currentActionSL: 'change-log' })
            var enableRouteSL = this.state.enableRouteSL;
            enableRouteSL['change-log'] = { status: true, link: '/site_filtered_change_log_ng?entityid=' + this.props.sitemgmt.siteRowArr[rowID].entityIdField }
            this.setState({ enableRouteSL });
        }

        this.getDataFromTable1(actions, rowID);
    }

    getDataFromTable1(actions, rowID) {
        if (actions.key === "Edit") {
            this.props.setRowIDSite(rowID);
            this.setState({ currentActionSL: 'edit' })
            var enableRouteSL = this.state.enableRouteSL;
            enableRouteSL['edit'] = { status: true, link: '/site_edit_ng?entityid=' + this.props.sitemgmt.siteRowArr[rowID].entityIdField }
            this.setState({ enableRouteSL });
        }
        if (actions.key === "Delete") {
            this.props.setDeleteDialogSite(true);
            this.props.setRowIDSite(rowID);
        }
    }

    handleData(selectedRows, rowID, rowData) {
        this.setState({ selectedRowData: selectedRows })
    }

    handleLoadSiteList() {
        this.props.loader.showLoader({ "GET_Site": true });

        this.setState({ reloadSiteData: false });
        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackWithArgForSiteList.bind(this));
    }

    handleDeleteSiteData(siteID) {
        this.props.loader.showLoader({ "DELETE_Site_entityId": true });

        this.setState({ site_list_delete_confirm_yes_disabled: true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Sites_entityId(this.callbackWithArgForDeleteSiteData, siteID);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }

    handleDeleteAllSiteDataDailog() {
        this.setState({ deleteAllSite: true });
    }

    handleDeleteAllSiteData() {
        if ((this.state.selectedRowData).length > 0) {
            this.state.selectedRowData.forEach((eachRowData) => {
                // var eachEntityIdField = this.props.sitemgmt.siteRowArr[eachRowData].entityIdField;
                let eachEntityIdField = eachRowData.value.entityIdField;

                this.props.loader.showLoader({ "DELETE_Site_entityId": true });

                let siteName = getSiteName();
                OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Sites_entityId(this.callbackhandleDeleteAllSiteData, eachEntityIdField);
            })
        }
    }

    callbackhandleDeleteAllSiteData(responseData) {
        if (responseData.state.response.status === 200) {
            deleteCount++;

            if ((this.state.selectedRowData).length === deleteCount) {
                this.props.loader.showLoader({ "DELETE_Site_entityId": false });
            }
        }
        else {
            this.props.loader.showLoader({ "DELETE_Site_entityId": false });

            let message = "";
            if (responseData.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseData.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }

            LiStatusManager.showStatus(responseData.state.response.status, message + responseData.state.response.statusText);
        }

        this.handleLoadSiteList();
        this.handleDialogCloseSiteList();
    }

    linkData(rowData) {
        this.setState({ currentActionSL: 'edit' });

        var enableRouteSL = this.state.enableRouteSL;
        enableRouteSL['edit'] = { status: true, link: '/site_edit_ng?entityid=' + rowData.value.toString() }
        this.setState({ enableRouteSL });
    }

    render() {

        return (
            <div>
                {this.state.enableRouteSL[this.state.currentActionSL] !== undefined && this.state.enableRouteSL[this.state.currentActionSL]['status'] === true ? <Redirect to={this.state.enableRouteSL[this.state.currentActionSL]['link']} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSL} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 130)} containerID="site.list" containerScrollY={true} containerScrollX={false} >
                    <LiMasterDataOverview
                        tableId="ng.um.site.list"
                        tableConfig={siteTableConfiguration}
                        colHeaderArr={this.props.sitemgmt.siteHeaderArr}
                        masterListData={this.props.sitemgmt.siteRowArr}
                        handleData={this.handleData.bind(this)}
                        linkData={this.linkData.bind(this)}
                        receiverFunction={this.getDataFromTable}

                        reloadList={this.state.reloadSiteData}

                        handleDialogClose={this.handleDialogCloseSiteList.bind(this)}
                        handleDeleteMasterData={this.handleDeleteSiteData.bind(this)}
                        handleDeleteSelected={this.handleDeleteAllSiteData.bind(this)}

                        translationTextsCommon={this.state.multitermTextCommon}
                        translationTexts={this.state.multitermText}

                        exportFileName="ng-um-site-list"

                        buttons={[
                            {
                                addButton: <Link to="/site_add_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99029979" textVal="Add Site" />}
                                    />
                                </Link>
                            },
                            {
                                deleteButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="6000749" textVal="Delete" />}
                                    buttonDisabled={this.state.selectedRowData.length === 0 ? true : false}
                                    buttonOnClickHandler={this.handleDeleteAllSiteDataDailog.bind(this, 2)}
                                />
                            },
                            {
                                reloadButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="99007226" textVal="Reload" />}
                                    buttonOnClickHandler={this.handleLoadSiteList.bind(this)}
                                />
                            },
                            {
                                auditButton: <Link to="/site_change_log_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99025943" textVal="Change log" />}
                                    />
                                </Link>
                            },
                        ]}
                        masterDataID={this.props.sitemgmt.siteID}
                        deleteOpenMasterRec={this.props.sitemgmt.site_delete_open}
                        deleteSelectedMasterRecs={this.state.deleteAllSite}
                    />
                </LiDivContainer>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        sitemgmt: state.sitemgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setResponseDataSite: SiteAction.setResponseDataSite,
        setViewDialogSite: SiteAction.setViewDialogSite,
        setEditDialogSite: SiteAction.setEditDialogSite,
        setDeleteDialogSite: SiteAction.setDeleteDialogSite,
        setSiteID: SiteAction.setSiteID,
        setRowIDSite: SiteAction.setRowIDSite
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
