const initial = {
    roleRowArr      : {},
    roleID          : '',
    roleHeaderArr   : {"roleIdField": ["99001692", "ID #"], "roleDescField": ["6001104", "Name"], "roleShdescField": ["99002657", "Short Desc"], "userTypeField": ["99024421", "User Type"], "disabledField": ["99023501", "Disabled"], "entityIdField": ["99027118", "Production Sites"]},
    userRoleListHeaderArr : {"userId": ["99001692","ID #"], "firstName": ["99024423","First Name"], "lastName": ["99024422","Last Name"]},
    roleRowID       : 0,
    dayVal          : [],
    siteDeptArr     : [],
    userTypeObj     : { "0": "Client Side", "1": "Server Side", "2": "Client + Server", "3": "Visu" },
}

export default function(state = initial, action){
    switch(action.type) {
        case "SET_RESPONSE_DATA_ROLES":
            state = {...state,
                roleRowArr: action.payload
            }
            break;
        
        case "SET_ROWID_ROLES":
            state = {...state,
                roleRowID : action.payload
            }
            break;

        case "SET_ROLEID":
            state = {...state,
                roleID : action.payload
            }
            break;

        case "SET_USER_SITE_OR_DEPT": state = { ...state, siteDeptArr: action.payload }
            break;

        default:
            state = {
                ...state
            }
            break;
    }
    return state;
}

