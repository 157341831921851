import { Link, Redirect } from "react-router-dom";
import * as UserAction from '../actions/useraction';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiBody, MimeTypes, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiLineEdit, LiBreadCrumbs, LiSwitch, LiComboBox, LiComboList, LiLabel, LiStatusManager, LiStatusContainer, LiReactDialog, LiForm } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';

const dataVal = {
    "interfaceInfo": {
        "type": "MT_MASTER_DATA",
        "version": 1,
        "itemType": "ITEM_TYPE_MASTER_COMPANY_ENTITY"
    },
    "entityIdField": -1,
    "subEntityOfField": null,
}

class AddSites extends Component {

    constructor() {
        super();
        this.state = {
            slider: 1.0,
            rotate: 0,
            add_site_save_disabled: false,
            add_confirm_open: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            dbTypeVal: '',
            redirectPathSA: '',
            siteDisabled: false,
            showInfoDialogSA: false,
            infoMessage: "",
            currentSiteData: {},

        };

        this.dataObjBreadCrumbsSA = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Sites": {
                "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                "path": "/sites_ng"
            },
            "Add Site": {
                "label": <LiMultiterm textId="99029979" textVal="Add Site" />,
                "path": "/site_add_ng"
            }
        }
    }

    handleSlider = (event, value) => {
        this.setState({ slider: value });
    }

    callbackWithArgFromSiteAdd(responseData) {
        this.props.loader.showLoader({ "POST_Site": false });

        if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
            this.setState({ redirectPathSA: "sites_ng" });
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.callBackForEnableButton.bind(this));
        }
    }

    callBackForEnableButton() {
        this.setState({ add_site_save_disabled: false });
    }

    handleSubmitData(allTrue) {

        if (!allTrue) {
            return
        }

        let addDesc = document.getElementById('addDesc').value;
        let addShortDesc = document.getElementById('addShortDesc').value;
        // let hostIP = document.getElementById('hostIP').value;
        // let portOffset = document.getElementById('portOffset').value;
        let dbType = this.state.dbTypeVal;
        let siteDisabled = (document.getElementById('siteDisabled').checked === true ? 1 : 0);
        let licenseSiteApiKeyField = document.getElementById('licenseSiteApiKeyField').value;

        if (addDesc.trim() === "" || addShortDesc.trim() === "") {
            this.setState({ showInfoDialogSA: true });
            return;
        }

        dataVal.entityDescField = addDesc;
        dataVal.entityShdescField = addShortDesc;
        dataVal.entityTypeField = 2;
        // dataVal.hostIpField = hostIP;
        // dataVal.portOffsetField = parseInt(portOffset);
        dataVal.dbTypeField = dbType;
        dataVal.disabledField = siteDisabled;
        dataVal.licenseSiteApiKeyField = licenseSiteApiKeyField;



        this.setState({ add_site_save_disabled: true });
        this.props.loader.showLoader({ "POST_Site": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).POST_Sites(this.callbackWithArgFromSiteAdd.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, dataVal));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }

    handleChangeSiteAdd(eventSA) {
        const target = eventSA.target;
        // console.log(target.type)
        const value = target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : target.value;
        const name = eventSA.target.name;
        const siteObj = this.state.currentSiteData;
        siteObj[name] = value;
        this.setState({
            currentSiteData: siteObj
        });
        if (target.type === 'checkbox')
            this.setState({
                siteDisabled: target.checked
            });
    }

    dbTypeChangeSiteAdd = (value, name, event) => {
        if (value.length !== 0) {
            this.setState({ dbTypeVal: parseInt(value[0]) });
        }
    }

    handleCloseResponseSiteAdd() {
        this.setState({ showInfoDialogSA: false });
    }

    render() {
        const stateRequiredSA = [
            { "Name": [<LiMultiterm textId="6001104" textVal="Name" />, this.state.currentSiteData.addDesc] },
            { "ShortDescription": [<LiMultiterm textId="99002657" textVal="Short description" />, this.state.currentSiteData.addShortDesc] },
        ];
        return (
            <div>
                {this.state.redirectPathSA !== undefined && this.state.redirectPathSA !== '' ? <Redirect to={this.state.redirectPathSA} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSA} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />

                <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 220)} containerID="LiUsrMgmtSiteAddSub1" containerScrollY={true} containerScrollX={false} >
                    <div className="twoColumnMainGridContainer">
                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} isMandatory={true} /></div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="addDesc" liEditName="addDesc" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteAdd.bind(this)} liEditOnKeyUp={this.handleChangeSiteAdd.bind(this)} liEditValue={this.state.currentSiteData.addDesc !== undefined ? this.state.currentSiteData.addDesc : ''} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short description" />} isMandatory={true} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="addShortDesc" liEditName="addShortDesc" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteAdd.bind(this)} liEditOnKeyUp={this.handleChangeSiteAdd.bind(this)} liEditValue={this.state.currentSiteData.addShortDesc !== undefined ? this.state.currentSiteData.addShortDesc : ''} />
                        </div>

                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="99032737" textVal="License site API key" />} isMandatory={false} /></div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="licenseSiteApiKeyField" liEditName="licenseSiteApiKeyField" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteAdd.bind(this)} liEditOnKeyUp={this.handleChangeSiteAdd.bind(this)} liEditValue={this.state.currentSiteData.licenseSiteApiKeyField !== undefined ? this.state.currentSiteData.licenseSiteApiKeyField : ''} liReadOnly={false} liPassword={true} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiSwitch switchName="siteDisabled" switchId="siteDisabled" switchDisabled={false} switchVal="siteDisabled" switchOnChangeHandler={this.handleChangeSiteAdd.bind(this)} switchChecked={this.state.siteDisabled} />
                        </div>
                    </div>

                </LiDivContainer>

                <LiDivContainer containerHeight="70" containerID="LiUsrMgmtSiteAddSub2" containerScrollY={false} containerScrollX={false} >
                    <hr className={this.state.theme + " LiUsrMgmtBottom"} />
                    <div className="userMgmtGridContainerFull">
                        <div className="userMgmtGridContainerFullItem">
                            <LiForm
                                height={350}
                                formDialogTitle={<LiMultiterm textId="4000070" textVal="Warning" />}
                                formDialogCloseText={<LiMultiterm textId="99000136" textVal="OK" />}
                                requiredByState={stateRequiredSA}
                                requiredById={[]}
                                allRequiredFilled={this.handleSubmitData.bind(this)}
                                buttonLabel={<LiMultiterm textId="6001485" textVal="Save" />}
                                formMessage={<LiMultiterm textId="99030257" textVal="Please fill all the required fields" />}
                            />
                            <Link to="/sites_ng">
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                />
                            </Link>
                        </div>
                    </div>

                    {
                        this.state.showInfoDialogSA
                            ?
                            <LiReactDialog
                                enableStoring="server.response.site.add"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.handleCloseResponseSiteAdd()
                                    }]
                                }
                                onClose={this.handleCloseResponseSiteAdd.bind(this)}
                                isFooterEnable={false}
                            >
                                <p><LiMultiterm textId="99030257" textVal="Please fill all the required fields" />.</p>
                                <p><LiMultiterm textId="99030605" textVal="Mandatory fields are marked with" /> <span className="user_mandatoryFieldNote">*</span></p>
                            </LiReactDialog>
                            :
                            ""
                    }

                    <LiStatusContainer />
                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToPropsSiteAdd(state) {
    return {
        sitemgmt: state.sitemgmt
    };
}

function mapDispatchToPropsSiteAdd(dispatch) {
    return bindActionCreators({
        setConfirmDialog: UserAction.setConfirmDialog,
    }, dispatch)
}

export default connect(mapStateToPropsSiteAdd, mapDispatchToPropsSiteAdd)(AddSites);