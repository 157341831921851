
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as RolesAction from '../actions/rolesaction';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiBreadCrumbs, LiDivContainer, LiButton, LiStatusManager } from 'liseccomponents';
import { LiMasterDataOverview } from 'lisecdependantcomp';

import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';

const choiceObject = {
    0: ['Default', 99001446],
    1: ['Shopfloor Operator', 99030183],
};

var deleteCount = 0;

/*--------------------------- Table Configuration -------------------------------*/
const roleTableConfiguration = {
    "getRowDataConfiguration": {
        "enableRowData": true,
    },
    "linkConfiguration": {
        "enableLink": false,
        "link": "/role_edit_ng?roleid="
    },

    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.role.list") !== null ? conversionLib.getLocalStorageValue("table.pagesize.role.list") : 20,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": { "roleIdField": ["99001692", "ID #"], "roleDescField": ["6001104", "Name"], "roleShdescField": ["99002657", "Short Desc"], "userTypeField": ["99024421", "User Type"], "disabledField": ["99023501", "Disabled"], "entityIdField": ["99027118", "Production Sites"] },                      //Only Column Header Name
        "defaultSortingColumn": "ID",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["code", "full_name", "extn", "company_name", "email", "dept", "status"],           //Only Row Key 
        "searchOptionColumns": { "status": { "option1": "Online", "option2": "Offline" }, "dept": { "option1": "Accounting", "option2": "Machinery", "option3": "Software", "option4": "IT" } }
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
            "Edit": ['6001743', "Edit"],
            "Delete": ['99003381', "Delete"],
            "Change log": ['99025943', "Change log"],

        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "roleIdField": "link",
        "roleDescField": "text",
        "roleShdescField": "text",
        "disabledField": "boolean",
        "entityIdField": "number",
    },
    "multiSelectable": {
        "enabled": true,
        "showCheckboxes": true
    },
    "enableTrigger": false
};

class RoleList extends Component {
    constructor() {
        super();
        this.state = {
            reloadData: false,
            role_delete_dailog: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            selectedRowData: [],
            enableRouteRL: {},
            currentActionRL: '',
            allCompany: [],
            deleteAllRoles: false,
            comboChoice: {},
            multilangDataVal: {},
            multitermTextCommon: {
                yesText: <LiMultiterm textId="99001613" textVal="Yes" />,
                noText: <LiMultiterm textId="99000054" textVal="No" />,
            },
            multitermText: {
                deleteSingleTitle: <LiMultiterm textId="99031036" textVal="Delete Role" />,
                deleteSingleText: <LiMultiterm textId="99031024" textVal="Do you want to delete the role" />,
                deleteSelectedTitle: <LiMultiterm textId="99031036" textVal="Delete Role" />,
                deleteSelectedText: <LiMultiterm textId="99031023" textVal="Do you want to delete selected roles ?" />,
            },

            addRoleGroupDialog: false,
            tableData: null,
            filterValue: ""
        }

        this.getDataFromTable = this.getDataFromTable.bind(this);
        this.handleDialogCloseRoleList = this.handleDialogCloseRoleList.bind(this);
        this.handleLoadRoleList = this.handleLoadRoleList.bind(this);
        this.handleDeleteRoleData = this.handleDeleteRoleData.bind(this);
        this.callbackhandleDeleteAllRoleData = this.callbackhandleDeleteAllRoleData.bind(this);

        this.dataObjBreadCrumbsRL = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Roles": {
                "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                "path": "/roles_ng"
            }
        }
    }

    // callbackWithChoices = (responseData) => {
    //     if(responseData.state.response.status === 200) {
    //         var result = conversionLib.convertToJSON((responseData.state.response.data));
    //         this.setChoices(result.choice);
    //     }
    //     else {
    //         // console.log("111111",responseData);
    //         LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
    //     }

    // let siteName = getSiteName();
    // OpenApiClient_ng_user_management_v1.getClient(siteName).GET_CompanyEntities(this.callbackForCompanyList.bind(this));
    // }

    callBackHandlGetLanguageLangID3TextRoleList(responseDataLangRL) {
        if (responseDataLangRL.state.response !== undefined && responseDataLangRL.state.response.status === 200) {
            var jsonDataLangRL = conversionLib.convertToJSON(responseDataLangRL.state.response.data);
            var tempArr = {};
            Object.keys(jsonDataLangRL).forEach(function (k, item) {
                tempArr[jsonDataLangRL[k].number] = jsonDataLangRL[k].text
            })
            this.setState({ multilangDataVal: tempArr })
        }
        else {
            console.log("RESPONSE : " + responseDataLangRL.state.response);
        }
    }

    // async setChoices(choices){
    //     if (choices[0].choiceEntry !== undefined) {
    //         var choiceArr = {};
    //         var textNumberArr = [];
    //         choices[0].choiceEntry.forEach((ChoiceText, index) => {
    //             choiceArr[ChoiceText.keyInt] = [ChoiceText.displayToken[0].tokenTxtfallback,ChoiceText.displayToken[0].tokenTxtno]
    //             if(ChoiceText.displayToken[0].tokenTxtno > 0) {
    //                 textNumberArr.push(ChoiceText.displayToken[0].tokenTxtno);
    //             }
    //         })

    //         textNumberArr.push('99000054');
    //         textNumberArr.push('99001613');

    //         if(textNumberArr.length > 0){
    //             // this.props.loader.showLoader({"qp_multilang": true});
    //             var tmpArrayofText = await getMultitermTexts(textNumberArr) 
    //             this.callBackHandlGetLanguageLangID3TextRoleList(tmpArrayofText)
    //         }
    //     }

    //     this.setState({comboChoice : choiceArr})
    // }

    callbackForAllSiteOrDeptRoleList(responseDataSiteDepRL) {
        if (responseDataSiteDepRL.state.response.status === 200) {
            var jsonDataSiteDepRL = conversionLib.convertToJSON(responseDataSiteDepRL.state.response.data);
            if (jsonDataSiteDepRL !== undefined && Object.keys(jsonDataSiteDepRL).length !== 0) {
                var tempArrSiteDepRL = {};
                Object.keys(jsonDataSiteDepRL.companyEntities).forEach(function (k, item) {
                    tempArrSiteDepRL[jsonDataSiteDepRL.companyEntities[k].entityIdField] = jsonDataSiteDepRL.companyEntities[k].entityDescField
                })
                this.props.setUserSiteOrDept(tempArrSiteDepRL);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataSiteDepRL.state.response.status, responseDataSiteDepRL.state.response.message);
        }
    }

    callbackForCompanyList(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var tempAllRoleArr = {};
                var jsonCompany = jsonData.companyEntities;
                jsonCompany.forEach((eachCompany) => {
                    tempAllRoleArr[eachCompany.entityIdField] = eachCompany.entityDescField;
                })
                this.setState({ allCompany: tempAllRoleArr });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArg.bind(this));
    }

    callbackWithArg(responseData) {
        this.props.loader.showLoader({ "GET_Role": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            Object.keys(this.props.rolestore.roleHeaderArr).forEach(headKey => {
                jsonData.roles.forEach((user) => {
                    if (user[headKey] === undefined) {
                        user[headKey] = '';
                    }
                });
            });

            // for(var headKey in this.props.rolestore.roleHeaderArr){
            //     jsonData.roles.forEach((user) => {
            //         if(user[headKey] === undefined){
            //             user[headKey] = '';
            //         }   
            //     });
            // }

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.props.setResponseDataRole(this.convertToObjectRoleList(jsonData));
                this.setState({
                    reloadData: true
                })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackForAllSiteOrDeptRoleList.bind(this));
    }

    callbackDeleteRolRroleId(responseDataDeleteRE) {
        this.props.loader.showLoader({ "DELETE_Role_roleId": false });

        if (responseDataDeleteRE.state.response.status === 200) {
            this.handleLoadRoleList();
            this.handleDialogCloseRoleList();
        }
        else {
            this.handleDialogCloseRoleList();

            let message = "";
            if (responseDataDeleteRE.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseDataDeleteRE.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }
            LiStatusManager.showStatus(responseDataDeleteRE.state.response.status, message + responseDataDeleteRE.state.response.statusText);
        }
    }

    convertToObjectRoleList(data) {
        var opt = {};
        for (var i = 0; i < data.roles.length; i++) {
            data.roles[i].entityIdField = this.state.allCompany[data.roles[i].entityIdField];
            if (data.roles[i]['userTypeField'] !== undefined && data.roles[i]['userTypeField'] !== '') {

                data.roles[i]['userTypeField'] = this.state.multilangDataVal[
                    String(this.state.comboChoice[data.roles[i]['userTypeField']] !== undefined ? this.state.comboChoice[data.roles[i]['userTypeField']][1] : '')
                ] !== undefined
                    ? this.state.multilangDataVal[String(this.state.comboChoice[data.roles[i]['userTypeField']][1])]
                    : '';

                data.roles[i]['disabledField'] = data.roles[i]['disabledField'] === 0 ? this.state.multilangDataVal['99000054'] !== undefined ? this.state.multilangDataVal['99000054'] : 'No' : this.state.multilangDataVal['99001613'] !== undefined ? this.state.multilangDataVal['99001613'] : 'Yes';
            }
            opt[i] = data.roles[i];
        }
        return (opt);
    }

    componentDidMount() {
        // console.log('ROLcheck:::::::::::::::::',     .dateTimeFormatConversion('2022-07-25T15:36:00.220+04:00', 'time', ))
        this.props.loader.showLoader({ "GET_Role": true });

        this.setChoices();

        // let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2108']);

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_CompanyEntities(this.callbackForCompanyList.bind(this));
    }

    async setChoices() {
        var textNumberArr = [];

        Object.values(choiceObject).forEach((eachObject, index) => {
            // console.log(" --------------- ", eachObject, index)
            if (eachObject[1] > 0) {
                textNumberArr.push(eachObject[1]);
            }
        })

        if (textNumberArr.length > 0) {
            var tmpArrayofText = await getMultitermTexts(textNumberArr)
            this.callBackHandlGetLanguageLangID3TextRoleList(tmpArrayofText)
        }

        this.setState({ comboChoice: choiceObject })
    }

    handleDialogCloseRoleList() {
        if (this.state.role_delete_dailog) {
            this.setState({
                role_delete_dailog: false
            })
        }

        if (this.state.deleteAllRoles) {
            this.setState({ deleteAllRoles: false })
        }

        if (this.state.addRoleGroupDialog) {
            this.setState({
                addRoleGroupDialog: false,
                tableData: null,
                filterValue: ""
            })
        }
    }

    getDataFromTable = function (rowID, roleRecObj, actionName, dummy1, dummy2, dummy3, actions) {
        if (rowID !== undefined) { this.props.setRowIDRole(rowID) }
        this.props.setRoleID(roleRecObj[rowID].value.roleIdField);
        if (actions.key === "View") {
            this.props.setRowIDRole(rowID);
            this.setState({ currentActionRL: 'view' })
            var enableRouteRL = this.state.enableRouteRL;
            enableRouteRL['view'] = { status: true, link: '/role_view_ng?roleid=' + this.props.rolestore.roleRowArr[rowID].roleIdField }
            this.setState({ enableRouteRL });
        }
        if (actions.key === "Change log") {
            this.props.setRowIDRole(rowID);
            this.setState({ currentActionRL: 'change-log' })
            let enableRouteRL = this.state.enableRouteRL;
            enableRouteRL['change-log'] = { status: true, link: '/role_filtered_change_log_ng?roleid=' + this.props.rolestore.roleRowArr[rowID].roleIdField }
            this.setState({ enableRouteRL });
        }
        this.getDataFromTable1(actions, rowID);
    }

    getDataFromTable1(actions, rowID) {
        if (actions.key === "Edit") {
            this.props.setRowIDRole(rowID);
            this.setState({ currentActionRL: 'edit' })
            var enableRouteRL = this.state.enableRouteRL;
            enableRouteRL['edit'] = { status: true, link: '/role_edit_ng?roleid=' + this.props.rolestore.roleRowArr[rowID].roleIdField }
            this.setState({ enableRouteRL });
        }
        if (actions.key === "Delete") {
            this.props.setRowIDRole(rowID);
            this.setState({
                role_delete_dailog: true
            })
        }
    }

    handleData(selectedRows, rowID, rowData) {
        this.setState({ selectedRowData: selectedRows })
    }

    handleLoadRoleList() {
        this.setState({ reloadData: false });
        this.props.loader.showLoader({ "GET_Role": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArg.bind(this));
    }

    handleDeleteRoleData(roleID) {
        this.props.loader.showLoader({ "DELETE_Role_roleId": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Roles_roleId(this.callbackDeleteRolRroleId.bind(this), roleID);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }

    handleDeleteAllRoleDataDailog() {
        this.setState({ deleteAllRoles: true });
    }

    handleDeleteAllRoleData() {
        if ((this.state.selectedRowData).length > 0) {
            this.state.selectedRowData.forEach((eachRowData) => {
                // var eachroleIdField = this.props.rolestore.roleRowArr[eachRowData].roleIdField;
                let eachroleIdField = eachRowData.value.roleIdField;

                this.props.loader.showLoader({ "DELETE_Role_roleId": true });

                let siteName = getSiteName();
                OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Roles_roleId(this.callbackhandleDeleteAllRoleData.bind(this), eachroleIdField);
            })
        }
    }

    callbackhandleDeleteAllRoleData(responseData) {
        if (responseData.state.response.status === 200) {
            deleteCount++;

            if ((this.state.selectedRowData).length === deleteCount) {
                this.props.loader.showLoader({ "DELETE_Role_roleId": false });
            }
        }
        else {
            this.props.loader.showLoader({ "DELETE_Role_roleId": false });

            let message = "";
            if (responseData.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseData.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }
            LiStatusManager.showStatus(responseData.state.response.status, message + responseData.state.response.statusText);
        }

        this.handleLoadRoleList();
        this.handleDialogCloseRoleList();
    }

    linkData(rowData) {
        this.setState({ currentActionRL: 'edit' });

        var enableRouteRL = this.state.enableRouteRL;
        enableRouteRL['edit'] = { status: true, link: '/role_edit_ng?roleid=' + rowData.value.toString() }
        this.setState({ enableRouteRL });
    }

    render() {
        return (
            <div>
                {this.state.enableRouteRL[this.state.currentActionRL] !== undefined && this.state.enableRouteRL[this.state.currentActionRL]['status'] === true ? <Redirect to={this.state.enableRouteRL[this.state.currentActionRL]['link']} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsRL} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 130)} containerID="role.list" containerScrollY={true} containerScrollX={false} >
                    <LiMasterDataOverview
                        tableId="ng.um.role.list"
                        tableConfig={roleTableConfiguration}
                        colHeaderArr={this.props.rolestore.roleHeaderArr}
                        masterListData={this.props.rolestore.roleRowArr}
                        handleData={this.handleData.bind(this)}
                        linkData={this.linkData.bind(this)}
                        receiverFunction={this.getDataFromTable}

                        reloadList={this.state.reloadData}

                        handleDialogClose={this.handleDialogCloseRoleList.bind(this)}
                        handleDeleteMasterData={this.handleDeleteRoleData.bind(this)}
                        handleDeleteSelected={this.handleDeleteAllRoleData.bind(this)}

                        translationTextsCommon={this.state.multitermTextCommon}
                        translationTexts={this.state.multitermText}

                        exportFileName="ng-um-role-list"

                        buttons={[
                            {
                                addButton: <Link to="/role_add_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99029982" textVal="Add roles" />}
                                    />
                                </Link>
                            },
                            {
                                deleteButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="6000749" textVal="Delete" />}
                                    buttonDisabled={this.state.selectedRowData.length === 0 ? true : false}
                                    buttonOnClickHandler={this.handleDeleteAllRoleDataDailog.bind(this, 2)}
                                />
                            },
                            {
                                reloadButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="99007226" textVal="Reload" />}
                                    buttonOnClickHandler={this.handleLoadRoleList.bind(this)}
                                />
                            },
                            {
                                auditButton: <Link to="/role_change_log_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99025943" textVal="Change log" />}
                                    />
                                </Link>
                            },
                        ]}
                        masterDataID={this.props.rolestore.roleID}
                        deleteOpenMasterRec={this.state.role_delete_dailog}
                        deleteSelectedMasterRecs={this.state.deleteAllRoles}
                    />
                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolestore: state.rolestore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRowIDRole: RolesAction.setRowIDRole,
        setResponseDataRole: RolesAction.setResponseDataRole,
        setRoleID: RolesAction.setRoleID,
        setUserSiteOrDept: RolesAction.setUserSiteOrDept,

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
