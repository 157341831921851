import React from "react";
import { Link, Redirect } from "react-router-dom";
import { LiMultiterm } from "lisecutilityfunctions/lib/utilitylib";
import * as conversionLib from "lisecutilityfunctions/lib/commonfunction";
import lightUserIcon from "./images/LightTheme/application_detail.svg";
import darkUserIcon from "./images/DarkTheme/application_detail.svg";
import { LiDivContainer, LiButton, LiLineEdit, LiBreadCrumbs, LiComboBox, LiComboList, LiLabel, LiForm, LiStatusManager } from "liseccomponents";
import { OpenApiClient_ng_user_management_v1, OpenApiBody, MimeTypes, getSiteName } from "lisecutilityfunctions/lib/openapi";

const twoColumnMainGridContainerItem = "twoColumnMainGridContainerItem";
const type = ["Global", "Customer", "Site"];
class ApplicationDetailAdd extends React.Component {
  constructor() {
    super();
    this.state = {
      theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
      idField: null,
      nameField: null,
      urlField: null,
      descField: null,
      type: null,
      modifiedField: null,
      entityIdField: null,
      redirectPathADAE: "",
      add: false,
      edit: false,
      defaultTextForCombo: "",
    };
    this.liComboList = this.liComboList.bind(this);
    this.dataObjBreadCrumbsADAE = {
      "User Management": {
        label: <LiMultiterm textId="99005135" textVal="User Management" />,
        path: "/usermain_ng",
      },
      Sites: {
        label: <LiMultiterm textId="99030950" textVal="Application Detail" />,
        path: "/application_detail_ng",
      },
      "Add Site": {
        label: <LiMultiterm textId="99030952" textVal="Add Application Detail" />,
        path: "/application_detail_add_ng",
      },
    };
    this.dataObjBreadCrumbsADAE2 = {
      "User Management": {
        label: <LiMultiterm textId="99005135" textVal="User Management" />,
        path: "/usermain_ng",
      },
      Sites: {
        label: <LiMultiterm textId="99030950" textVal="Application Detail" />,
        path: "/application_detail_ng",
      },
      "Add Site": {
        label: <LiMultiterm textId="99030952" textVal="Edit Application Detail" />,
        path: "/application_detail_edit_ng",
      },
    };
  }

  componentDidMount() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var appId = url.searchParams.get("appid");
    this.setState({
      idField: parseInt(appId),
    });
    document.getElementById("nameField").maxLength = 240;
    document.getElementById("urlField").maxLength = 240;
    document.getElementById("descField").maxLength = 240;
    if (window.location.pathname.includes("add")) {
      this.setState({
        add: true,
      });
    }
    if (window.location.pathname.includes("edit")) {
      this.setState({
        edit: true,
      });
    }
    if (window.location.pathname.includes("edit")) {
      let siteName = getSiteName();
      // console.log(siteName);
      if (appId !== null && appId !== undefined && appId !== "") {
        this.props.loader.showLoader({ GET_Application_appId: true });
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Applications_appId(this.handleGetApplicationByID.bind(this), appId);
      }
    }
  }

  handleGetApplicationByID(responseData) {
    this.props.loader.showLoader({ GET_Application_appId: false });
    if (responseData.state.response.status === 200) {
      var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
      if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
        this.setState({
          nameField: jsonData.nameField,
          urlField: jsonData.urlField,
          descField: jsonData.descField,
          type: jsonData.type,
          entityIdField: jsonData.entityIdField,
          modifiedField: jsonData.modifiedField,
          defaultTextForCombo: jsonData.type
        });
        // this.selectedComboOption();
      }
    } else {
      LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
    }
  }

  selectedComboOption() {
    if (this.state.type === 0) {
      this.setState({
        defaultTextForCombo: 0,
      });
    } else if (this.state.type === 1) {
      this.setState({
        defaultTextForCombo: 1,
      });
    } else if (this.state.type === 2) {
      this.setState({
        defaultTextForCombo: 2,
      });
    }
  }

  handleSubmitData(allTrue) {
    if (!allTrue) {
      return;
    }

    let siteName = getSiteName();
    let data = {
      idField: this.state.idField,
      nameField: this.state.nameField,
      urlField: this.state.urlField === null ? " " : this.state.urlField,
      descField: this.state.descField === null ? " " : this.state.descField,
      type: this.state.type,
      modifiedField: this.state.modifiedField
    };
    if (this.state.add) {
      this.props.loader.showLoader({ POST_Application_details: true });
      OpenApiClient_ng_user_management_v1.getClient(siteName).POST_Applications(this.handleCallbackResponse.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, data));
    }

    if (this.state.edit) {
      this.props.loader.showLoader({ POST_Application_details: true });
      OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Applications(this.handleCallbackResponse.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, data));
    }
  }

  handleCallbackResponse(responseData) {
    let response = responseData.state.response;
    this.props.loader.showLoader({ POST_Application_details: false });
    if (response.status === 200 || response.status === 201) {
      this.setState({
        redirectPathADAE: "application_detail_ng",
      });
    } else {
      // LiStatusManager.showStatus(
      //   response.status,
      //   response.message,
      //   this.callBackForEnableButton.bind(this)
      // );
      return;
    }
  }

  handleChangeAppDetail(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  compTypeChange(value) {
    this.setState({
      type: parseInt(value),
      defaultTextForCombo: (value)
    });
  }

  liComboList() {
    return type.map((data, idx) => {
      return (
        <LiComboList key={idx} value={idx}>
          {data}
        </LiComboList>
      );
    });
  }

  liMultiterm(text, textNumber) {
    return <LiMultiterm textId={textNumber} textVal={text} />;
  }

  render() {
    let { redirectPathADAE, theme, nameField, urlField, descField, edit, add } = this.state;
    const stateRequiredADAE = [{ Name: [<LiMultiterm textId="6001104" textVal="Name" />, nameField] },
    { URL: [<LiMultiterm textId="99000363" textVal="URL" />, urlField] }];
    return (
      <div>
        {redirectPathADAE !== undefined && redirectPathADAE !== "" ? <Redirect to={redirectPathADAE} /> : null}

        <LiBreadCrumbs breadcrumbsdataObj={add ? this.dataObjBreadCrumbsADAE : this.dataObjBreadCrumbsADAE2} rootImage={this.state.theme === "LightTheme" ? lightUserIcon : darkUserIcon} />
        <br />
        <LiDivContainer containerHeight={parseInt(window.innerHeight - 10 - 220)} containerID="LiUsrMgmtSiteAddSub1" containerScrollY={true} containerScrollX={false}>
          <div className="twoColumnMainGridContainer">
            <div className={twoColumnMainGridContainerItem}>
              <LiLabel labelText={[this.liMultiterm("Name", "06001104"), <span className="messageRed">*</span>]} />
            </div>
            <div className={twoColumnMainGridContainerItem}>
              <LiLineEdit
                liEditID="nameField"
                liEditName="nameField"
                liEditValue={nameField}
                liEditStyle={{ width: "300px" }}
                liEditOnChange={this.handleChangeAppDetail.bind(this)}
                liEditOnKeyUp={this.handleChangeAppDetail.bind(this)}
              />
            </div>
            <div className={twoColumnMainGridContainerItem}>
              <LiLabel labelText={[this.liMultiterm("URL", "99000363"), <span className="messageRed">*</span>]} />
            </div>
            <div className={twoColumnMainGridContainerItem}>
              <LiLineEdit
                liEditID="urlField"
                liEditName="urlField"
                liEditValue={urlField}
                liEditStyle={{ width: "300px" }}
                liEditOnChange={this.handleChangeAppDetail.bind(this)}
                liEditOnKeyUp={this.handleChangeAppDetail.bind(this)}
              />
            </div>

            <div className={twoColumnMainGridContainerItem}>
              <LiLabel labelText={this.liMultiterm("Description", "06000358")} />
            </div>
            <div className={twoColumnMainGridContainerItem}>
              <LiLineEdit
                liEditID="descField"
                liEditName="descField"
                liEditStyle={{ width: "300px" }}
                liEditValue={descField}
                liEditOnChange={this.handleChangeAppDetail.bind(this)}
                liEditOnKeyUp={this.handleChangeAppDetail.bind(this)}
              />
            </div>

            <div className={twoColumnMainGridContainerItem}>
              <LiLabel labelText={this.liMultiterm("Type", "99000026")} />
            </div>
            <div className={twoColumnMainGridContainerItem}>
              <LiComboBox id="addEntityTypeField" multiSelect={false} onSelection={this.compTypeChange.bind(this)} value={(this.state.defaultTextForCombo)}>
                {this.liComboList()}
              </LiComboBox>
            </div>
          </div>
        </LiDivContainer>
        <LiDivContainer containerHeight="70" containerID="LiUsrMgmtUserAddSub2" containerScrollY={false} containerScrollX={false}>
          <hr className={theme + " LiUsrMgmtBottom"} />
          <div className="userMgmtGridContainerFull">
            <div className="userMgmtGridContainerFullItem">
              <LiForm
                height={350}
                formDialogTitle={<LiMultiterm textId="4000070" textVal="Warning" />}
                formDialogCloseText={<LiMultiterm textId="99000136" textVal="OK" />}
                requiredByState={stateRequiredADAE}
                requiredById={[]}
                allRequiredFilled={this.handleSubmitData.bind(this)}
                buttonLabel={<LiMultiterm textId="6001485" textVal="Save" />}
                formMessage={<LiMultiterm textId="99030257" textVal="Please fill all the required fields" />}
              />
              <Link to="/application_detail_ng">
                <LiButton buttonLabel={this.liMultiterm("Cancel", "6002006")} />
              </Link>
            </div>
          </div>
        </LiDivContainer>
      </div>
    );
  }
}

export default ApplicationDetailAdd;
