import React from "react";
import { Link, Redirect } from "react-router-dom";
import { LiMultiterm } from "lisecutilityfunctions/lib/utilitylib";
import * as conversionLib from "lisecutilityfunctions/lib/commonfunction";
import * as usrLib from './usr_lib';
import {
  OpenApiClient_ng_user_management_v1,
  getSiteName,
} from "lisecutilityfunctions/lib/openapi";

import {
  LiDivContainer,
  LiButton,
  LiBreadCrumbs,
  LiStatusManager,
} from "liseccomponents";
import { LiMasterDataOverview } from 'lisecdependantcomp';

import lightUserIcon from "./images/LightTheme/application_detail.svg";
import darkUserIcon from "./images/DarkTheme/application_detail.svg";

const applicationDetailTableConfiguration = {
  getRowDataConfiguration: {
    enableRowData: true,
  },
  linkConfiguration: {
    enableLink: false,
    link: "",
  },
  paginationConfiguration: {
    enablePaging: true,
    pageSize:
      conversionLib.getLocalStorageValue("table.pagesize.app.list") !== null
        ? conversionLib.getLocalStorageValue("table.pagesize.app.list")
        : 20,
    pageSizeCombo: [5, 10, 15, 20, 25, 50, 100, 200],
  },
  sortingConfiguration: {
    enableSorting: true,
    sortingColumns: {
      idField: ["00000", "ID"],
      nameField: ["00000", "Name"],
      urlField: ["00000", "URL"],
      descField: ["00000", "Desciption"],
      type: ["00000", "Type"],
    }, //Only Column Header Name
    defaultSortingColumn: "idField",
    defaultSortingOrder: "ASC",
  },
  searchConfiguration: {
    enableSearching: false,
    searchColumns: [
      "code",
      "full_name",
      "extn",
      "company_name",
      "email",
      "dept",
      "status",
    ], //Only Row Key
    searchOptionColumns: {
      status: { option1: "Online", option2: "Offline" },
      dept: {
        option1: "Accounting",
        option2: "Machinery",
        option3: "Software",
        option4: "IT",
      },
    },
  },
  actionConfiguration: {
    showAction: true,
    actionItems: {
      //   View: ["99000829", "View"],
      Edit: ["6001743", "Edit"],
      Delete: ["99003381", "Delete"],
    },
    receiverFunction: "getDataFromTable",
  },
  dataTypeConfiguration: {
    idField: "link",
    nameField: "text",
    urlField: "text",
    descField: "text",
    type: "number",
  },
  multiSelectable: {
    enabled: true,
    showCheckboxes: true,
  },
  enableTrigger: false,
};

const applicationDetailsTableHeader = {
  idField: ["00000", "ID"],
  nameField: ["00000", "Name"],
  urlField: ["00000", "URL"],
  descField: ["00000", "Desciption"],
  type: ["00000", "Type"],
};

class ApplicationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme:
        conversionLib.getLocalStorageValue("core.app.theme") !== null
          ? conversionLib.getLocalStorageValue("core.app.theme")
          : "",
      tableData: {},
      app_delete_open: false,
      rowID: null,
      selectedRowData: [],
      enableRouteADL: {},
      currentActionADL: "",
      deleteAllSiteApplication: false,
      reloadData: false,
      multitermTextCommon: {
        yesText: <LiMultiterm textId="99001613" textVal="Yes" />,
        noText: <LiMultiterm textId="99000054" textVal="No" />,
      },
      multitermText: {
        deleteSingleTitle: <LiMultiterm textId="99031032" textVal="Delete App" />,
        deleteSingleText: <LiMultiterm textId="99031033" textVal="Do you want to delete the app" />,
        deleteSelectedTitle: <LiMultiterm textId="99031032" textVal="Delete App" />,
        deleteSelectedText: <LiMultiterm textId="99031034" textVal="Do you want to delete selected app?" />,
      }
    };
    this.dataObjBreadCrumbsADL = {
      "User Management": {
        label: <LiMultiterm textId="99005135" textVal="User Management" />,
        path: "/usermain_ng",
      },
      Sites: {
        label: <LiMultiterm textId="99030950" textVal="Application Detail" />,
        path: "/application_detail_ng",
      },
    };
    this.handleDialogCloseAppDetailList = this.handleDialogCloseAppDetailList.bind(this);
    this.getDataFromTable = this.getDataFromTable.bind(this);
    this.handleDeleteAppDetailData = this.handleDeleteAppDetailData.bind(this);
  }
  componentDidMount() {
    this.props.loader.showLoader({ GET_Application: true });

    let siteName = getSiteName();
    OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Applications(
      this.callbackForAllApplicationDetail.bind(this)
    );
  }
  callbackForAllApplicationDetail(responseData) {
    this.props.loader.showLoader({ GET_Application: false });

    if (responseData.state.response.status === 200) {
      var jsonData = conversionLib.convertToJSON(
        responseData.state.response.data
      );
      jsonData.applicationDetails.forEach((data) => {
        if (data.type === 0) {
          data.type = "Global";
        } else if (data.type === 1) {
          data.type = "Customer";
        } else if (data.type === 2) {
          data.type = "Site";
        }
      });
      Object.keys(applicationDetailsTableHeader).forEach((headKey) => { });

      if (jsonData !== undefined) {
        var tableData = usrLib.convertToObject(jsonData,"applicationDetails");
        // jsonData.forEach(data => {
        // })
        this.setState({
          reloadData: true,
          tableData: tableData,
          appId: "",
          rowID: "",
        });
      }
    } else {
      LiStatusManager.showStatus(
        responseData.state.response.status,
        responseData.state.response.message
      );
    }
  }
  handleData(selectedRows, rowID, rowData) {
    this.setState({ selectedRowData: selectedRows });
  }

  getDataFromTable = function (rowID, jobTitleRecObj, actionName, dummy1, dummy2, dummy3, actions) {
    if (rowID !== undefined) {
      this.setState({
        rowID: rowID,
        appId: this.state.tableData[rowID].idField,
      });
    }
    if (actions.key === "View") {
      this.setState({ currentActionADL: "view" });
      var enableRouteADL = this.state.enableRouteADL;
      enableRouteADL["view"] = {
        status: true,
        link:
          "/application_detail_view_ng?appid=" +
          this.state.tableData[rowID].idField,
      };
      this.setState({ enableRouteADL });
    }
    this.getDataFromTable1(actions, rowID);
  };

  getDataFromTable1(actions, rowID) {
    if (actions.key === "Edit") {
      this.setState({ currentActionADL: "edit" });
      var enableRouteADL = this.state.enableRouteADL;
      enableRouteADL["edit"] = {
        status: true,
        link:
          "/application_detail_edit_ng?appid=" +
          this.state.tableData[rowID].idField,
      };
      this.setState({ enableRouteADL });
    }
    if (actions.key === "Delete") {
      this.setState({ app_delete_open: true });
    }
  }
  handleDialogCloseAppDetailList() {
    if (this.state.app_delete_open) {
      this.setState({ app_delete_open: false });
    }
    if (this.state.deleteAllSiteApplication) {
      this.setState({
        deleteAllSiteApplication: false,
      });
    }
  }

  handleDeleteAppDetailData(appID) {
    this.props.loader.showLoader({ DELETE_Application_appId: true });

    this.setState({ site_list_delete_confirm_yes_disabled: true });

    let siteName = getSiteName();
    OpenApiClient_ng_user_management_v1.getClient(
      siteName
    ).DELETE_Applications_appId(
      this.callbackWithArgForDeleteApplicationAppId.bind(this),
      appID
    );
  }

  callbackWithArgForDeleteApplicationAppId(responseData) {
    let response = responseData.state.response;
    this.props.loader.showLoader({ DELETE_Application_appId: false });

    if (response.status === 200) {
      this.handleLoadAppList();
      this.handleDialogCloseAppDetailList();
      this.setState({ site_list_delete_confirm_yes_disabled: false });
    } else {
      this.handleDialogCloseAppDetailList();

      let message = "";
      if (response.data !== undefined) {
        message = conversionLib.convertToJSON(response.data);
        if (typeof message === "object") {
          message = message.message;
        }

        message += " ";
      }

      LiStatusManager.showStatus(
        response.status,
        message + response.statusText
      );
    }
  }

  handleDeleteAllSiteApplicationDataDailog() {
    this.setState({
      deleteAllSiteApplication: true,
    });
  }

  handleDeleteAllApplicationData() {
    if (this.state.selectedRowData.length > 0) {
      this.state.selectedRowData.forEach((eachRowId) => {
        var eachEntityIdField = eachRowId.value.idField;
        this.props.loader.showLoader({ DELETE_Application_entityId: true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(
          siteName
        ).DELETE_Applications_appId(
          this.callbackhandleDeleteAllSiteApplicationData.bind(this),
          eachEntityIdField
        );
      });
    }
  }

  callbackhandleDeleteAllSiteApplicationData(responseData) {
    let response = responseData.state.response;
    var deleteCount = 0;
    if (response.status === 200) {
      deleteCount++;

      if (this.state.selectedRowData.length === deleteCount) {
        this.props.loader.showLoader({ DELETE_Application_entityId: false });
      }
    } else {
      this.props.loader.showLoader({ DELETE_Application_entityId: false });

      let message = "";
      if (response.data !== undefined) {
        message = conversionLib.convertToJSON(response.data);
        if (typeof message === "object") {
          message = message.message;
        }

        message += " ";
      }

      LiStatusManager.showStatus(
        response.status,
        message + response.statusText
      );
    }

    this.setState({
      selectedRowData: [],
    });
    this.handleLoadAppList();
    this.handleDialogCloseAppDetailList();
  }

  handleLoadAppList() {
    this.props.loader.showLoader({ GET_Application: true });

    this.setState({ reloadData: false });

    let siteName = getSiteName();
    OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Applications(
      this.callbackForAllApplicationDetail.bind(this)
    );
  }

  linkData(rowData) {
    this.setState({ currentActionADL: "edit" });
    var enableRouteADL = this.state.enableRouteADL;
    enableRouteADL["edit"] = {
      status: true,
      link: "/application_detail_edit_ng?appid=" + rowData.value,
    };
    this.setState({ enableRouteADL });
  }

  render() {
    return (
      <div>
        {this.state.enableRouteADL[this.state.currentActionADL] !== undefined &&
          this.state.enableRouteADL[this.state.currentActionADL]["status"] === true ? (
          <Redirect
            to={this.state.enableRouteADL[this.state.currentActionADL]["link"]}
          />
        ) : null}
        <LiBreadCrumbs
          breadcrumbsdataObj={this.dataObjBreadCrumbsADL}
          rootImage={
            this.state.theme === "LightTheme" ? lightUserIcon : darkUserIcon
          }
        />

        <LiDivContainer
          containerHeight={parseInt(window.innerHeight - 130)}
          containerID="site.list"
          containerScrollY={true}
          containerScrollX={false}
        >
          <LiMasterDataOverview
            tableId="ng.um.app.det.list"
            tableConfig={applicationDetailTableConfiguration}
            colHeaderArr={applicationDetailsTableHeader}
            masterListData={this.state.tableData}
            handleData={this.handleData.bind(this)}
            linkData={this.linkData.bind(this)}
            receiverFunction={this.getDataFromTable}

            reloadList={this.state.reloadData}

            handleDialogClose={this.handleDialogCloseAppDetailList.bind(this)}
            handleDeleteMasterData={this.handleDeleteAppDetailData.bind(this)}
            handleDeleteSelected={this.handleDeleteAllApplicationData.bind(this)}

            translationTextsCommon={this.state.multitermTextCommon}
            translationTexts={this.state.multitermText}

            exportFileName="ng-um-app-det-list"

            buttons={[
              {
                addButton: <Link to="/application_detail_add_ng">
                  <LiButton
                    buttonLabel={
                      <LiMultiterm textId="99030951" textVal="Add Application" />
                    }
                  />
                </Link>
              },
              {
                deleteButton: <LiButton
                  buttonLabel={<LiMultiterm textId="6000749" textVal="Delete" />}
                  buttonDisabled={
                    this.state.selectedRowData.length === 0 ? true : false
                  }
                  buttonOnClickHandler={this.handleDeleteAllSiteApplicationDataDailog.bind(
                    this,
                    2
                  )}
                />
              }
            ]}
            masterDataID={this.state.appId}
            deleteOpenMasterRec={this.state.app_delete_open}
            deleteSelectedMasterRecs={this.state.deleteAllSiteApplication}
          />
        </LiDivContainer>
      </div>
    );
  }
}

export default ApplicationDetail;
