export const setResponseDataSite = (value) => {
    return{
        type: "SET_RESPONSE_DATA_SITE",
        payload: value
    }
    
};

export const setViewDialogSite = (value) => {
    return{
        type: "SET_VIEW_DIALOG_SITE",
        payload: value
    }
    
};

export const setEditDialogSite = (value) => {
    return{
        type: "SET_EDIT_DIALOG_SITE",
        payload: value
    }
    
};

export const setDeleteDialogSite = (value) => {
    return{
        type: "SET_DELETE_DIALOG_SITE",
        payload: value
    }
    
};

export const setSiteID = (value) => {
    return{
        type: "SET_SITEID_SITE",
        payload: value
    }
    
};

export const setRowIDSite = (value) => {
    return{
        type: "SET_ROWID_SITE",
        payload: value
    }
    
};