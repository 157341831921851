import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiBreadCrumbs, LiDivContainer, LiLabel, LiStatusManager, LiStatusContainer } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';

const baseUrl = window.location;

class SiteView extends Component {

    constructor() {
        super();

        this.state = {
            currentSiteDataIndividual: {},
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
        };

        this.dataObjBreadCrumbsSV = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Sites": {
                "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                "path": "/sites_ng"
            },
            "View Details": {
                "label": <LiMultiterm textId="99005780" textVal="View Details" />,
                "path": "#"
            }
        };
    }

    callbackWithArgFromViewSite(responseData) {
        this.props.loader.showLoader({ "GET_Site_entityId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({ currentSiteDataIndividual: jsonData });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    componentDidMount() {
        var siteID = (conversionLib.getParameterByName('entityid', baseUrl.href) !== null && conversionLib.getParameterByName('entityid', baseUrl.href) !== '') ? conversionLib.getParameterByName('entityid', baseUrl.href) : 2;
        this.props.loader.showLoader({ "GET_Site_entityId": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites_entityId(this.callbackWithArgFromViewSite.bind(this), siteID);
    }

    render() {
        return (
            <div>
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSV} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 150)} containerID="site.view" containerScrollY={true} containerScrollX={true} >
                    <div className="twoColumnMainGridContainer">
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99001692" textVal="ID #" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.entityIdField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.entityDescField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short description" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.entityShdescField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="6000676" textVal="Type" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.entityTypeField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.disabledField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99028779" textVal="Host IP" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.hostIpField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99030902" textVal="Base Port" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentSiteDataIndividual.portOffsetField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99028780" textVal="DB Type" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.props.sitemgmt.DBTypeObj[this.state.currentSiteDataIndividual.dbTypeField]} />
                        </div>

                    </div>
                    <LiStatusContainer />
                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sitemgmt: state.sitemgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteView);
