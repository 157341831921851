import './usr.css';

import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sha3_512 } from 'js-sha3';
import * as UserAction from '../actions/useraction';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiBody, MimeTypes, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiBreadCrumbs, LiStatusManager, LiLineEdit, LiReactDialog, LiLabel } from 'liseccomponents';
import { LiMasterDataOverview } from 'lisecdependantcomp';

import { LiTable } from 'lisecdependantcomp';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';

const choiceObject = {
    0: ['Default', 99001446],
    1: ['Shopfloor Operator', 99030183],
    2: ['Shopfloor Workstation', 99030184]
};

var textNumberArr = [];

/*--------------------------- Table Configuration -------------------------------*/
const userTableConfiguration = {
    "getRowDataConfiguration": {
        "enableRowData": true,
    },
    "linkConfiguration": {
        "enableLink": false,
        "link": '/user_edit_ng?userid='
    },

    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": 5,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": {
            "userId": ["99001692", "ID #"], "firstName": ["99024423", "First Name"], "lastName": ["99024422", "Last Name"], "userType": ["99024421", "User Type"], "loginName": ["99010629", "Login Name"]
        },
        "defaultSortingColumn": "full_name",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["code", "full_name", "extn", "company_name", "email", "dept", "status"],           //Only Row Key 
        "searchOptionColumns": { "status": { "option1": "Online", "option2": "Offline" }, "dept": { "option1": "Accounting", "option2": "Machinery", "option3": "Software", "option4": "IT" } }
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
            "Edit": ['6001743', "Edit"],
            "Delete": ['99003381', "Delete"],
            "Change log": ['99025943', "Change log"],
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "userId": "link",
        "firstName": "text",
        "lastName": "text",
        "loginName": "text",
        "userType": "text"
    },
    "multiSelectable": {
        "enabled": true,
        "showCheckboxes": true
    },
    "enableTrigger": false
};


const userAddDataVal = {
    "interfaceInfo": {
        "type": "MT_USER_DATA",
        "version": 1
    },
    "userData": {
        "interfaceInfo": {
            "type": "MT_MASTER_DATA",
            "version": 1,
            "item_type": "ITEM_TYPE_MASTER_USER"
        },
    },
    "avatar": {
        "key": {
            "useCase": 11,
            "dataSource": "avatar",
            "dataType": "CFCT_BINARY"
        },
        "fileName": "asdf.png",
    }
}

const userTableConfiguration1 = {
    "getRowDataConfiguration": {
        "enableRowData": false,
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") !== null ? conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") : 20,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": { "loginNameField": ["99001692", "ID #"], "firstNameField": ["99024423", "First Name"], "lastNameField": ["99024422", "Last Name"] },                      //Only Column Header Name
        "defaultSortingColumn": "ID",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["code", "lastNameField", "firstNameField", "emailField"],           //Only Row Key 
        "searchOptionColumns": {}
    },
    "dataTypeConfiguration": {
        "loginNameField": "number",
        "firstNameField": "text",
        "lastNameField": "text",
        "emailField": "text"
    },
    "multiSelectable": {
        "enabled": true,
        "showCheckboxes": true
    },
    "enableTrigger": false
};

const tableHeader = {
    loginNameField: ["99000636", "ID"],
    lastNameField: ["99024422", "Last Name"],
    firstNameField: ["99024423", "First Name"],
    emailField: ["99004834", "Email ID"],
};

// Method to convert the Object to Array
// function convertObjectToArray(stringVal) {
//     var arr = Object.keys(stringVal).map(function (k) {
//         return { key: k, value: stringVal[k] }
//     });
//     return (arr);
// };


class UserList extends Component {
    constructor() {
        super();

        this.state = {
            reloadData: false,
            list_delete_confirm_yes_disabled: false,
            theme: (conversionLib.getLocalStorageValue("core.app.theme") !== null) ? conversionLib.getLocalStorageValue("core.app.theme") : {},
            innerHeight: 0,
            enableRouteUL: {},
            currentActionUL: '',
            selectedRowData: [],
            deleteAllUser: false,
            comboChoice: {},

            addUserFromAdDialog: false,
            filterValue: "",

            tableData: null,

            multitermTextCommon: {
                yesText: <LiMultiterm textId="99001613" textVal="Yes" />,
                noText: <LiMultiterm textId="99000054" textVal="No" />,
            },
            multitermText: {
                deleteSingleTitle: <LiMultiterm textId="99025175" textVal="Delete User" />,
                deleteSingleText: <LiMultiterm textId="99031018" textVal="Do you want to delete the user" />,
                deleteSelectedTitle: <LiMultiterm textId="99025175" textVal="Delete User" />,
                deleteSelectedText: <LiMultiterm textId="99031017" textVal="Do you want to delete selected users ?" />,
            }
        }

        this.getDataFromTable = this.getDataFromTable.bind(this);
        this.handleDialogCloseUserList = this.handleDialogCloseUserList.bind(this);
        this.handleLoadList = this.handleLoadList.bind(this);
        this.handleDeleteData = this.handleDeleteData.bind(this);

        this.dataObjBreadCrumbsUL = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Users": {
                "label": <LiMultiterm textId="99028960" textVal="Users" />,
                "path": "/users_ng"
            }
        }
    }

    callbackWithArg(responseData) {
        this.props.loader.showLoader({ "GET_Users": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            Object.keys(this.props.usermgmt.userHeaderArr).forEach(headKey => {
                jsonData.users.forEach((user) => {
                    if (user[headKey] === undefined) {
                        user[headKey] = '';
                    }
                });
            });

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.props.setResponseData(this.convertToObject(jsonData, "users"));
                this.setState({
                    reloadData: true
                })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackForAllSiteOrDeptUserList(responseDataSiteDepUL) {
        if (responseDataSiteDepUL.state.response.status === 200) {
            var jsonDataSiteDepUL = conversionLib.convertToJSON(responseDataSiteDepUL.state.response.data);
            if (jsonDataSiteDepUL !== undefined && Object.keys(jsonDataSiteDepUL).length !== 0) {
                var tempArrSiteDepUL = {};
                Object.keys(jsonDataSiteDepUL.companyEntities).forEach(function (k, item) {
                    tempArrSiteDepUL[jsonDataSiteDepUL.companyEntities[k].entityIdField] = jsonDataSiteDepUL.companyEntities[k].entityDescField
                })
                this.props.setUserSiteOrDept(tempArrSiteDepUL);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataSiteDepUL.state.response.status, responseDataSiteDepUL.state.response.message);
        }
    }

    callbackDeleteUserUserId(responseDataDeleteUL) {
        this.props.loader.showLoader({ "DELETE_Users_userId": false });

        if (responseDataDeleteUL.state.response.status === 200) {
            this.handleLoadList();
            this.handleDialogCloseUserList();
            this.setState({ list_delete_confirm_yes_disabled: false });
        }
        else {
            this.handleDialogCloseUserList();

            let message = "";
            if (responseDataDeleteUL.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseDataDeleteUL.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }

            LiStatusManager.showStatus(responseDataDeleteUL.state.response.status, message + responseDataDeleteUL.state.response.statusText);
        }
    }

    convertToObject(data, type) {
        var opt = {};
        for (var i = 0; i < data[type].length; i++) {
            var multiTextValue = "";

            if (data[type][i]['userType'] !== undefined && data[type][i]['userType'] !== '') {

                if (this.state.comboChoice[data[type][i]['userType']] !== undefined) {
                    multiTextValue = this.state.multilangDataVal[String(this.state.comboChoice[data[type][i]['userType']][1])]
                }

                data[type][i]['userType'] = multiTextValue;
            }
            else if (data[type][i]['userType'] === '') {
                if (this.state.comboChoice[0] !== undefined) {
                    multiTextValue = this.state.multilangDataVal[String(this.state.comboChoice[0][1])]
                }

                data[type][i]['userType'] = multiTextValue;
                // data[type][i]['userType'] = this.state.comboChoice[0];
            }
            else {
                data[type][i]['userType'] = "";
            }

            if (data[type][i]['firstName'] === undefined || data[type][i]['firstName'] === null) {
                data[type][i]['firstName'] = "";
            }
            if (data[type][i]['lastName'] === undefined || data[type][i]['lastName'] === null) {
                data[type][i]['lastName'] = "";
            }

            opt[i] = data[type][i];
        }

        return (opt);
    }

    callbackWithArgForRoleObjUserList(responseDataRoleObjUL) {
        if (responseDataRoleObjUL.state.response.status === 200) {
            var jsonDataRoleObjUL = conversionLib.convertToJSON(responseDataRoleObjUL.state.response.data);
            if (jsonDataRoleObjUL !== undefined && Object.keys(jsonDataRoleObjUL).length !== 0) {
                var roleValObjUL = {};
                Object.keys(jsonDataRoleObjUL.roles).forEach(function (k, item) {
                    roleValObjUL[jsonDataRoleObjUL.roles[k].roleIdField] = jsonDataRoleObjUL.roles[k].roleDescField;
                })
                this.props.setRoleDetailObj(roleValObjUL);
            }
        }
        else {
            console.log("RESPONSE : " + responseDataRoleObjUL.state.response);
        }
    }

    callbackWithArgJobTitles(responseData) {
        if (responseData.state.response !== undefined) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.props.setAllUserJobTitle(this.convertToObject(jsonData, "jobTitles"));
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithChoices = (responseData) => {
        if (responseData.state.response.status === 200) {
            var result = conversionLib.convertToJSON((responseData.state.response.data));
            this.setChoices(result.choice);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }

        const key = "usermgmt.userlist.getuser";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users(this.callbackWithArg.bind(this), true, key);
    }

    callBackHandlGetLanguageLangID3TextUserList(responseDataLangUL) {
        if (responseDataLangUL.state.response !== undefined && responseDataLangUL.state.response.status === 200) {
            var jsonDataLangUL = conversionLib.convertToJSON(responseDataLangUL.state.response.data);
            var tempArr = {};
            Object.keys(jsonDataLangUL).forEach(function (k, item) {
                tempArr[jsonDataLangUL[k].number] = jsonDataLangUL[k].text
            })
            this.setState({ multilangDataVal: tempArr })
        }
        else {
            console.log("RESPONSE : " + responseDataLangUL.state.response);
        }
    }

    // async setChoices(choices){
    //     if (choices[0].choiceEntry !== undefined) {
    //         var choiceArr = {};
    //          var textNumberArr = [];
    //         choices[0].choiceEntry.forEach((ChoiceText, index) => {
    //             choiceArr[ChoiceText.keyInt] = [ChoiceText.displayToken[0].tokenTxtfallback,ChoiceText.displayToken[0].tokenTxtno]
    //             if(ChoiceText.displayToken[0].tokenTxtno > 0) {
    //                 textNumberArr.push(ChoiceText.displayToken[0].tokenTxtno);
    //             }
    //         })

    //         if(textNumberArr.length > 0){
    //             var tmpArrayofText = await getMultitermTexts(textNumberArr) 
    //             this.callBackHandlGetLanguageLangID3TextUserList(tmpArrayofText)
    //         }
    //     }
    //     this.setState({comboChoice : choiceArr})
    // }

    componentDidMount() {
        this.props.loader.showLoader({ "GET_Users": true });

        let siteName = getSiteName();
        // console.log(siteName,"11111111111111111");
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2107']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserList.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_JobTitles(this.callbackWithArgJobTitles.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackForAllSiteOrDeptUserList.bind(this));

        this.setState({ innerHeight: parseInt(window.innerHeight) })

        this.setChoices();
    }

    async setChoices() {
        var textNumberArr = [];

        Object.values(choiceObject).forEach((eachObject, index) => {
            if (eachObject[1] > 0) {
                textNumberArr.push(eachObject[1]);
            }
        })

        if (textNumberArr.length > 0) {
            var tmpArrayofText = await getMultitermTexts(textNumberArr)
            this.callBackHandlGetLanguageLangID3TextUserList(tmpArrayofText)
        }

        this.setState({
            comboChoice: choiceObject
        },
            () => {
                let siteName = getSiteName();
                // console.log(siteName,"222222222222222");
                const key = "usermgmt.userlist.getuser";
                window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

                OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users(this.callbackWithArg.bind(this), true, key);
            }
        );
    }





    handleDialogCloseUserList() {
        if (this.props.usermgmt.delete_open) {
            this.props.setDeleteDialog(false);
        }

        if (this.state.deleteAllUser) {
            this.setState({ deleteAllUser: false })
        }

        if (this.state.addUserFromAdDialog) {
            this.setState({
                addUserFromAdDialog: false,
                tableData: null,
                filterValue: ""
            })
        }
    }

    addUserDailog() {
        this.props.setAddDialog(true);
    }

    getDataFromTable = function (rowID, userRecObj, actionName, dummy1, dummy2, dummy3, actions) {
        if (rowID !== undefined) {
            this.props.setUserID(this.props.usermgmt.myRowArr[rowID].userId);
            this.props.setRowID(rowID);
        }

        if (actions.key === "View") {
            this.props.setRowID(rowID);
            this.setState({ currentActionUL: 'view' })
            var enableRouteUL = this.state.enableRouteUL;
            enableRouteUL['view'] = { status: true, link: '/user_view_ng?userid=' + this.props.usermgmt.myRowArr[rowID].userId }
            this.setState({ enableRouteUL });
        }
        if (actions.key === "Change log") {
            this.props.setRowID(rowID);
            this.setState({ currentActionUL: 'change-log' })
            let enableRouteUL = this.state.enableRouteUL;
            enableRouteUL['change-log'] = { status: true, link: '/user_filtered_change_log_ng?userid=' + this.props.usermgmt.myRowArr[rowID].userId  }
            this.setState({ enableRouteUL });
        }

        this.getDataFromTable1(rowID, actions);
    }

    getDataFromTable1(rowID, actions) {
        if (actions.key === "Edit") {
            this.setState({ currentActionUL: 'edit' })
            var enableRouteUL = this.state.enableRouteUL;
            enableRouteUL['edit'] = { status: true, link: '/user_edit_ng?userid=' + this.props.usermgmt.myRowArr[rowID].userId }
            this.setState({ enableRouteUL });
        }

        if (actions.key === "Delete") {
            this.props.setDeleteDialog(true);
            this.props.setRowID(rowID);
        }
    }

    elementRemoveFromArray = (dataValueArr, value) => {
        if (value !== undefined && value !== null) {
            var index = dataValueArr.indexOf(value);
            if (index > -1) {
                dataValueArr.splice(index, 1);
            }
        }
        return dataValueArr
    }

    handleData(selectedRows, rowID, rowData) {
        this.setState({ selectedRowData: selectedRows })
    }

    handleLoadList() {
        this.setState({ reloadData: false });

        this.props.loader.showLoader({ "GET_Users": true });

        const key = "usermgmt.userlist.handlelist";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users(this.callbackWithArg.bind(this), true, key);
    }

    handleDeleteData(userID) {
        this.setState({ list_delete_confirm_yes_disabled: true });
        this.props.loader.showLoader({ "DELETE_Users_userId": true });

        const key = "usermgmt.userlist.handledeletedata";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Users_userId(this.callbackDeleteUserUserId.bind(this), userID, true, key);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: (conversionLib.getLocalStorageValue("core.app.theme") !== null) ? conversionLib.getLocalStorageValue("core.app.theme") : {}
        });
    }

    callBackHandleUserAddData(responseData) {
        this.props.loader.showLoader({ "POST_Users": false });
        // console.log("USER ADDED");
        if (responseData.state.response !== undefined) {
            if (responseData.state.response.data !== undefined) {
                if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
                    this.handleDialogCloseUserList();
                    this.handleLoadList();
                    this.props.usermgmt.resetUserDataAdd();
                }
            }
        }
        else {
            console.log("RESPONSE : " + responseData.state.response);
        }
    }

    onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                conversionLib.setLocalStorageValue("config.background", e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    callbackhandleDeleteUserData(responseData) {
        this.props.loader.showLoader({ "DELETE_Users_userId": false });

        if (responseData.state.response.status === 200) {
            this.handleLoadList();
            this.handleDialogCloseUserList();
        }
        else {
            let message = "";
            if (responseData.state.response.data !== undefined) {
                message = conversionLib.convertToJSON(responseData.state.response.data);
                if (typeof message === "object") {
                    message = message.message;
                }

                message += " ";
            }

            LiStatusManager.showStatus(responseData.state.response.status, message + responseData.state.response.statusText);
        }
    }

    handleDeleteUserDataDailog() {
        this.setState({ deleteAllUser: true });
    }

    handleDeleteUserData() {

        if ((this.state.selectedRowData).length > 0) {
            this.state.selectedRowData.forEach((eachRowData) => {
                // var eachUserID = this.props.usermgmt.myRowArr[eachRowData].userId;
                let eachUserID = eachRowData.value.userId;

                this.props.loader.showLoader({ "DELETE_Users_userId": true });

                let siteName = getSiteName();
                OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Users_userId(this.callbackhandleDeleteUserData.bind(this), parseInt(eachUserID));
            })
        }
    }

    linkData(rowData) {
        this.setState({ currentActionUL: 'edit' });
        var enableRouteUL = this.state.enableRouteUL;
        enableRouteUL['edit'] = { status: true, link: '/user_edit_ng?userid=' + rowData.value.toString() }
        this.setState({ enableRouteUL });
    }

    handleAddUserAdDataDailog() {
        this.setState({
            addUserFromAdDialog: true
        })
    }

    setText = (evt) => {
        this.setState({
            filterValue: evt.target.value
        })
    }

    getAzureAdUser() {
        this.props.loader.showLoader({ "Get_Azure_User": true });
        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_azureADQuery(this.callbackGetAzureAdUser.bind(this), this.state.filterValue)
    }

    callbackGetAzureAdUser(responseData) {
        this.props.loader.showLoader({ "Get_Azure_User": false });
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            jsonData.forEach(data => {
                if (data.userIdField > 0) {
                    data.disabled = true;
                } else {
                    data.disabled = false
                }
            })
            this.setState({
                tableData: jsonData
            })
            // console.log(jsonData);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    async handleAddAzureUser() {
        let siteName = getSiteName();
        const key = "usermgmt.userlist.postusers";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };
        if (this.state.selectedRowData.length > 0) {
            // console.log("CALL WENT");
            this.props.loader.showLoader({ "POST_Users": true });
            this.state.selectedRowData.forEach(data => {
                var newuserAddDataVal = JSON.parse(JSON.stringify(userAddDataVal))
                var tempObj = {
                    "firstNameField": data.value.firstNameField,
                    "lastNameField": data.value.lastNameField,
                    "loginNameField": data.value.loginNameField,
                    "emailField": data.value.emailField,
                    "userTypeField": 0,
                    "activeDirectoryUser": true
                }
                newuserAddDataVal.userData = { ...newuserAddDataVal.userData, ...tempObj }
                OpenApiClient_ng_user_management_v1.getClient(siteName).POST_Users(this.callBackHandleUserAddData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, newuserAddDataVal), true, key);
            })
        }
    }



    render() {
        // if (Object.keys(this.props.usermgmt.myRowArr).length !== 0) {
        return (
            <div>
                {this.state.enableRouteUL[this.state.currentActionUL] !== undefined && this.state.enableRouteUL[this.state.currentActionUL]['status'] === true ? <Redirect to={this.state.enableRouteUL[this.state.currentActionUL]['link']} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsUL} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 130)} containerID="user.list" containerScrollY={true} containerScrollX={true} >
                    <LiMasterDataOverview
                        tableId="ng.um.user.list"
                        tableConfig={userTableConfiguration}
                        colHeaderArr={this.props.usermgmt.userHeaderArr}
                        masterListData={this.props.usermgmt.myRowArr}
                        handleData={this.handleData.bind(this)}
                        linkData={this.linkData.bind(this)}
                        receiverFunction={this.getDataFromTable}

                        reloadList={this.state.reloadData}

                        handleDialogClose={this.handleDialogCloseUserList.bind(this)}
                        handleDeleteMasterData={this.handleDeleteData.bind(this)}
                        handleDeleteSelected={this.handleDeleteUserData.bind(this)}

                        translationTextsCommon={this.state.multitermTextCommon}
                        translationTexts={this.state.multitermText}

                        exportFileName="ng-um-user-list"

                        buttons={[
                            {
                                addButton: <Link to="/user_add_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99025174" textVal="Add User" />}
                                    />
                                </Link>
                            },
                            {
                                addButtonAzureAD: <LiButton
                                    buttonLabel={<LiMultiterm textId="99031539" textVal="Add user from AD" />}
                                    buttonOnClickHandler={this.handleAddUserAdDataDailog.bind(this, 2)}
                                />
                            },
                            {
                                deleteButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="6000749" textVal="Delete" />}
                                    buttonDisabled={this.state.selectedRowData.length === 0 ? true : false}
                                    buttonOnClickHandler={this.handleDeleteUserDataDailog.bind(this, 2)}
                                />
                            },
                            {
                                reloadButton: <LiButton
                                    buttonLabel={<LiMultiterm textId="99007226" textVal="Reload" />}
                                    buttonOnClickHandler={this.handleLoadList.bind(this)}
                                />
                            },
                            {
                                auditButton: <Link to="/user_change_log_ng">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99025943" textVal="Change log" />}
                                    />
                                </Link>
                            },
                        ]}
                        masterDataID={this.props.usermgmt.userID}
                        deleteOpenMasterRec={this.props.usermgmt.delete_open}
                        deleteSelectedMasterRecs={this.state.deleteAllUser}
                    />
                </LiDivContainer>
                {
                    this.state.addUserFromAdDialog
                        ?
                        <LiReactDialog
                            enableStoring="li.user.add"
                            title={<LiMultiterm textId="99031539" textVal="Add user from AD" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            modal={true}
                            onClose={this.handleDialogCloseUserList}
                            hasCloseIcon={true}
                            isResizable={true}
                            isDraggable={true}
                            height={300}
                            width={500}
                            buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="" textVal="Cancel" />,
                                        onClick: () => this.handleDialogCloseUserList(),
                                        // className: this.state.theme + " dialogButton"
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        >
                            <div>
                                <br />
                                <LiLabel labelText={<LiMultiterm textId="99031540" textVal="Search User" />} />
                                <div className="Usermanagement_AzureAD_SearchUser">
                                    <LiLineEdit liEditID="t4" liReadOnly={false} liEditValue={this.state.filterValue} liEditOnChange={this.setText} liEditDisable={false} liEditRequired={false} liEditOnKeyUp={this.setText} />
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="" textVal="Search" />}
                                        buttonOnClickHandler={this.getAzureAdUser.bind(this)}
                                        buttonDisabled={this.state.filterValue.length > 0 ? false : true}
                                    />
                                </div>
                                {this.state.tableData ? <div className="AddUserADTableContainer">
                                    <LiButton
                                        buttonLabel={<LiMultiterm textId="99031541" textVal="Add Selected Users" />}
                                        buttonOnClickHandler={this.handleAddAzureUser.bind(this)}
                                        buttonDisabled={this.state.selectedRowData.length > 0 ? false : true}
                                    />
                                    <LiTable
                                        handleData={this.handleData.bind(this)}
                                        tableId="userAzureTable"
                                        colHeaderArr={tableHeader}
                                        config={userTableConfiguration1}
                                        rowArr={this.state.tableData}
                                        receiverFunction={this.getDataFromTable}
                                        subTable={true}
                                        linkData={this.linkData.bind(this)}
                                        enableColSearch={true}
                                    />
                                </div>
                                    : ""}
                                <br /><br />
                            </div>
                        </LiReactDialog>
                        : ''
                }

            </div>
        );
        // }
        // else {
        //     return null;
        // }
    }
}

function mapStateToProps(state) {
    return {
        usermgmt: state.usermgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setResponseData: UserAction.setResponseData,
        setViewDialog: UserAction.setViewDialog,
        setAddDialog: UserAction.setAddDialog,
        setEditDialog: UserAction.setEditDialog,
        setDeleteDialog: UserAction.setDeleteDialog,
        setUserID: UserAction.setUserID,
        setRowID: UserAction.setRowID,
        resetUserDataAdd: UserAction.resetUserDataAdd,
        setRoleDetailObj: UserAction.setRoleDetailObj,
        setAllUserJobTitle: UserAction.setAllUserJobTitle,
        setUserSiteOrDept: UserAction.setUserSiteOrDept,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
