import React			    from 'react';
import ReactDOM			    from 'react-dom';
import {setLocalStorageValue, getLocalStorageValue} from 'lisecutilityfunctions/lib/commonfunction';
import axios			    from 'axios';
import * as configApp	    from './configapp';
import loadable             from 'react-loadable';
import LoadingComp          from './mainframe/src/loadingcomponent/loadingcomponent';
import {LiMultiterm} from 'lisecutilityfunctions/lib/utilitylib';
import {getSiteName, checkAccessTokenIsValid}   from 'lisecutilityfunctions/lib/openapi';

const siteName = getSiteName();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    require('./config_dev');
} else {
    require('./config_prod');
}

const LoadingComponent = () => <LoadingComp appName="User Management" />;

const Mainframe = loadable({
  loader: () => import('./mainframe/index').then(module => module.default),
  loading: LoadingComponent
});

const setBaseURL = () => {
    return axios.get("./config_prod.json")
    .then(function (response) {
        if(response.data !== undefined){
            var config = response.data.config;
            if(config.baseURL !== undefined && process.env.NODE_ENV !== 'development'){
                window.config.configURL.baseURL = config.baseURL;
            }
            if(getLocalStorageValue("core.app.theme") === null){
                setLocalStorageValue("core.app.theme", config.theme);
            }
        }
    })
    .catch(function (error) {
        console.error(error, "Error in reading config file");
    })
}

const getBaseURL = async() => {
    let isLogin = true;
    if(window.config !== undefined && window.config.authMethod === "none"){
        isLogin = false;
    }
    if(getLocalStorageValue("core.app.theme") === null)
    {
        setLocalStorageValue("core.app.theme", 'LightTheme');
    }
 
    Promise.all([setBaseURL()])
    .then(function(results) {
        
        ReactDOM.render(
            <Mainframe appType = {checkAccessTokenIsValid(getLocalStorageValue("config.accessToken." + siteName))
          ? <LiMultiterm textId="99005135" textVal="User Management"/> : "User Management"} configApp = {configApp} isLogin = {isLogin}/>, 
            document.getElementById('root')
        );
    });
}

getBaseURL();
