import './usr.css';
import UserMgmtRoute from './usrmgmtroute';
import UserList from '../components/userlist';
import AddUser from '../components/useradd';
import UserView from '../components/userview';
import ChangeLog from '../components/changelog';
import ChangeLogView from '../components/changelogview';
import EditUser from '../components/useredit';
import SiteList from '../components/sitelist';
import AddSites from '../components/siteadd';
import SiteView from '../components/siteview';
import SiteEdit from '../components/siteedit';
import RoleList from '../components/roleslist';
import RoleAdd from '../components/roleadd';
import RoleView from '../components/roleview';
import RoleEdit from '../components/roleedit';
// import EntityList       from '../components/entitylist';
// import EntityAdd        from '../components/entityadd';
// import EntityView       from '../components/entityview';
// import EntityEdit       from '../components/entityedit';
// import JobTitleList     from '../components/jobtitlelist';
// import JobTitleAdd      from '../components/jobtitleadd';
// import JobTitlesView    from '../components/jobtitleview';
// import JobTitleEdit     from '../components/jobtitleedit';
// import ApplicationDetailAdd from './../components/applicationdetailadd';
// import ApplicationDetailView from './../components/applicationdetailview';
// import ApplicationDetailEdit from './../components/applicationdetailedit';

import ApplicationDetail from './../components/applicationdetailslist';
import ApplicationDetailAddEdit from './applicationdetailaddedit';


import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from '../reducers/index';


const store = createStore(allReducers);

class UserMgmtMain extends Component {
    render() {
        return (
            <Provider store={store}>
                <Switch>
                    <Route exact path='/' render={() => { return <UserMgmtRoute loader={this.props.loader} /> }} />
                    <Route exact path='/users_ng' render={() => { return <UserList loader={this.props.loader} /> }} />
                    <Route exact path='/user_add_ng' render={() => { return <AddUser loader={this.props.loader} /> }} />
                    <Route exact path='/user_view_ng' render={() => { return <UserView loader={this.props.loader} /> }} />
                    <Route exact path='/user_filtered_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="user" /> }} />
                    <Route exact path='/user_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="users" /> }} />
                    <Route exact path='/user_change_log_view_ng' render={() => { return <ChangeLogView loader={this.props.loader} dataType="user" /> }} />

                    <Route exact path='/user_edit_ng' render={() => { return <EditUser loader={this.props.loader} /> }} />

                    <Route exact path='/sites_ng' render={() => { return <SiteList loader={this.props.loader} /> }} />
                    <Route exact path='/site_add_ng' render={() => { return <AddSites loader={this.props.loader} /> }} />
                    <Route exact path='/site_view_ng' render={() => { return <SiteView loader={this.props.loader} /> }} />
                    <Route exact path='/site_filtered_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="site" /> }} />
                    <Route exact path='/site_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="sites" /> }} />
                    <Route exact path='/site_change_log_view_ng' render={() => { return <ChangeLogView loader={this.props.loader} dataType="site" /> }} />

                    <Route exact path='/site_edit_ng' render={() => { return <SiteEdit loader={this.props.loader} /> }} />

                    <Route exact path='/roles_ng' render={() => { return <RoleList loader={this.props.loader} /> }} />
                    <Route exact path='/role_add_ng' render={() => { return <RoleAdd loader={this.props.loader} /> }} />
                    <Route exact path='/role_view_ng' render={() => { return <RoleView loader={this.props.loader} /> }} />
                    <Route exact path='/role_edit_ng' render={() => { return <RoleEdit loader={this.props.loader} /> }} />
                    <Route exact path='/role_change_log_view_ng' render={() => { return <ChangeLogView loader={this.props.loader} dataType="role" /> }} />
                    <Route exact path='/role_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="roles" /> }} />
                    <Route exact path='/role_filtered_change_log_ng' render={() => { return <ChangeLog loader={this.props.loader} dataType="role" /> }} />




                    {/* <Route exact path='/company_entities_ng' render={() => { return <EntityList loader={this.props.loader}/> }} />
                    <Route exact path='/company_add_ng' render={() => { return <EntityAdd loader={this.props.loader}/> }} />
                    <Route exact path='/company_view_ng' render={() => { return <EntityView loader={this.props.loader}/> }} />
                    <Route exact path='/company_edit_ng' render={() => { return <EntityEdit loader={this.props.loader}/> }} /> */}

                    {/* <Route exact path='/job_titles_ng' render={() => { return <JobTitleList loader={this.props.loader}/> }} />
                    <Route exact path='/jobtitle_add_ng' render={() => { return <JobTitleAdd loader={this.props.loader}/> }} />
                    <Route exact path='/jobtitle_view_ng' render={() => { return <JobTitlesView loader={this.props.loader}/> }} />
                    <Route exact path='/jobtitle_edit_ng' render={() => { return <JobTitleEdit loader={this.props.loader}/> }} /> */}

                    {/* // NG-19840 (REMOVE - APPLICATION DETAILS MENU) */}
                    {/* <Route exact path="/application_detail_ng" render={() => { return <ApplicationDetail loader={this.props.loader} /> }} />
                    <Route exact path='/application_detail_add_ng' render={() => { return <ApplicationDetailAddEdit loader={this.props.loader} /> }} />
                    <Route exact path='/application_detail_edit_ng' render={() => { return <ApplicationDetailAddEdit loader={this.props.loader} /> }} /> */}


                    {/* <Route exact path='/application_detail_add_ng' render={() => { return <ApplicationDetailAdd loader={this.props.loader} /> }} />
                    <Route exact path='/application_detail_view_ng' render={() => { return <ApplicationDetailView loader={this.props.loader} /> }} />
                    <Route exact path='/application_detail_edit_ng' render={() => { return <ApplicationDetailEdit loader={this.props.loader} /> }} /> */}
                </Switch>
            </Provider>
        );
    }
}
export default UserMgmtMain;

