export const setRowIDRole = (value) => {
    return{
        type: "SET_ROWID_ROLES",
        payload: value
    }    
};

export const setResponseDataRole = (value) => {
    return{
        type: "SET_RESPONSE_DATA_ROLES",
        payload: value
    }    
};


export const setRoleID = (value) => {
    return{
        type: "SET_ROLEID",
        payload: value
    }    
};

export const setUserSiteOrDept  = (value) => {
    return{ 
        type: "SET_USER_SITE_OR_DEPT",
        payload: value,
    }    
};

