import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import * as RolesAction from '../actions/rolesaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_Authorization_v1, OpenApiClient_choice_values, OpenApiBody, MimeTypes, getSiteName, OpenApiRemoteAuth } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiLineEdit, LiBreadCrumbs, LiSwitch, LiComboBox, LiComboList, LiLabel, LiStatusManager, LiStatusContainer, LiReactDialog, LiGridList } from 'liseccomponents';
import { LiTable } from 'lisecdependantcomp';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';
import ChangeLog from './changelog';
import LiMultiLevelList from './multilevellist';

const baseUrl = window.location;

var saveRecCount = 0;
var saveRecCount1 = 0;

const dataVal = {
    "interfaceInfo": {
        "type": "MT_MASTER_DATA",
        "version": 1,
        "itemType": "ITEM_TYPE_MASTER_ROLE"
    },
}

const userTableConfiguration1 = {
    "getRowDataConfiguration": {
        "enableRowData": false,
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") !== null ? conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") : 20,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": { "userId": ["99001692", "ID #"], "firstName": ["99024423", "First Name"], "lastName": ["99024422", "Last Name"] },                      //Only Column Header Name
        "defaultSortingColumn": "ID",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["code", "full_name", "extn", "company_name", "email", "dept", "status"],           //Only Row Key 
        "searchOptionColumns": { "status": { "option1": "Online", "option2": "Offline" }, "dept": { "option1": "Accounting", "option2": "Machinery", "option3": "Software", "option4": "IT" } }
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ["openView"],
            "Edit": ["openEdit"],
            "Delete": ["openDelete"]
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "userId": "number",
        "firstName": "text",
        "lastName": "text"
    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false
};

const userTableConfiguration2 = {
    "getRowDataConfiguration": {
        "enableRowData": false,
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") !== null ? conversionLib.getLocalStorageValue("table.pagesize.user.assigned.list") : 20,
        "pageSizeCombo": [5, 10, 15, 20, 25, 50, 100, 200]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": { "azureAdGroupName": ["99031535", "Azure Ad Group Name"], "azureAdGroupId": ["99031536", "Azure Ad Group Id"] },                      //Only Column Header Name
        "defaultSortingColumn": "ID",
        "defaultSortingOrder": "ASC"
    },
    "searchConfiguration": {
        "enableSearching": false,
        "searchColumns": ["azureAdGroupName", "azureAdGroupId"],           //Only Row Key 
        "searchOptionColumns": {}
    },
    "actionConfiguration": {
        "showAction": false,
        "actionItems": {
            "View": ["openView"],
            "Edit": ["openEdit"],
            "Delete": ["openDelete"]
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "azureAdGroupName": "text",
        "azureAdGroupId": "text"
    },
    "multiSelectable": {
        "enabled": true,
        "showCheckboxes": true
    },
    "enableTrigger": false
};

const tableHeader = {
    azureAdGroupName: ["99031535", "Azure Ad Group Name"],
    azureAdGroupId: ["99031536", "Azure Ad Group Id"],
};

class RoleEdit extends Component {

    constructor() {
        super();
        this.state = {
            currentRoleDataIndividual: {},
            roleEditUpdatedisabledRE: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            siteId: '',
            dataListRE: [],
            columnListRE: [{ desc: " ", checked: false }, { desc: "Fat Client", checked: false }, { desc: "Web", checked: false }, { desc: "Mobile", checked: false }, { desc: "External", checked: false }],
            curGridDataRE: [],
            gridValToDBRE: [],
            authorizationTreeArr: [],
            eachAuthRole: [],
            user_role_view: false,
            user_role_delete: false,
            userRoleList: [],
            currentActionRE: '',
            enableRouteRE: {},
            deletUserId: '',
            userTypeId: '',
            comboChoice: {},
            redirectPathRE: '',

            showInfoDialogRE: false,
            infoMessage: "",

            addRoleGroupDialog: false,
            tableData: null,
            filterValue: "",
            selectedRowData: [],
            addedRolesAdGroups: [],
            selectedAuthIdArr: [],
            authIdArr: [],

            masterRoleAdGroupChangeLogDialog: false,
            masterRoleAuthAssignmentChangeLogDialog: false
        };

        this.getDataFromTable = this.getDataFromTable.bind(this);
        this.handleDeleteData = this.handleDeleteData.bind(this);
        this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
        this.handleDialogCloseRoleEdit = this.handleDialogCloseRoleEdit.bind(this);

        this.dataObjBreadCrumbsRE = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Roles": {
                "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                "path": "/roles_ng"
            },
            "Edit roles": {
                "label": <LiMultiterm textId="99029983" textVal="Edit roles" />,
                "path": "#"
            }
        }
    }

    callbackWithArgForEntityComboRoleEdit(responseDataEntityComboRE) {

        this.props.loader.showLoader({ "role_edit": false });


        if (responseDataEntityComboRE.state.response !== undefined) {
            var jsonDataEntityComboRE = conversionLib.convertToJSON(responseDataEntityComboRE.state.response.data);

            var tempAllSiteObj1 = {};
            if (jsonDataEntityComboRE !== undefined && Object.keys(jsonDataEntityComboRE).length !== 0) {
                var tempArrEntityComboRE = {};
                Object.keys(jsonDataEntityComboRE.companyEntities).forEach(function (k, item) {
                    var tempAllSiteObj = {};
                    tempAllSiteObj['desc'] = jsonDataEntityComboRE.companyEntities[k].entityDescField;
                    tempAllSiteObj['shdesc'] = jsonDataEntityComboRE.companyEntities[k].entityShdescField
                    tempArrEntityComboRE[jsonDataEntityComboRE.companyEntities[k].entityIdField] = jsonDataEntityComboRE.companyEntities[k].entityDescField
                    tempAllSiteObj1[jsonDataEntityComboRE.companyEntities[k].entityIdField] = tempAllSiteObj;
                })
                this.props.setUserSiteOrDept(tempArrEntityComboRE);
                this.setState({ fullSiteDetail: tempAllSiteObj1 });
            }
        }
        else {
            console.log("RESPONSE : " + responseDataEntityComboRE.state.response);
        }

        let siteName = getSiteName();

        var roleIDRE = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : 2;

        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAuthAssgnmts_roleId(this.callbackGetRoleAuthForUsrMgmt.bind(this), parseInt(roleIDRE));
    }

    callbackGetRoleRoleEdit(responseDataGetRoleRE) {
        if (responseDataGetRoleRE.state.response.status === 200) {
            var jsonDataGetRoleRE = conversionLib.convertToJSON(responseDataGetRoleRE.state.response.data);
            if (jsonDataGetRoleRE !== undefined && Object.keys(jsonDataGetRoleRE).length !== 0) {
                this.setState({
                    currentRoleDataIndividual: jsonDataGetRoleRE,
                    siteId: jsonDataGetRoleRE.entityIdField,
                    userTypeId: jsonDataGetRoleRE.userTypeField,
                    modifiedField: jsonDataGetRoleRE.modifiedField
                });
            }
            if (jsonDataGetRoleRE.masterRoleAuthAssgnmtSubdata && jsonDataGetRoleRE.masterRoleAuthAssgnmtSubdata.length > 0) {
                this.handleROleAuthTreeSelection(jsonDataGetRoleRE.masterRoleAuthAssgnmtSubdata);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataGetRoleRE.state.response.status, responseDataGetRoleRE.state.response.message);
        }

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackWithArgForEntityComboRoleEdit.bind(this));
    }


    handleROleAuthTreeSelection(masterRoleAuthAssgnmtSubdata) {
        let authIdArr = [];
        masterRoleAuthAssgnmtSubdata.forEach(ele => authIdArr.push(ele.authIdField))
        this.setState({ authIdArr, selectedAuthIdArr: authIdArr })

    }


    callbackWithArgForUpdateRolesData(responseData) {
        this.props.loader.showLoader({ "role_edit": false });

        if (responseData.state.response.status === 200) {
            this.setState({ redirectPathRE: "roles_ng" });
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.enableSaveButtonRoleEdit.bind(this));
        }
    }

    enableSaveButtonRoleEdit() {
        this.setState({ roleEditUpdatedisabledRE: false });
    }

    callbackGetRoleAuthForUsrMgmt(responseData) {
        var roleIDRE = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : 2;

        if (responseData.state.response.status === 200) {
            if (this.state.siteId !== '') {
                var selectedSiteName = this.state.fullSiteDetail[this.state.siteId] !== undefined ? this.state.fullSiteDetail[this.state.siteId].shdesc : 'PROD';
                this.getavailRoles(selectedSiteName, parseInt(this.state.siteId));
            }

            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({ eachAuthRole: jsonData })
            }

            let siteName = getSiteName();
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_roleId_users(this.callbackGetRolesRoleidUsers.bind(this), roleIDRE);
        }
        else {
            // LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
            if (this.state.siteId !== '') {
                var selectedSiteName = this.state.fullSiteDetail[this.state.siteId] !== undefined ? this.state.fullSiteDetail[this.state.siteId].shdesc : 'PROD';
                if (responseData.state.response.status === 204) {
                this.getavailRoles(selectedSiteName, parseInt(this.state.siteId) );
                }
                else 
                this.getavailRoles(selectedSiteName);
            }
        }
    }

    callbackGetRolesRoleidUsers(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            // console.log(jsonData,"jsonDatajsonDatajsonDatajsonDatajsonData");
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({ userRoleList: usrLib.convertToObject(jsonData, "users") })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }


    componentDidMount() {
        var roleIDRE = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : 2;

        this.props.loader.showLoader({ "role_edit": true });
        // console.log(roleIDRE);
        let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2108']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_roleId(this.callbackGetRoleRoleEdit.bind(this), roleIDRE);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups(this.callbackRolesAdGroupRoleEdit.bind(this), roleIDRE);
    }

    callbackRolesAdGroupRoleEdit(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                addedRolesAdGroups: jsonData
            })
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    handleDeleteDialogClose() {
        this.setState({
            user_role_delete: false
        });
    }

    handleChangeRoleEdit(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : target.value;
        const name = event.target.name;
        const roleObj = this.state.currentRoleDataIndividual;
        roleObj[name] = value;
        this.setState({
            currentRoleDataIndividual: roleObj
        });
    }

    editRoleHandleSubmitData() {
        var roleIdField = parseInt(this.state.currentRoleDataIndividual.roleIdField);
        var roleDescField = document.getElementById('roleDescField').value;
        var roleShdescField = document.getElementById('roleShdescField').value;
        var disabledField = (document.getElementById('disabledField').checked === true ? 1 : 0);
        var scopeLevelField = 0;
        var entityIdField = this.state.siteId;
        var userTypeField = this.state.userTypeId;

        if (roleDescField.trim() === "" || roleShdescField.trim() === "" || entityIdField === "" || userTypeField === undefined) {
            this.setState({ showInfoDialogRE: true, infoMessage: "Fill all the fields" });
            return;
        }

        dataVal.roleIdField = roleIdField;
        dataVal.roleDescField = roleDescField
        dataVal.roleShdescField = roleShdescField;
        dataVal.disabledField = disabledField;
        dataVal.scopeLevelField = scopeLevelField;
        dataVal.entityIdField = entityIdField;
        dataVal.userTypeField = userTypeField;
        dataVal.modifiedField = this.state.modifiedField;
        dataVal.authorizations = this.state.selectedAuthIdArr

        this.setState({ roleEditUpdatedisabledRE: true });

        this.props.loader.showLoader({ "role_edit": true });

        let siteName = getSiteName();

        OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Roles(this.callbackWithArgForUpdateRolesData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, dataVal));
    }

    enitityMenuItemsRoleEdit() {
        return Object.keys(this.props.rolestore.siteDeptArr).map((name) => (
            <LiComboList key={name} value={parseInt(name)} > {this.props.rolestore.siteDeptArr[name]} </LiComboList>
        ));
    }

    // callbackGetAuthForUsrMgmt(responseData) {
    //     if (String(responseData.state.response).indexOf('403') > -1) {
    //         this.setState({ dataListRE: [] });
    //         this.props.loader.showLoader({ "role_edit": false });

    //         this.setState({ showInfoDialogRE: true, infoMessage: "Invalid License" });
    //         return false;
    //     }

    //     if (responseData.state.response !== undefined) {
    //         let siteName = getSiteName();
    //         OpenApiClient_Authorization_v1.getClient(siteName).GET_Authorizations(this.callbackGetForGroupedIdRoleEdit.bind(this));
    //     }
    // }

    callBackHandlGetLanguageLangID3TextRoleEdit(responseData) {
        if (responseData.state.response !== undefined && responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            var tempArr = {};
            Object.keys(jsonData).forEach(function (k, item) {
                tempArr[jsonData[k].number] = jsonData[k].text
            })
            this.setState({ multilangDataVal: tempArr })
            this.props.loader.showLoader({ "qp_multilang": false });
        }
        else {
            this.props.loader.showLoader({ "qp_multilang": false });
            console.log("RESPONSE : " + responseData.state.response);
        }
    }

    async callbackGetForGroupedIdRoleEdit(responseDataGroupedRE) {
        this.props.loader.showLoader({ "role_edit": false });
        if (responseDataGroupedRE.state.response.status === 200) {

            var resRE = conversionLib.convertToJSON((responseDataGroupedRE.state.response.data))    //.slice(0, 4);

            let updatedMuTreeData = this.filterMultiLevelListData(resRE)
            let preSelectedTreeData = this.updateTreeWithSelection(updatedMuTreeData)

            this.setState({ authorizationTreeArr: preSelectedTreeData })
        }
        else {
            this.setState({ dataListRE: [] , authorizationTreeArr: []});
            if (responseDataGroupedRE.state.response.status !== 204){
                this.setState({ showInfoDialogRE: true, infoMessage: "No data for this site" });
            }
            // console.log('ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        }
    }

    filterMultiLevelListData(treeData) {
        let updatedTreeArr = treeData.map((eachEle, idx) => {
            const { authId: id, authDesc: description, authorizationTrees: sublevel, descId: muTextId } = eachEle;

            if (sublevel && sublevel.length !== 0) {
                return { id, description, sublevel: this.filterMultiLevelListData(sublevel), muTextId }
            }
            else
                return { id, description, sublevel, muTextId }

        });
        return updatedTreeArr;
    }

    updateTreeWithSelection(treeData) {

        treeData.map((eachEle, idx) => {
            eachEle.checked = this.state.authIdArr.includes(eachEle.id);
            eachEle.sublevel.length > 0 && this.updateTreeWithSelection(eachEle.sublevel)

        });
        return treeData;
    }

    fillHeaderDataRoleEdit(res, list) {
        res.forEach((parent, mainIdx) => {
            var value = parent.description;
            var isAllRowsChecked = true;
            var child = {};
            var columnIdx = 0;
            ({ isAllRowsChecked, columnIdx } = this.fillCellDataRoleEdit(child, mainIdx, value, parent, isAllRowsChecked, columnIdx));
            list.push(child);
        });

    }

    fillCellDataRoleEdit(child, mainIdx, value, parent, isAllRowsChecked, columnIdx) {
        child["id"] = mainIdx;
        child["header"] = value;
        child["key"] = mainIdx;
        child["innerIdx"] = mainIdx;
        child["multiterm"] = parent.multitermId;
        child["multitermtext"] = this.state.multilangDataVal[String(parent.multitermId)] !== undefined ? this.state.multilangDataVal[String(parent.multitermId)] : value;
        ({ isAllRowsChecked, columnIdx } = this.fillAuthRefRoleEdit(parent, isAllRowsChecked, mainIdx, columnIdx, child));
        return { isAllRowsChecked, columnIdx };
    }

    fillAuthRefRoleEdit(parent, isAllRowsChecked, mainIdx, columnIdx, child) {
        if (parent.authDefs !== undefined) {
            var childlist = [];
            parent.authDefs.forEach((child, idx) => {
                var eeachAuth = {};
                this.state.eachAuthRole.forEach((eachAuth, eachIndx) => {
                    if (child.id === eachAuth.authIdField) {
                        eeachAuth = eachAuth;
                    }
                })

                var childObj = {};
                isAllRowsChecked = this.fillRowDataRoleEdit(childObj, mainIdx, idx, parent, child, isAllRowsChecked, eeachAuth);
                childlist.push(childObj);
                columnIdx = columnIdx + 1;
            });

            child["rows"] = childlist;
            child["checked"] = this.checkForAllRowsCheckedWithDisabledRoleEdit(childlist);
        }
        return { isAllRowsChecked, columnIdx };
    }

    checkForAllRowsCheckedWithDisabledRoleEdit(rowList) {
        var isAllEnabledChildChecked = true;
        rowList.forEach((child, idx) => {
            var flag = this.checkForAllColumnCheckedWithDisabledRoleEdit(child);
            if (!flag) {
                isAllEnabledChildChecked = false
            }
        })

        if (isAllEnabledChildChecked)
            rowList["checked"] = isAllEnabledChildChecked

        return isAllEnabledChildChecked;
    }

    checkForAllColumnCheckedWithDisabledRoleEdit(list) {
        var totalColumn = this.state.columnListRE.length - 1;
        var checkCount = 0;
        var disabledCount = 0;
        var isAllEnabledChildChecked = false;

        list.list.forEach((child, idx) => {
            if (child.checked) {
                checkCount = checkCount + 1;
            }
            if (child.isDisabled) {
                disabledCount = disabledCount + 1;
            }
        })
        if (checkCount === (totalColumn - disabledCount))
            isAllEnabledChildChecked = true;

        list["checked"] = isAllEnabledChildChecked;
        return isAllEnabledChildChecked;
    }

    fillRowDataRoleEdit(childObj, mainIdx, idx, parent, child, isAllRowsChecked, eeachAuth) {
        childObj["list"] = this.fillTableDataRoleEdit(mainIdx, idx, parent, child, eeachAuth).list;
        childObj["checked"] = this.fillTableDataRoleEdit(mainIdx, idx, parent, child, eeachAuth).isAllColumnChecked;
        childObj["innerIdx"] = mainIdx + "_" + idx;
        childObj["id"] = child.id;
        childObj["name"] = child.name;
        childObj["desc"] = child.desc;
        childObj["multiterm"] = child.multitermId;
        childObj["multitermtext"] = this.state.multilangDataVal[String(child.multitermId)] !== undefined ? this.state.multilangDataVal[String(child.multitermId)] : child.desc;
        childObj["isDisabled"] = false;
        if (!childObj["checked"])
            isAllRowsChecked = false;
        return isAllRowsChecked;
    }

    fillTableDataRoleEdit(mainIdx, columnIdx, parent, child, eeachAuth) {
        var list = [];
        var dataIdx = 1;
        var isAllColumnChecked = false;
        ({ dataIdx, isAllColumnChecked } = this.createInnerRowDataRoleEdit(mainIdx, columnIdx, dataIdx, child, isAllColumnChecked, list, eeachAuth));
        return { list: list, isAllColumnChecked: isAllColumnChecked };
    }

    createInnerRowDataRoleEdit(mainIdx, columnIdx, dataIdx, child, isAllColumnChecked, list, eeachAuth) {
        this.state.columnListRE.forEach((val, colIdx) => {
            var isChecked = false;
            if (val.desc.replace(/\s/g, '').length === 0)
                return false;
            var childObj = { innerIdx: mainIdx + "_" + columnIdx + "_" + colIdx, checked: false, key: dataIdx, isDisabled: false };

            isChecked = this.isDataProductTypeRoleEdit(child, dataIdx, childObj, isChecked, eeachAuth);
            if (!isChecked) {
                childObj.isDisabled = true;
                isAllColumnChecked = false;
            }
            dataIdx = dataIdx + 1;
            list.push(childObj);
        });
        return { dataIdx, isAllColumnChecked };
    }

    isDataProductTypeRoleEdit(child, dataIdx, childObj, isChecked, eeachAuth) {
        // child.productTypes.forEach((product, idx) => {
        var product = 2;
        if (product === dataIdx) {
            if (Object.keys(eeachAuth).length > 0) {
                if (product === 1 && eeachAuth.fatClientField) {
                    childObj.checked = true;
                }
                if (product === 2 && eeachAuth.webField) {
                    childObj.checked = true;
                }
                if (product === 3 && eeachAuth.mobileField) {
                    childObj.checked = true;
                }
                if (product === 4 && eeachAuth.thirdPartyField) {
                    childObj.checked = true;
                }
            }
            else {
                childObj.checked = false;
            }

            isChecked = true;
        }
        // });
        return isChecked;
    }

    // callBackGetISO(selectedSiteName) {
    //     OpenApiClient_ng_user_management_v1.getClient(selectedSiteName).GET_Authorizations(this.callbackGetAuthForUsrMgmt.bind(this));
    // }

    handleChangeEntityComboRoleEdit = (value, name, event) => {
        var selectedSiteName = "";
        if (this.state.fullSiteDetail !== undefined) {
            selectedSiteName = this.state.fullSiteDetail[value[0]].shdesc;
        }

        if (value[0] !== undefined) {
            this.setState({ siteId: parseInt(value[0]) })
        }
        else {
            this.setState({ siteId: parseInt(name) })
        }

        this.setState({
            selectedSiteName: selectedSiteName
        });

        this.props.loader.showLoader({ "role_edit": true });

        this.getavailRoles(selectedSiteName, parseInt(value[0]))
    }

    getavailRoles(selectedSiteName, productionSiteId) {
        let siteName = getSiteName();
        // OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Authorizations(this.callbackGetAuthForUsrMgmt.bind(this));
        OpenApiClient_Authorization_v1.getClient(siteName).GET_Authorizations_productionSiteId(this.callbackGetForGroupedIdRoleEdit.bind(this), productionSiteId);

    }

    gridListChangeRoleEdit(valueChangeRE) {
        this.setState({ curGridDataRE: valueChangeRE })

        if (valueChangeRE.length > 0) {
            var tempObjIdChangeRE = {};

            valueChangeRE.forEach((eachRowChangeRE) => {

                eachRowChangeRE.rows.forEach((eachRowChangeRE1) => {

                    var tempObjIndividual = {};
                    eachRowChangeRE1.list.forEach((eachRowChangeRE2) => {
                        if (eachRowChangeRE2.key === 1 && eachRowChangeRE2.checked === true) {
                            tempObjIndividual['fatClientField'] = 1;
                        }
                        else if (eachRowChangeRE2.key === 2 && eachRowChangeRE2.checked === true) {
                            tempObjIndividual['webField'] = 1;
                        }
                        else if (eachRowChangeRE2.key === 3 && eachRowChangeRE2.checked === true) {
                            tempObjIndividual['mobileField'] = 1;
                        }
                        else if (eachRowChangeRE2.key === 4 && eachRowChangeRE2.checked === true) {
                            tempObjIndividual['thirdPartyField'] = 1;
                        }
                    })
                    tempObjIdChangeRE[eachRowChangeRE1.id] = tempObjIndividual;
                })
            })
        }
        // console.log("Temppp", tempObjIdChangeRE)
        this.setState({ gridValToDBRE: tempObjIdChangeRE });
    }

    handleUserRoleViewClose() {
        this.setState({
            user_role_view: false
        });
    }

    handleAssignedUsers() {
        this.setState({
            user_role_view: true
        });
    }

    handleData(selectedRows, rowID, rowData) {
    }

    getDataFromTable = function (rowID, userRecObj, actionName) {
        if (actionName === "View") {
            this.setState({ currentActionRE: 'view' })
            let enableRouteRE = this.state.enableRouteRE;
            enableRouteRE['view'] = { status: true, link: '/user_view_ng?userid=' + userRecObj[rowID].value.userId }
            this.setState({ enableRouteRE });
        }

        if (actionName === "Edit") {
            this.setState({ currentActionRE: 'edit' })
            let enableRouteRE = this.state.enableRouteRE;
            enableRouteRE['edit'] = { status: true, link: '/user_edit_ng?userid=' + userRecObj[rowID].value.userId }
            this.setState({ enableRouteRE });
        }

        if (actionName === "Delete") {
            this.setState({ user_role_delete: true, deletUserId: userRecObj[rowID].value.userId });
        }
    }

    callbackHandleDeleteData(responseData) {
        var roleIDRE = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : 2;
        let siteName = getSiteName();

        this.props.loader.showLoader({ "role_edit": false });

        if (responseData.state.response !== undefined) {
            if (responseData.state.response.status === 200) {
                this.setState({ user_role_delete: false });
                OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_roleId_users(this.callbackGetRolesRoleidUsers.bind(this), roleIDRE);
            }
        }
        else {
            console.log("RESPONSE : " + responseData.state.response);
        }
    }

    handleDeleteData() {
        this.setState({ list_delete_confirm_yes_disabled: true });
        this.props.loader.showLoader({ "role_edit": true });

        const key = "usermgmt.userrolelist.handledeletedata";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_Users_userId(this.callbackHandleDeleteData.bind(this), this.state.deletUserId, true, key);
    }

    handleChangeUserTypeComboRoleEdit = (value, name, event) => {
        this.setState({
            userTypeId: parseInt(value[0])
        })
    }

    // userTypeMenuItems(values) {
    //     return Object.keys(this.state.comboChoice).map((name) => (
    //         <LiComboList key={name} value = {parseInt(name)} className="LiCombo_selected_dark" >{<LiMultiterm textId={String(this.state.comboChoice[name][1])} textVal={this.state.comboChoice[name][0]} />}</LiComboList>
    //     ));
    // }

    handleCloseResponseRoleEdit() {
        this.setState({ showInfoDialogRE: false });
    }

    handleAddRoleGroupDialog() {
        this.setState({
            addRoleGroupDialog: true
        })
    }

    handleMasterRoleAdGroupChangeLogDialog() {
        this.setState({
            masterRoleAdGroupChangeLogDialog: true
        })
    }

    handleMasterRoleAuthAssignmentChangeLogDialog() {
        this.setState({
            masterRoleAuthAssignmentChangeLogDialog: true
        })
    }

    setText = (evt) => {
        this.setState({
            filterValue: evt.target.value
        })
    }

    handleAddRoleGroup() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const roleId = urlParams.get('roleid')
        let siteName = getSiteName();
        const key = "usermgmt.userlist.postusers";
        window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };
        if (this.state.selectedRowData.length > 0) {
            // console.log("CALL WENT");
            this.props.loader.showLoader({ "POST_Roles": true });
            this.state.selectedRowData.forEach(data => {
                // var newuserAddDataVal = JSON.parse(JSON.stringify(userAddDataVal))
                var tempObj = {
                    // "firstNameField": data.value.firstNameField,
                    // "lastNameField": data.value.lastNameField,
                    // "loginNameField": data.value.loginNameField,
                    // "emailField": data.value.emailField,
                    // "userTypeField": 0,
                    // "activeDirectoryUser": true
                    azureAdGroupId: data.value.azureAdGroupId,
                    azureAdGroupName: data.value.azureAdGroupName,
                    id: data.value.id,
                    roleId: roleId
                }

                // console.log(data, "ROLLEESS");
                // newuserAddDataVal.userData = { ...newuserAddDataVal.userData, ...tempObj }
                OpenApiClient_ng_user_management_v1.getClient(siteName).POST_RoleAdGroups(this.callBackHandleUserAddData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, tempObj));
            })
        }
    }

    callBackHandleUserAddData(responseData) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const roleId = urlParams.get('roleid')
        let siteName = getSiteName();
        this.props.loader.showLoader({ "POST_Roles": false });
        // console.log("USER ADDED");
        if (responseData.state.response !== undefined) {
            if (responseData.state.response.data !== undefined) {
                if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
                    this.setState({
                        addRoleGroupDialog: false,
                        tableData: null,
                        filterValue: ""
                    })
                    OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups(this.callbackRolesAdGroupRoleEdit.bind(this), roleId);
                }
            }
        }
        else {
            console.log("RESPONSE : " + responseData.state.response);
        }
        this.setState({
            addRoleGroupDialog: false
        })
    }

    getRoleGroup() {
        this.props.loader.showLoader({ "Get_Role_Group": true });
        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups_azureADQuery(this.callbackGetRoleGroup.bind(this), this.state.filterValue)
    }

    callbackGetRoleGroup(responseData) {
        this.props.loader.showLoader({ "Get_Role_Group": false });
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                tableData: jsonData.azureADGroups
            })
            // console.log(jsonData);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    handleData1(selectedRows, rowID, rowData) {
        this.setState({ selectedRowData: selectedRows })
    }

    linkData(rowData) {
        this.setState({ currentActionRE: 'edit' });

        var enableRouteRE = this.state.enableRouteRE;
        enableRouteRE['edit'] = { status: true, link: '/role_edit_ng?roleid=' + rowData.value.toString() }
        this.setState({ enableRouteRE });
    }

    handleDialogCloseRoleEdit() {
        if (this.state.addRoleGroupDialog) {
            this.setState({
                addRoleGroupDialog: false,
                tableData: null,
                filterValue: ""
            })
        }
    }

    deleteRolesAdGroup(data) {
        let siteName = getSiteName();
        this.props.loader.showLoader({ "Delete_Role_Group": true });
        OpenApiClient_ng_user_management_v1.getClient(siteName).DELETE_RoleAdGroups_roleId_azureAdGroupId(this.callbackDeleteRolesAdGroup.bind(this), data.roleId, data.azureAdGroupId)
    }

    callbackDeleteRolesAdGroup(responseData) {
        let siteName = getSiteName();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const roleId = urlParams.get('roleid')
        this.props.loader.showLoader({ "Delete_Role_Group": false });

        if (responseData.state.response.status === 200) {
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups(this.callbackRolesAdGroupRoleEdit.bind(this), roleId);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    closeMasterRoleAdGroupChangeLog() {
        this.setState({ masterRoleAdGroupChangeLogDialog: false })
    }

    closeMasterRoleAuthAssignmentChangeLog() {
        this.setState({ masterRoleAuthAssignmentChangeLogDialog: false })
    }

    onChangeMuLevelListRE(listdata) {
        let selectedAuthIdArr = [];
        listdata.map((eachMain, idx) => {
            const recursiveCheck = (eachMain) => {
                eachMain.checked && selectedAuthIdArr.push(eachMain.id);
                eachMain.sublevel && eachMain.sublevel.length !== 0 && eachMain.sublevel.map((eachSub, ids) => {
                    recursiveCheck(eachSub)
                })
            }
            recursiveCheck(eachMain)
        })
        selectedAuthIdArr = selectedAuthIdArr.filter((e, idx) => selectedAuthIdArr.indexOf(e) === idx)

        this.setState({
            selectedAuthIdArr: selectedAuthIdArr
        });

    }


    render() {
        return (
            <div>

                {this.state.redirectPathRE !== undefined && this.state.redirectPathRE !== '' ? <Redirect to={this.state.redirectPathRE} /> : null}

                {this.state.enableRouteRE[this.state.currentActionRE] !== undefined && this.state.enableRouteRE[this.state.currentActionRE]['status'] === true ? <Redirect to={this.state.enableRouteRE[this.state.currentActionRE]['link']} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsRE} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />
                <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 220)} containerID="LiUsrMgmtRoleEditSub1" containerScrollY={true} containerScrollX={false} >
                    <div className="outerGrid">
                        <div className="outerGridItemLeft">
                            <div className="addSiteMainGridContainer">
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99001692" textVal="ID #" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">{this.state.currentRoleDataIndividual.roleIdField}</div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLineEdit liEditID="roleDescField" liEditName="roleDescField" liEditPlaceholder="" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeRoleEdit.bind(this)} liEditOnKeyUp={this.handleChangeRoleEdit.bind(this)} liEditValue={this.state.currentRoleDataIndividual.roleDescField !== undefined ? this.state.currentRoleDataIndividual.roleDescField : ''} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short Description" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLineEdit liEditID="roleShdescField" liEditName="roleShdescField" liEditPlaceholder="" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeRoleEdit.bind(this)} liEditOnKeyUp={this.handleChangeRoleEdit.bind(this)} liEditValue={this.state.currentRoleDataIndividual.roleShdescField !== undefined ? this.state.currentRoleDataIndividual.roleShdescField : ''} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    {
                                        this.state.userTypeId !== ''
                                            ?
                                            <LiComboBox id="userTypeField" name="userTypeField" value={parseInt(this.state.userTypeId)} onSelection={this.handleChangeUserTypeComboRoleEdit.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>

                                                <LiComboList key={"0"} value={0}>{<LiMultiterm textId="99001446" textVal="Default" />}</LiComboList>
                                                <LiComboList key={"1"} value={1}>{<LiMultiterm textId="99030183" textVal="Shopfloor Operator" />}</LiComboList>

                                                {/* {this.userTypeMenuItems(this.state.comboChoice)} */}
                                            </LiComboBox>
                                            :
                                            ''
                                    }
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    {
                                        this.state.siteId !== ''
                                            ?
                                            <LiComboBox value={parseInt(this.state.siteId)} id="entityIdField" name="entityIdField" defaultText={<LiMultiterm textId="1000875" textVal="Select" />} onSelection={this.handleChangeEntityComboRoleEdit.bind(this)}>
                                                {this.enitityMenuItemsRoleEdit()}
                                            </LiComboBox>
                                            :
                                            ''
                                    }
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                                </div>
                                {
                                    this.state.currentRoleDataIndividual.disabledField !== undefined ?
                                        <div className="twoColumnMainGridContainerItem">
                                            <LiSwitch switchName="disabledField" switchId="disabledField" switchDisabled={false} switchVal="disabledField" switchOnChangeHandler={this.handleChangeRoleEdit.bind(this)} switchChecked={this.state.currentRoleDataIndividual.disabledField === 1 ? true : false} />
                                        </div>
                                        :
                                        ''
                                }
                            </div>

                            <div className='RoleEditRoleAdGroup'>
                                <LiLabel labelText={<LiMultiterm textId="99031538" textVal="AD Groups" />} />
                                {
                                    this.state.addedRolesAdGroups !== undefined ?
                                        <div className={this.state.theme + " RoleGroupADListEditBlock"}>
                                            {this.state.addedRolesAdGroups.map((data, idx) => {
                                                return <div key={idx} className="RoleGroupADList RoleGroupADListEdit">
                                                    <LiLabel labelText={data.azureAdGroupName} />
                                                    <LiButton buttonOnClickHandler={this.deleteRolesAdGroup.bind(this, data)}
                                                        buttonLabel={<LiMultiterm textId="99003381" textVal="Delete" />} />
                                                </div>
                                            })}
                                        </div>
                                        :
                                        <div></div>
                                }
                            </div>


                            <div className="twoColumnMainGridContainerItem" style={{ float: "right" }}>
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="99025943" textVal="Change log" />}
                                    buttonOnClickHandler={this.handleMasterRoleAdGroupChangeLogDialog.bind(this)}
                                />
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="99031537" textVal="Add Role Group from AD" />}
                                    buttonOnClickHandler={this.handleAddRoleGroupDialog.bind(this, 2)}
                                />
                            </div>
                        </div>

                        <div className="outerGridItemRight">
                            <div className="addSiteMainGridContainerSecond">
                                <div className="twoColumnMainGridContainerItem">
                                    {/* {this.state.dataListRE.length > 0 ?
                                        <LiGridList
                                            selectlistdata={this.state.dataListRE}
                                            selectListonChange={this.gridListChangeRoleEdit.bind(this)}
                                            selectListTitleButton={<LiMultiterm textId="99025943" textVal="Change log" />}
                                            selectListTitleButtonClick={this.handleMasterRoleAuthAssignmentChangeLogDialog.bind(this)}
                                        />
                                        : ''} */}
                                    <LiMultiLevelList
                                        id='featurelistedit'
                                        isToggleenabled={true}
                                        headerText={<LiMultiterm
                                            textId="99032454"
                                            textVal="Available features"
                                        />}
                                        listData={this.state.authorizationTreeArr}
                                        // listData={data}
                                        onChangeListData={this.onChangeMuLevelListRE.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.user_role_view
                            ?
                            <LiReactDialog
                                enableStoring="li.user.role.view"
                                title={<LiMultiterm textId="99031016" textVal="User assigned to this Role" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                modal={true}
                                hasCloseIcon={true}
                                onClose={this.handleUserRoleViewClose.bind(this)}
                                isResizable={true}
                                isDraggable={true}
                                height={650}
                                width={700}
                                buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="1001439" textVal="Close" />,
                                            onClick: () => this.handleUserRoleViewClose()
                                        }
                                    ]
                                }
                            >
                                {this.state.userRoleList ? <LiTable handleData={this.handleData.bind(this)} tableId="user.assigned.list" colHeaderArr={this.props.rolestore.userRoleListHeaderArr} config={userTableConfiguration1} rowArr={this.state.userRoleList} receiverFunction={this.getDataFromTable} subTable={true} enableColSearch={true} /> : null}
                            </LiReactDialog>
                            : ''
                    }

                    {
                        this.state.user_role_delete
                            ?
                            <LiReactDialog
                                enableStoring="li.user.role.delete"
                                title={<LiMultiterm textId="99025175" textVal="Delete User" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                modal={true}
                                onClose={this.handleDeleteDialogClose}
                                hasCloseIcon={true}
                                isResizable={true}
                                isDraggable={true}
                                height={300}
                                width={500}
                                buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="99001613" textVal="Yes" />,
                                            onClick: () => this.handleDeleteData(),
                                            className: "LiGeneral_raisedButton"
                                        },
                                        {
                                            text: <LiMultiterm textId="99000054" textVal="No" />,
                                            onClick: () => this.handleDeleteDialogClose(),
                                            className: "LiGeneral_raisedButton"
                                        }
                                    ]
                                }
                            >
                                <div>
                                    <br />
                                    <LiMultiterm textId="99031025" textVal="Do you want to delete the user ?" />
                                    <br /><br />
                                </div>
                            </LiReactDialog>
                            : ''
                    }
                </LiDivContainer>

                <LiDivContainer containerHeight="70" containerID="LiUsrMgmtRoleEditSub2" containerScrollY={false} containerScrollX={false} >
                    <hr className={this.state.theme + " LiUsrMgmtBottom"} />
                    <div className="userMgmtGridContainerFull" >
                        <div className="userMgmtGridContainerFullItem">
                            <div className='userMgmtGridContainerFullItemLeft'>
                                <LiButton
                                    buttonOnClickHandler={this.handleAssignedUsers.bind(this)}
                                    buttonLabel={<LiMultiterm textId="99030937" textVal="Assigned Users" />}
                                />
                            </div>

                            <LiButton
                                buttonOnClickHandler={this.editRoleHandleSubmitData.bind(this)}
                                buttonLabel={<LiMultiterm textId="99004199" textVal="Save Changes" />}
                                buttonDisabled={this.state.roleEditUpdatedisabledRE}
                            />
                            <Link to="/roles_ng">
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                />
                            </Link>
                        </div>
                    </div>

                    {
                        this.state.showInfoDialogRE
                            ?
                            <LiReactDialog
                                enableStoring="server.response.role.edit"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.handleCloseResponseRoleEdit()
                                    }]
                                }
                                onClose={this.handleCloseResponseRoleEdit.bind(this)}
                                isFooterEnable={false}
                            >
                                <p>{this.state.infoMessage}</p>
                            </LiReactDialog>
                            :
                            ""
                    }

                    <LiStatusContainer />
                    {
                        this.state.addRoleGroupDialog
                            ?
                            <LiReactDialog
                                enableStoring="li.user.add"
                                title={<LiMultiterm textId="99031537" textVal="Add Role Group from AD" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                modal={true}
                                onClose={this.handleDialogCloseRoleEdit}
                                hasCloseIcon={true}
                                isResizable={true}
                                isDraggable={true}
                                height={300}
                                width={500}
                                buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="" textVal="Cancel" />,
                                            onClick: () => this.handleDialogCloseRoleEdit(),
                                            // className: this.state.theme + " dialogButton"
                                            className: "LiGeneral_raisedButton"
                                        }
                                    ]
                                }
                            >
                                <div>
                                    <br />
                                    <LiLabel labelText={<LiMultiterm textId="99031542" textVal="Search Role Groups" />} />
                                    <div className="Usermanagement_AzureAD_SearchUser">
                                        <LiLineEdit liEditID="t4" liReadOnly={false} liEditValue={this.state.filterValue} liEditOnChange={this.setText} liEditDisable={false} liEditRequired={false} liEditOnKeyUp={this.setText} />
                                        <LiButton
                                            buttonLabel={<LiMultiterm textId="" textVal="Search" />}
                                            buttonOnClickHandler={this.getRoleGroup.bind(this)}
                                            buttonDisabled={this.state.filterValue.length > 0 ? false : true}
                                        />
                                    </div>
                                    {this.state.tableData ? <div className="AddUserADTableContainer">
                                        <LiButton
                                            buttonLabel={<LiMultiterm textId="99031543" textVal="Add Selected Role Groups" />}
                                            buttonOnClickHandler={this.handleAddRoleGroup.bind(this)}
                                            buttonDisabled={this.state.selectedRowData.length > 0 ? false : true}
                                        />
                                        <LiTable
                                            handleData={this.handleData1.bind(this)}
                                            tableId="userAzureTable"
                                            colHeaderArr={tableHeader}
                                            config={userTableConfiguration2}
                                            rowArr={this.state.tableData}
                                            receiverFunction={this.getDataFromTable}
                                            subTable={true}
                                            linkData={this.linkData.bind(this)}
                                            enableColSearch={true}
                                        />
                                    </div>
                                        : ""}
                                    <br /><br />
                                </div>
                            </LiReactDialog>
                            : ''
                    }

                    {
                        this.state.masterRoleAdGroupChangeLogDialog
                            ?
                            <LiReactDialog
                                enableStoring="user_management_master_role_ad_group_change_log"
                                title={<LiMultiterm textId="" textVal="Master Role AD Group Change Log" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                onClose={this.closeMasterRoleAdGroupChangeLog.bind(this)}
                                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                                width={1200} height={600} buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                                            onClick: () => this.closeMasterRoleAdGroupChangeLog(),
                                            className: "LiGeneral_raisedButton"
                                        }
                                    ]
                                }
                            >
                                <ChangeLog dataType="masterroleadgroup" loader={this.props.loader} />
                            </LiReactDialog>
                            : ''
                    }

                    {
                        this.state.masterRoleAuthAssignmentChangeLogDialog
                            ?
                            <LiReactDialog
                                enableStoring="user_management_master_role_auth_assignment_change_log"
                                title={<LiMultiterm textId="" textVal="Master Role Auth Assignment Change Log" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                onClose={this.closeMasterRoleAuthAssignmentChangeLog.bind(this)}
                                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                                width={1200} height={600} buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                                            onClick: () => this.closeMasterRoleAuthAssignmentChangeLog(),
                                            className: "LiGeneral_raisedButton"
                                        }
                                    ]
                                }
                            >
                                <ChangeLog dataType="masterroleauthassignment" loader={this.props.loader} />
                            </LiReactDialog>
                            : ''
                    }

                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolestore: state.rolestore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserSiteOrDept: RolesAction.setUserSiteOrDept,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleEdit);

