export const setResponseData = (value) => {
    return{
        type: "SET_RESPONSE_DATA_USER",
        payload: value
    }
    
};

export const setViewDialog = (value) => {
    return{
        type: "SET_VIEW_DIALOG_USER",
        payload: value
    }
    
};

export const setAddDialog = (value) => {
    return{
        type: "SET_ADD_DIALOG_USER",
        payload: value
    }
    
};

export const setEditDialog = (value) => {
    return{
        type: "SET_EDIT_DIALOG_USER",
        payload: value
    }
    
};

export const setDeleteDialog = (value) => {
    return{
        type: "SET_DELETE_DIALOG_USER",
        payload: value
    }
    
};

export const setUserID = (value) => {
    return{
        type: "SET_USERID_USER",
        payload: value
    }
    
};

export const setRowID = (value) => {
    return{
        type: "SET_ROWID_USER",
        payload: value
    }
    
};

export const setConfirmDialog = (value) => {
    return{
        type: "SET_CONFIRM_DIALOG_USER",
        payload: value
    }
    
};

export const setUserRole = (value) => {
    return{
        type: "SET_USER_ROLES",
        payload: value
    }
    
};


export const setUserJobTitles = (value) => {
    return{
        type: "SET_USER_JOB_TITLES",
        payload: value
    }
    
};


export const setUserDataAdd = (fieldID, fieldVal) => {

    return{ 
        type: "SET_USER_DATA_ADD",
        filedIDVar: fieldID,
        fieldValVar: fieldVal
    }
    
};

export const resetUserDataAdd  = () => {
    return{ 
        type: "RESET_USER_DATA_ADD"
    }
    
};


export const setUserDataAddObject  = (value) => {

    return{ 
        type: "RESET_USER_DATA_ADD_OBJECT",
        userDataObj: value,
    }
    
};

export const setAvatarData  = (value) => {
    return{ 
        type: "SET_AVATAR_DATA",
        avatadDataVal: value,
    }
    
};

export const setUserRoleDragDrop  = (value) => {
    return{ 
        type: "SET_USER_ROLES_DRAG_DROP",
        userRoles: value,
    }
    
};


export const setAvailUserRolesArr  = (value) => {
    return{ 
        type: "SET_AVAIL_USER_ROLES_ARR",
        availUser: value,
    }
    
};


export const setAssignUserRolesArr  = (value) => {
    return{ 
        type: "SET_ASSIGN_USER_ROLES_ARR",
        assignUser: value,
    }
    
};

export const setExistingAssignUserRolesArr  = (value) => {
    return{ 
        type: "SET_EXISTING_ASSIGN_USER_ROLES_ARR",
        existingAssignUser: value,
    }
    
};


export const setAllUserArr  = (value) => {
    return{ 
        type: "SET_ALL_USER_ARR",
        allUserVal: value,
    }
    
};


export const setRoleDetailObj  = (value) => {
    return{ 
        type: "SET_ROLE_DETAIL_OBJ",
        roleDeatilObj: value,
    }
    
};


export const setAllUserJobTitle  = (value) => {
    return{ 
        type: "SET_ALL_USER_JOB_TITLE",
        payload: value,
    }
    
};

export const setEachUserJobTitle  = (value) => {
    return{ 
        type: "SET_EACH_USER_JOB_TITLE",
        payload: value,
    }
    
};

export const setFullEachUserJobTitle  = (value) => {
    return{ 
        type: "SET_FULL_EACH_USER_JOB_TITLE",
        payload: value,
    }
    
};




export const setUserJobTitlesDragDrop  = (value) => {
    return{ 
        type: "SET_USER_JOB_TITLES_DRAG_DROP",
        payload: value,
    }
    
};

export const setUserSiteOrDept  = (value) => {
    return{ 
        type: "SET_USER_SITE_OR_DEPT",
        payload: value,
    }
    
};




export const setAvailUserJobTitlesArr  = (value) => {
    return{ 
        type: "SET_AVAIL_USER_JOB_TITLES_ARR",
        payload: value,
    }
    
};


export const setAssignUserJobTitlesArr  = (value) => {
    return{ 
        type: "SET_ASSIGN_USER_JOB_TITLES_ARR",
        payload: value,
    }
    
};













