import React from 'react';


import InitialLoading from './Initialloading.gif'
import Initializing from './Intializing.gif'
import './loading.css'
import '../mainframe/lilayoutcss/style.css'
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { getSiteName, checkAccessTokenIsValid } from 'lisecutilityfunctions/lib/openapi';
import {getLocalStorageValue} from 'lisecutilityfunctions/lib/commonfunction';
const siteName = getSiteName();

const LoadingComponent = (props) => {
    return (
        <div className = "LoadingContianer">
            <img src = {InitialLoading} className = "loadingImage"/>
            <span className = "dataContainer">
                <p className = "appName">{props.appName}</p>
                <span className = "inintlaizing">
                <p className = "appInitializing">{checkAccessTokenIsValid(getLocalStorageValue("config.accessToken." + siteName)) ? <LiMultiterm textId="99030680" textVal="Initializing" /> : "Initializing"}</p>
                <img src = {Initializing} className = "appInitializing"/>

                </span>
            </span>
            
        </div>
    );
};

export default LoadingComponent;