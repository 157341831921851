const initial = {
    siteRowArr: {},
    site_view_open: false,
    site_edit_open: false,
    site_delete_open: false,
    siteID:'',
    siteHeaderArr:{"entityIdField": ["99001692","ID #"],"entityDescField": ["6001104","Name"],"entityShdescField": ["99002657","Short Desc"],"disabledField": ["99023501","Disabled"], "licenseSiteIdField": ["99032736","Licence site ID"]},
    rowID:0,
    DBTypeObj: { "1": "Order", "2": "Prod", "3": "Autofab"}
}

export default function(state=initial, action){
    switch(action.type){

        case "SET_RESPONSE_DATA_SITE": state = {...state, siteRowArr: action.payload }
            break;
        
        case "SET_VIEW_DIALOG_SITE": state = {...state, site_view_open : action.payload }
            break;

        case "SET_EDIT_DIALOG_SITE": state = {...state, site_edit_open : action.payload }
            break;

        case "SET_DELETE_DIALOG_SITE": state = {...state, site_delete_open : action.payload }
            break;

        case "SET_SITEID_SITE": state = {...state, siteID : action.payload }
            break;

        case "SET_ROWID_SITE": state = {...state, rowID : action.payload }
            break;

        default:
            state = {
                ...state
            }
            break;
    }
    return state;
}

