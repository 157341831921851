import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserAction from '../actions/useraction';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_choice_values, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiBreadCrumbs, LiLabel, LiStatusManager, LiStatusContainer } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import lightNoAvatarIcon from './images/LightTheme/no_avatar.png';
import darkNoAvatarIcon from './images/DarkTheme/no_avatar.png';
import * as usrLib from './usr_lib';

const baseUrl = window.location;

const choiceObject = {
    0: ['Default', 99001446],
    1: ['Shopfloor Operator', 99030183],
    2: ['Shopfloor Workstation', 99030184]
};

class UserView extends Component {

    constructor() {
        super();

        this.state = {
            imageBinaryDataView: '',
            currentUserDataIndividual: {},
            userRolesArr: [],
            allRoleDetailObj: {},
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            userIdd: null,
            userJobTitlesArr: [],
            allJobTitlesDetailObj: {},
            comboChoice: {},
            noUserRoles:false
        };

        this.dataObjBreadCrumbsUV = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Users": {
                "label": <LiMultiterm textId="99028960" textVal="Users" />,
                "path": "/users_ng"
            },
            "View Details": {
                "label": <LiMultiterm textId="99005780" textVal="View Details" />,
                "path": "#"
            }
        };
    }

    callbackGetUserUserView(responseData) {
        this.props.loader.showLoader({ "GET_Users_userId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {

                this.setState({
                    currentUserDataIndividual: jsonData.userData,
                    imageBinaryDataView: (jsonData.avatar !== undefined ? jsonData.avatar.data : '')
                });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgFromViewUserRole(responseData) {
        this.props.loader.showLoader({ "GET_Users_userId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({
                    userRolesArr: jsonData.userRoles,
                });
            }
        }
        else if(responseData.state.response.status === 404){
            this.setState({
                noUserRoles:true
            })
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callBackForGetUserJobTitles(responseData) {
        this.props.loader.showLoader({ "GET_Users_userId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({ userJobTitlesArr: jsonData.userJobTitles });
            }
            else {
                this.setState({ userJobTitlesArr: [] });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForRoleObjUserView(responseDataRoleObjUV) {
        if (responseDataRoleObjUV.state.response.status === 200) {
            var jsonDataRoleObjUV = conversionLib.convertToJSON(responseDataRoleObjUV.state.response.data);
            if (jsonDataRoleObjUV !== undefined && Object.keys(jsonDataRoleObjUV).length !== 0) {
                var roleValObjUV = {};
                Object.keys(jsonDataRoleObjUV.roles).forEach(function (k, item) {
                    roleValObjUV[jsonDataRoleObjUV.roles[k].roleIdField] = jsonDataRoleObjUV.roles[k].roleDescField;
                })

                this.setState({ allRoleDetailObj: roleValObjUV });
            }
        }
        else {
            LiStatusManager.showStatus(responseDataRoleObjUV.state.response.status, responseDataRoleObjUV.state.response.message);
        }
    }

    callbackWithArgJobTitles(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var jobTitlesObj = {};
                Object.keys(jsonData.jobTitles).forEach(function (k, item) {
                    jobTitlesObj[jsonData.jobTitles[k].jobTitleIdField] = jsonData.jobTitles[k].jobTitleDescField;
                })
                this.setState({ allJobTitlesDetailObj: jobTitlesObj });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackForAllSiteOrDeptUserView(responseDataSiteDepUV) {
        if (responseDataSiteDepUV.state.response.status === 200) {
            var jsonDataSiteDepUV = conversionLib.convertToJSON(responseDataSiteDepUV.state.response.data);
            if (jsonDataSiteDepUV !== undefined && Object.keys(jsonDataSiteDepUV).length !== 0) {
                var tempArrSiteDepUV = {};
                Object.keys(jsonDataSiteDepUV.companyEntities).forEach(function (k, item) {
                    tempArrSiteDepUV[jsonDataSiteDepUV.companyEntities[k].entityIdField] = jsonDataSiteDepUV.companyEntities[k].entityDescField
                })
                this.props.setUserSiteOrDept(tempArrSiteDepUV);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataSiteDepUV.state.response.status, responseDataSiteDepUV.state.response.message);
        }
    }

    // callbackWithChoices = (responseData) => {
    //     if (responseData.state.response.status === 200) {
    //         var result = conversionLib.convertToJSON((responseData.state.response.data));
    //         this.setChoices(result.choice);
    //     }
    //     else {
    //         LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
    //     }
    // }

    // setChoices = (choices) => {
    //     // var combo = {};
    //     if (choices[0].choiceEntry !== undefined) {
    //         var choiceArr = {};
    //         choices[0].choiceEntry.forEach((ChoiceText, index) => {
    //             choiceArr[ChoiceText.keyInt] = [ChoiceText.displayToken[0].tokenTxtfallback,ChoiceText.displayToken[0].tokenTxtno]
    //         })
    //     }
    //     this.setState({comboChoice : choiceArr})
    // }

    componentDidMount() {
        var userId = (conversionLib.getParameterByName('userid', baseUrl.href) !== null && conversionLib.getParameterByName('userid', baseUrl.href) !== '') ? conversionLib.getParameterByName('userid', baseUrl.href) : 2;
        this.setState({ userIdd: userId });

        this.props.loader.showLoader({ "GET_Users_userId": true });

        let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2107']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId(this.callbackGetUserUserView.bind(this), userId);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId_role(this.callbackWithArgFromViewUserRole.bind(this), userId);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_userId_jobTitle(this.callBackForGetUserJobTitles.bind(this), userId);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserView.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_JobTitles(this.callbackWithArgJobTitles.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackForAllSiteOrDeptUserView.bind(this));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }

    render() {

        var self = this;
        return (

            <div>
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsUV} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 130)} containerID="user.view" containerScrollY={true} containerScrollX={true} >
                    <div className={this.state.theme + " userMgmtGridContainer"} >
                        <div className="userMgmtGridContainerItem" style={{ textAlign: 'center' }}>
                            {/* <img alt="avatar" id="userAva" src={this.state.imageBinaryDataView !== '' ? `data:image/jpeg;base64,${this.state.imageBinaryDataView}` : "lilayoutimages/"+this.state.theme+"/no_avatar.png"} /> */}

                            <img id="userAva" src={this.state.imageBinaryDataView !== '' ? `data:image/jpeg;base64,${this.state.imageBinaryDataView}` : this.state.theme === 'LightTheme' ? lightNoAvatarIcon : darkNoAvatarIcon} alt='Avatar' />
                        </div>
                        <div className="userMgmtGridContainerItem">
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99024423" textVal="First Name" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.firstNameField !== undefined ? this.state.currentUserDataIndividual.firstNameField : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99024422" textVal="Last Name" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.lastNameField !== undefined ? this.state.currentUserDataIndividual.lastNameField : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99010629" textVal="Login Name" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.loginNameField !== undefined ? this.state.currentUserDataIndividual.loginNameField : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99028908" textVal="Personnel Number" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.personalNumberField !== undefined ? this.state.currentUserDataIndividual.personalNumberField : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99028911" textVal="Date of Birth" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.birthdateField !== undefined ? this.state.currentUserDataIndividual.birthdateField : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99023790" textVal="Abbreviation" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.abbreviationField !== undefined ? this.state.currentUserDataIndividual.abbreviationField : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.entityIdField !== undefined ? this.props.usermgmt.siteDeptArr[this.state.currentUserDataIndividual.entityIdField] : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99021171" textVal="Email" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.emailField !== undefined ? this.state.currentUserDataIndividual.emailField : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99028910" textVal="OS User" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.osuserYnField !== undefined ? (this.state.currentUserDataIndividual.osuserYnField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99004030" textVal="Administrator" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.adminYnField !== undefined ? (this.state.currentUserDataIndividual.adminYnField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99028585" textVal="Locked" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.lockedYnField !== undefined ? (this.state.currentUserDataIndividual.lockedYnField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99020812" textVal="Disabled" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.disabledField !== undefined ? (this.state.currentUserDataIndividual.disabledField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99012483" textVal="Date Restriction" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.dateRestrictionField !== undefined ? (this.state.currentUserDataIndividual.dateRestrictionField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.userTypeField !== undefined ? <LiMultiterm textId={String(choiceObject[this.state.currentUserDataIndividual.userTypeField][1])} textVal={choiceObject[this.state.currentUserDataIndividual.userTypeField][0]} /> : '--'} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99031611" textVal="Password change required" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={this.state.currentUserDataIndividual.pwdChangeReqField !== undefined ? (this.state.currentUserDataIndividual.pwdChangeReqField === 1 ? 'Yes' : 'No') : '--'} />
                                </div>
                            </div>
                            {
                                this.state.currentUserDataIndividual.dateRestrictionField === 1 ?
                                    <div className="userMgmtGridContainerInner">
                                        <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                            <b><LiLabel labelText={<LiMultiterm textId="99028926" textVal="Date Restriction From" />} /></b>
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiLabel labelText={this.state.currentUserDataIndividual.dateRestrictionFromField !== undefined ? this.state.currentUserDataIndividual.dateRestrictionFromField : '--'} />
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <b><LiLabel labelText={<LiMultiterm textId="99029990" textVal="Date restriction to" />} /></b>
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiLabel labelText={this.state.currentUserDataIndividual.dateRestrictionToField !== undefined ? this.state.currentUserDataIndividual.dateRestrictionToField : '--'} />
                                        </div>
                                    </div>
                                    :
                                    ''
                            }

                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <b><LiLabel labelText={<LiMultiterm textId="99029991" textVal="User roles" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    {
                                        Object.keys(this.state.userRolesArr).map(function (k, item) {
                                            return (
                                                <div className="userMgmtspanFloatLeft">
                                                    {self.state.allRoleDetailObj[self.state.userRolesArr[k].roleIdField]}
                                                </div>
                                            )
                                        })
                                    }
                                    {this.state.noUserRoles ? <LiLabel labelText='--' />:''}
                                </div>
                                {/* <div className="userMgmtGridContainerInnerItem">
                                    <b><LiLabel labelText={<LiMultiterm textId="99029988" textVal="User Job Titles" />} /></b>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    {
                                        Object.keys(this.state.userJobTitlesArr).map(function (k, item) {
                                            return(
                                                <div className="userMgmtspanFloatLeft">
                                                    {self.state.allJobTitlesDetailObj[self.state.userJobTitlesArr[k].jobTitleIdField]}
                                                </div>
                                            )
                                        })
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <LiStatusContainer />
                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usermgmt: state.usermgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setEachUserJobTitle: UserAction.setEachUserJobTitle,
        setUserSiteOrDept: UserAction.setUserSiteOrDept

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
