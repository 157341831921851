import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import * as UserAction from '../actions/useraction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_Authorization_v1, OpenApiClient_choice_values, OpenApiBody, MimeTypes, getSiteName, OpenApiRemoteAuth } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiLineEdit, LiBreadCrumbs, LiSwitch, LiComboBox, LiComboList, LiLabel, LiStatusManager, LiStatusContainer, LiGridList, LiReactDialog } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import LiMultiLevelList from './multilevellist';


let countStr = 0;

const dataVal = {
    "interfaceInfo": {
        "type": "MT_MASTER_DATA",
        "version": 1,
        "itemType": "ITEM_TYPE_MASTER_ROLE"
    },
}

class RoleAdd extends Component {

    constructor() {
        super();

        this.state = {
            roleEditUpdatedisabledRA: false,
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            siteId: '',
            columnListRA: [{ desc: " ", checked: false }, { desc: "Fat Client", checked: false }, { desc: "Web", checked: false }, { desc: "Mobile", checked: false }, { desc: "External", checked: false }],
            curGridDataRA: [],
            gridValToDBRA: [],
            userTypeField: '',
            comboChoice: {},
            redirectPathRA: '',

            showInfoDialogRA: false,
            infoMessage: "",
            authorizationTreeArr: [],
            selectedAuthIdArr: [],
            roleDisabled: false


        };

        this.dataObjBreadCrumbsRA = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Roles": {
                "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                "path": "/roles_ng"
            },
            "Add Roles": {
                "label": <LiMultiterm textId="99029982" textVal="Add roles" />,
                "path": "#"
            }
        }
    }

    callbackWithArgForAddRolesData(responseData) {
        this.props.loader.showLoader({ "POST_Role": false });

        if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);


            // var basename = conversionLib.getAppBaseNameRoute();
            this.setState({ redirectPathRA: "roles_ng" });
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.enableSaveButtonRoleAdd.bind(this));
        }
    }

    enableSaveButtonRoleAdd() {
        this.setState({ roleEditUpdatedisabledRA: false });
    }

    callbackForAllSiteOrDeptRoleAdd(responseDataSiteDeptRA) {
        if (responseDataSiteDeptRA.state.response.status === 200) {
            var jsonDataSiteDeptRA = conversionLib.convertToJSON(responseDataSiteDeptRA.state.response.data);

            var tempAllSiteObj1 = {};
            if (jsonDataSiteDeptRA !== undefined && Object.keys(jsonDataSiteDeptRA).length !== 0) {
                var tempArrSiteDeptRA = {};
                Object.keys(jsonDataSiteDeptRA.companyEntities).forEach(function (k, item) {
                    var tempAllSiteObj = {};
                    tempAllSiteObj['desc'] = jsonDataSiteDeptRA.companyEntities[k].entityDescField;
                    tempAllSiteObj['shdesc'] = jsonDataSiteDeptRA.companyEntities[k].entityShdescField;

                    tempArrSiteDeptRA[jsonDataSiteDeptRA.companyEntities[k].entityIdField] = jsonDataSiteDeptRA.companyEntities[k].entityDescField
                    tempAllSiteObj1[jsonDataSiteDeptRA.companyEntities[k].entityIdField] = tempAllSiteObj;
                })
                this.props.setUserSiteOrDept(tempArrSiteDeptRA);
                this.setState({ fullSiteDetail: tempAllSiteObj1 });
            }
        }
        else {
            LiStatusManager.showStatus(responseDataSiteDeptRA.state.response.status, responseDataSiteDeptRA.state.response.message);
        }
    }


    callBackHandlGetLanguageLangID3TextRoleAdd(responseDataLangRA) {
        if (responseDataLangRA.state.response !== undefined && responseDataLangRA.state.response.status === 200) {
            var jsonDataLangRA = conversionLib.convertToJSON(responseDataLangRA.state.response.data);
            var tempArr = {};
            Object.keys(jsonDataLangRA).forEach(function (k, item) {
                tempArr[jsonDataLangRA[k].number] = jsonDataLangRA[k].text
            })
            this.setState({ multilangDataVal: tempArr })
            // this.props.loader.showLoader({"qp_multilang": false});
        }
        else {
            // this.props.loader.showLoader({"qp_multilang": false});
            console.log("RESPONSE : " + responseDataLangRA.state.response);
        }
    }

    async callbackGetForGroupedIdRoleAdd(responseDataRA) {
        this.props.loader.showLoader({ "GET_Authorization": false });

        if (responseDataRA.state.response.status === 200) {
            var res = conversionLib.convertToJSON((responseDataRA.state.response.data));
            let updatedMuTreeData = this.filterMultiLevelListData(res)
            this.setState({ authorizationTreeArr: updatedMuTreeData })
        }
        else {
            this.setState({ dataListRA: [], authorizationTreeArr: [] });
            this.setState({ showInfoDialogRA: true, infoMessage: "No data for this site" });
            // console.log('ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');
        }
    }

    filterMultiLevelListData(treeData) {
        let updatedTreeArr = treeData.map((eachEle, idx) => {
            const { authId: id, authDesc: description, authorizationTrees: sublevel, descId: muTextId } = eachEle;

            if (sublevel && sublevel.length !== 0) {
                return { id, description, sublevel: this.filterMultiLevelListData(sublevel), muTextId }
            }
            else
                return { id, description, sublevel, muTextId }

        });
        return updatedTreeArr;
    }




    componentDidMount() {
        let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2108']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackForAllSiteOrDeptRoleAdd.bind(this));
    }

    componentDidUpdate() {
    }

    addRoleHandleSubmitData() {
        var roleDescField = document.getElementById('addRoleDescField').value;
        var roleShdescField = document.getElementById('addRoleShdescField').value;
        var disabledField = (document.getElementById('addDisabledField').checked === true ? 1 : 0);
        var entityIdField = this.state.siteId;
        var userTypeField = this.state.userTypeField;

        if (roleDescField.trim() === "" || roleShdescField.trim() === "" || entityIdField === "" || userTypeField === -99) {
            this.setState({ showInfoDialogRA: true, infoMessage: "Fill all the fields" });
            return;
        }

        dataVal.roleDescField = roleDescField
        dataVal.roleShdescField = roleShdescField;
        dataVal.disabledField = disabledField;
        dataVal.scopeLevelField = 0;
        dataVal.entityIdField = entityIdField;
        dataVal.userTypeField = userTypeField;
        dataVal.authorizations = this.state.selectedAuthIdArr

        this.setState({ roleEditUpdatedisabledRA: true });
        this.props.loader.showLoader({ "POST_Role": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).POST_Roles(this.callbackWithArgForAddRolesData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, dataVal));
    }

    handleChangeRoleAdd(event) {
        const target = event.target;
        if (target.type === 'checkbox')
            this.setState({ roleDisabled: target.checked === true ? 1 : 0 })
    }

    enitityMenuItemsRoleAdd() {
        return Object.keys(this.props.rolestore.siteDeptArr).map((name) => (
            <LiComboList key={name} value={parseInt(name)} > {this.props.rolestore.siteDeptArr[name]} </LiComboList>
        ));
    }

    handleChangeEntityComboRoleAdd = (value, name, event) => {
        // console.log("asdasdasdasd", value, this.state.fullSiteDetail[value[0]].shdesc, this.state.fullSiteDetail)
        let productionSiteId = parseInt(value[0])
        var selectedSiteName = this.state.fullSiteDetail[value[0]].shdesc;

        this.setState({
            siteId: parseInt(value[0]),
            selectedSiteName: selectedSiteName
        })

        this.props.loader.showLoader({ "GET_Authorization": true });

        let siteName = getSiteName();


        // OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Authorizations(this.callbackGetAuthForUsrMgmt.bind(this));
        OpenApiClient_Authorization_v1.getClient(siteName).GET_Authorizations_productionSiteId(this.callbackGetForGroupedIdRoleAdd.bind(this), productionSiteId);

    }

    gridListChangeRoleAdd(valueChangeRA) {
        this.setState({ curGridDataRA: valueChangeRA })
        if (valueChangeRA.length > 0) {
            var tempObjIdChnageRA = {};
            valueChangeRA.forEach((eachRowChangeRA) => {
                eachRowChangeRA.rows.forEach((eachRowChangeRA1) => {
                    var tempObjIndividual = {};
                    eachRowChangeRA1.list.forEach((eachRowChangeRA2) => {
                        if (eachRowChangeRA2.key === 1 && eachRowChangeRA2.checked === true) {
                            tempObjIndividual['fatClientField'] = 1;
                        }
                        else if (eachRowChangeRA2.key === 2 && eachRowChangeRA2.checked === true) {
                            tempObjIndividual['webField'] = 1;
                        }
                        else if (eachRowChangeRA2.key === 3 && eachRowChangeRA2.checked === true) {
                            tempObjIndividual['mobileField'] = 1;
                        }
                        else if (eachRowChangeRA2.key === 4 && eachRowChangeRA2.checked === true) {
                            tempObjIndividual['thirdPartyField'] = 1;
                        }
                    })
                    tempObjIdChnageRA[eachRowChangeRA1.id] = tempObjIndividual;
                })
            })
        }
        this.setState({ gridValToDBRA: tempObjIdChnageRA });
    }

    handleChangeUserTypeComboRoleAdd = (value, name, event) => {
        this.setState({
            userTypeField: parseInt(value[0])
        })
    }

    // userTypeMenuItems(values) {
    //     return Object.keys(this.state.comboChoice).map((name) => (
    //         <LiComboList key={name} value = {parseInt(name)} className="LiCombo_selected_dark" >{<LiMultiterm textId={String(this.state.comboChoice[name][1])} textVal={this.state.comboChoice[name][0]} />}</LiComboList>
    //     ));
    // }

    handleCloseResponseRoleAdd() {
        this.setState({ showInfoDialogRA: false });
    }

    onChangeMuLevelListRA(listdata) {
        let selectedAuthIdArr = [];
        listdata.map((eachMain, idx) => {
            const recursiveCheck = (eachMain) => {
                eachMain.checked && selectedAuthIdArr.push(eachMain.id);
                eachMain.sublevel && eachMain.sublevel.length !== 0 && eachMain.sublevel.map((eachSub, ids) => {
                    recursiveCheck(eachSub)
                })
            }
            recursiveCheck(eachMain)
        })
        selectedAuthIdArr = selectedAuthIdArr.filter((e, idx) => selectedAuthIdArr.indexOf(e) === idx)

        this.setState({
            selectedAuthIdArr: selectedAuthIdArr
        });
    }


    render() {
        return (
            <div>
                {this.state.redirectPathRA !== undefined && this.state.redirectPathRA !== '' ? <Redirect to={this.state.redirectPathRA} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsRA} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />
                <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 220)} containerID="LiUsrMgmtRoleAddSub1" containerScrollY={true} containerScrollX={false} >

                    <div className="outerGrid">
                        <div className="outerGridItemLeft">
                            <div className="addSiteMainGridContainer">
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLineEdit liEditID="addRoleDescField" liEditName="addRoleDescField" liEditPlaceholder="" liEditStyle={{ 'width': '65%' }} liEditOnChange={this.handleChangeRoleAdd.bind(this)} liEditOnKeyUp={this.handleChangeRoleAdd.bind(this)} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short Description" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    <LiLineEdit liEditID="addRoleShdescField" liEditName="addRoleShdescField" liEditPlaceholder="" liEditStyle={{ 'width': '65%' }} liEditOnChange={this.handleChangeRoleAdd.bind(this)} liEditOnKeyUp={this.handleChangeRoleAdd.bind(this)} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} />
                                </div>
                                <div className="twoColumnMainGridContainerItem">
                                    <LiComboBox value={this.state.userTypeField} id="userTypeField" name="userTypeField" multiSelect={false} onSelection={this.handleChangeUserTypeComboRoleAdd.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>
                                        <LiComboList key={"0"} value={"0"}>{<LiMultiterm textId="99001446" textVal="Default" />}</LiComboList>
                                        <LiComboList key={"1"} value={"1"}>{<LiMultiterm textId="99030183" textVal="Shopfloor Operator" />}</LiComboList>
                                        {/* {this.userTypeMenuItems(this.state.comboChoice)}    */}
                                    </LiComboBox>
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiComboBox value={this.state.siteId} id="addEntityIdField" name="addEntityIdField" multiSelect={false} onSelection={this.handleChangeEntityComboRoleAdd.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>
                                        {this.enitityMenuItemsRoleAdd()}
                                    </LiComboBox>
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                                </div>

                                <div className="twoColumnMainGridContainerItem">
                                    <LiSwitch switchName="addDisabledField" switchId="addDisabledField" switchDisabled={false} switchVal="addDisabledField" switchOnChangeHandler={this.handleChangeRoleAdd.bind(this)} switchChecked={this.state.roleDisabled} />
                                </div>

                            </div>
                        </div>

                        <div className="outerGridItemRight">
                            <div className="addSiteMainGridContainerSecond">
                                <div className="twoColumnMainGridContainerItem">
                                    <LiMultiLevelList
                                        id='featurelistedit'
                                        isToggleenabled={true}
                                        headerText={<LiMultiterm
                                            textId="99032454"
                                            textVal="Available features"
                                        />}
                                        listData={this.state.authorizationTreeArr}
                                        // listData={data}
                                        onChangeListData={this.onChangeMuLevelListRA.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                </LiDivContainer>

                <LiDivContainer containerHeight="70" containerID="LiUsrMgmtRoleAddSub2" containerScrollY={false} containerScrollX={false} >
                    <hr className={this.state.theme + " LiUsrMgmtBottom"} />
                    <div className="userMgmtGridContainerFull">
                        <div className="userMgmtGridContainerFullItem">
                            <LiButton
                                buttonOnClickHandler={this.addRoleHandleSubmitData.bind(this)}
                                buttonLabel={<LiMultiterm textId="6001485" textVal="Save" />}
                                buttonDisabled={this.state.roleEditUpdatedisabledRA}
                            />
                            <Link to="/roles_ng">
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                />
                            </Link>
                        </div>
                    </div>

                    {
                        this.state.showInfoDialogRA
                            ?
                            <LiReactDialog
                                enableStoring="server.response.role.add"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.handleCloseResponseRoleAdd()
                                    }]
                                }
                                onClose={this.handleCloseResponseRoleAdd.bind(this)}
                                isFooterEnable={false}
                            >
                                <p>{this.state.infoMessage}</p>
                            </LiReactDialog>
                            :
                            ""
                    }
                    <LiStatusContainer />
                </LiDivContainer>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolestore: state.rolestore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserSiteOrDept: UserAction.setUserSiteOrDept,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdd);
