import React, { Component } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import { OpenApiClient_ng_user_management_v1, getSiteName, OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import * as usrLib from './usr_lib';
import { LiDivContainer, LiLabel, LiBreadCrumbs, LiStatusManager, LiStatusContainer, LiButton } from 'liseccomponents';
import { Redirect } from 'react-router-dom';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';

const baseUrl = window.location;

export default class ChangeLogView extends Component {

    constructor(props) {
        super();

        this.state = {
            currentRecData: {},
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
        };
        // this.dataTypeArr = {
        //     'AbbreviationField': { text: 'Abbreviation Field', textNumber: '' },
        //     'ActiveDirectoryUser': { text: 'Active Directory User', textNumber: '' },
        //     'AdminYnField': { text: 'Admin Yn Field', textNumber: '' },
        //     'BirthdateField': { text: 'Birthdate Field', textNumber: '' },
        //     'DateRestrictionField': { text: 'Date Restriction Field', textNumber: '' },
        //     'DisabledField': { text: 'Disabled Field', textNumber: '' },
        //     'EmailField': { text: 'Email Field', textNumber: '' },
        //     "FirstNameField": { text: 'First Name Field', textNumber: '' },
        //     'HwKeyField': { text: 'Hw Key Field', textNumber: '' },
        //     'LastNameField': { text: 'Last Name Field', textNumber: '' },
        //     'LockedYnField': { text: 'Locked Yn Field', textNumber: '' },
        //     'LoginNameField': { text: 'Login Name Field', textNumber: '' },
        //     'OsuserYnField': { text: 'Os user Yn Field', textNumber: '' },
        //     'PasswordField': { text: 'Password Field', textNumber: '' },
        //     'PersonalNumberField': { text: 'Personal Number Field', textNumber: '' },
        //     'PwAlgorithmField': { text: 'Pw Algorithm Field', textNumber: '' },
        //     'UserTypeField': { text: 'User Type Field', textNumber: '' },
        //     'HostIpField' : { text: 'Host Ip Field', textNumber: '' },
        //     'DbTypeField' : { text: 'Db Type Field ', textNumber: '' }
        // }


        switch (props.dataType) {
            case 'user':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Users": {
                        "label": <LiMultiterm textId="99028960" textVal="Users" />,
                        "path": "/users_ng"
                    },
                    "Audit Details": {
                        "label": <LiMultiterm textId="99030692" textVal="Audit Details" />,
                        "path": "#"
                    }
                };
                break;
            case 'site':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Sites": {
                        "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                        "path": "/sites_ng"
                    },
                    "Audit Details": {
                        "label": <LiMultiterm textId="99030692" textVal="Audit Details" />,
                        "path": "#"
                    }
                };
                break;
            case 'userroles':
                this.dataObjBreadCrumbsSV = null;
                break;
            case 'masterroleadgroup':
                this.dataObjBreadCrumbsSV = null;
                break;
            case 'masterroleauthassignment':
                this.dataObjBreadCrumbsSV = null;
                break;
            case 'role':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Roles": {
                        "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                        "path": "/roles_ng"
                    },
                    "Filtered change log": {
                        "label": <LiMultiterm textId="99030807" textVal="Filtered change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'roles':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Roles": {
                        "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                        "path": "/roles_ng"
                    },
                    "Change log": {
                        "label": <LiMultiterm textId="99025943" textVal="Change log" />,
                        "path": "#"
                    }
                };
                break;
            default:
                break;
        }

    }

    /**
    * Callback function that keeps the data from api into the state
    * convert auditType id to text
    */
    callbackFromChangeLogView(responseData) {
        this.props.loader.showLoader({ "GET_change_log_view_loader": false });

        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData && jsonData !== undefined) {
                jsonData.auditDateTime = usrLib.formatDate(jsonData.auditDateTime)


                let transObj = {}
                for (let i = 0; i < this.state.transTexts.length; i = i + 1) {
                    transObj[this.state.transTexts[i].number] = this.state.transTexts[i].text;
                }
                const transObj1 = {
                    "0": '99003381',
                    "1": '99024415',
                    "2": '99002987',
                    "3": '99008007',
                }

                jsonData.auditType = transObj[transObj1[jsonData.auditType]]

                this.setState({ currentRecData: jsonData });
            }
            else {
                this.setState({ currentRecData: {} });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    async componentDidMount() {


        /**
        * Extracting the auditId from the URL if present 
        * Extracting the auditId from the props 
        */

        let auditId;
        if (this.props.dataType === "userroles" || this.props.dataType === "masterroleadgroup" || this.props.dataType === "masterroleauthassignment") {
            auditId = this.props.auditId
        }
        else {
            auditId = (conversionLib.getParameterByName('auditId', baseUrl.href) !== null && conversionLib.getParameterByName('auditId', baseUrl.href) !== '') ? conversionLib.getParameterByName('auditId', baseUrl.href) : null;
        }
        this.props.loader.showLoader({ "GET_change_log_view_loader": true });

        let num = [99002987, 99024415, 99008007, 99003381]
        let responseData = await getMultitermTexts(num)
        if (responseData.state.response !== undefined && responseData.state.response.data !== undefined && responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                transTexts: jsonData
            })
        }

        let siteName = getSiteName();

        // conditionally calling the APIs based on what changelog need to be shown
        switch (this.props.dataType) {
            case "user":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            case "site":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            case "userroles":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_UserRoles_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            case "masterroleadgroup":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            case "masterroleauthassignment":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAuthAssgnmts_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            case "role":
                auditId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_audit_auditId(this.callbackFromChangeLogView.bind(this), auditId);
                break;
            default:
                break;
        }
    }


    /**
    * Redirecting if needed after restore 
    */
    callbackAuditRestore(responseData) {

        if (responseData.state.response.status === 200) {

            switch (this.props.dataType) {
                case "user":
                    this.setState({ redirectPath: "user_change_log_ng" });
                    break;
                case "site":
                    this.setState({ redirectPath: "site_change_log_ng" });
                    break;
                case "role":
                    this.setState({ redirectPath: "role_change_log_ng" });
                    break;
                case "userroles":
                    this.setState({ redirectPath: null });
                    break;
                case "masterroleadgroup":
                    this.setState({ redirectPath: null });
                    break;
                case "masterroleauthassignment":
                    this.setState({ redirectPath: null });
                    break;
                default:
                    break;
            }

        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    /**
    * Calling restore apis
    */
    handleRestore() {
        let siteName = getSiteName();
        switch (this.props.dataType) {
            case "user":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Users_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            case "site":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Sites_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            case "role":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Roles_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            case "userroles":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_UserRoles_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            case "masterroleadgroup":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_RoleAdGroups_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            case "masterroleauthassignment":
                this.state.currentRecData && OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_RoleAuthAssgnmts_restoreaudit_auditId(this.callbackAuditRestore.bind(this), this.state.currentRecData.auditId);
                break;
            default:
                break;
        }
    }


    render() {
        /**
        * New/old data key-value pair generation 
        */

        var newValueObj = {};
        if (this.state.currentRecData.newValues !== undefined && this.state.currentRecData.newValues !== "") {
            newValueObj = JSON.parse(this.state.currentRecData.newValues);
        }
        const updatedNewValueObj = Object.entries(newValueObj);

        var oldValueObj = {};
        if (this.state.currentRecData.oldValues !== undefined && this.state.currentRecData.oldValues !== "") {
            oldValueObj = JSON.parse(this.state.currentRecData.oldValues);
        }
        const updatedOldValueObj = Object.entries(oldValueObj);

        return (
            <div>
                {this.state.redirectPath !== null && this.state.redirectPath !== undefined && this.state.redirectPath !== '' ? <Redirect to={this.state.redirectPath} /> : null}

                {this.dataObjBreadCrumbsSV && <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSV} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />}

                <LiDivContainer containerHeight={parseInt(window.innerHeight - 130)} containerID="ViewAudit" containerScrollX={true} containerScrollY={true}>

                    <div className={"usrMasterData_Main"}>

                        <div className="auditView  usrMasterData_GridItem">
                            <div className="auditView  usrMasterData_GridContainer">
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                    <LiLabel labelText={<LiMultiterm textId="99030638" textVal="Audit ID" />} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                    <LiLabel labelText={this.state.currentRecData.auditId !== undefined ? this.state.currentRecData.auditId : '--'} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                    <LiLabel labelText={<LiMultiterm textId="99030639" textVal="Audit Type" />} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                    <LiLabel labelText={this.state.currentRecData.auditType !== undefined ? this.state.currentRecData.auditType : '--'} />
                                </div>
                            </div>

                            <div className="auditView  usrMasterData_GridContainer">
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                    <LiLabel labelText={<LiMultiterm textId="99001942" textVal="User" />} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                    <LiLabel labelText={this.state.currentRecData.auditUser !== undefined ? this.state.currentRecData.auditUser : '--'} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                    <LiLabel labelText={<LiMultiterm textId="6000764" textVal="Date" />} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                    <LiLabel labelText={this.state.currentRecData.auditDateTime !== undefined ? conversionLib.dateTimeFormatConversion(usrLib.formatDate(this.state.currentRecData.auditDateTime), 'datetime',) : '--'} />
                                </div>
                            </div>

                            <div className="auditView  usrMasterData_GridContainer">
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                    <LiLabel labelText={<LiMultiterm textId="99001073" textVal="Key Value" />} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                    <LiLabel labelText={this.state.currentRecData.keyValues !== undefined ? this.state.currentRecData.keyValues : '--'} />
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_normal">
                                </div>
                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                </div>
                            </div>

                            <br /><br />

                            {updatedOldValueObj && <div className="auditView  usrMasterData_GridContainerTable usrMasterData_normal">
                                <LiLabel labelText={<LiMultiterm textId="99030636" textVal="Old Values" />} />
                            </div>}

                            {
                                updatedOldValueObj && updatedOldValueObj.map((element, key) => {

                                    return (
                                        <>
                                            <div className="auditView  usrMasterData_GridContainer" key={key}>
                                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                                    <LiLabel
                                                        // labelText={
                                                        //     <LiMultiterm
                                                        //         textId={element[0] !== "" && this.dataTypeArr[element[0]] ? this.dataTypeArr[element[0]].textNumber : "-"}
                                                        //         textVal={element[0] !== "" && this.dataTypeArr[element[0]] ? this.dataTypeArr[element[0]].text : "-"}
                                                        //     />
                                                        // }
                                                        labelText={element[0]}
                                                    />
                                                </div>

                                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                                    <LiLabel labelText={element[1]} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            <br /><br />


                            {updatedNewValueObj && <div className="auditView  usrMasterData_GridContainerTable usrMasterData_normal">
                                <LiLabel labelText={<LiMultiterm textId="99030637" textVal="New Values" />} />
                            </div>}
                            {
                                updatedNewValueObj && updatedNewValueObj.map((element, key) => {

                                    return (
                                        <>
                                            <div className="auditView  usrMasterData_GridContainer" key={key}>
                                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                                    <LiLabel
                                                        // labelText={
                                                        //     <LiMultiterm
                                                        //         textId={element[0] !== "" && this.dataTypeArr[element[0]] ? this.dataTypeArr[element[0]].textNumber : "-"}
                                                        //         textVal={element[0] !== "" && this.dataTypeArr[element[0]] ? this.dataTypeArr[element[0]].text : "-"}
                                                        //     />}
                                                        labelText={element[0]}
                                                    />
                                                </div>

                                                <div className="auditView  usrMasterData_GridItem usrMasterData_bold">
                                                    <LiLabel labelText={element[1]} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            {
                                this.state.currentRecData && this.state.currentRecData.auditType !== "Created"
                                    ?
                                    <div className="auditView  usrMasterData_GridContainerTable">
                                        <LiButton
                                            buttonLabel={<LiMultiterm textId="99003416" textVal="Restore" />}
                                            buttonOnClickHandler={this.handleRestore.bind(this)}
                                        />
                                    </div>
                                    :
                                    ""
                            }

                        </div>
                    </div>

                    <LiStatusContainer />

                </LiDivContainer>
            </div>
        )
    }
}
