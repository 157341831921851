import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, getSiteName, OpenApiBody, MimeTypes } from 'lisecutilityfunctions/lib/openapi';
import { LiBreadCrumbs, LiDivContainer, LiStatusManager, LiStatusContainer, LiReactDialog } from 'liseccomponents';
import { LiTable } from 'lisecdependantcomp';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';
import ChangeLogView from './changelogview';

const baseUrl = window.location;

const HeaderArrChangeLog = {
    "auditId": ['99030638', 'Change ID'],
    "keyValues": ['99001073', 'Key Value'],
    "auditUser": ['99001942', 'User'],
    "auditType": ['99030639', 'Change type'],
    "auditDateTime": ['99030690', 'Modified date'],

};

const tableConfigurationChangeLog = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false,
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.change_log") !== null ? conversionLib.getLocalStorageValue("table.pagesize.change_log") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": HeaderArrChangeLog,
        "defaultSortingColumn": "auditDateTime",
        "defaultSortingOrder": "DESC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
            "Filtered Audit Trail": ['99030807', "Filtered Audit Trail"]
        },
        "receiverFunction": "getDataFromTable"
    },
    "dataTypeConfiguration": {
        "auditId": "link",
        "keyValues": "text",
        "auditUser": "text",
        "auditType": "text",
        "auditDateTime": "datetime",

    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};
const tableConfigurationFilteredChangeLog = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.filtered_change_log") !== null ? conversionLib.getLocalStorageValue("table.pagesize.filtered_change_log") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": HeaderArrChangeLog,
        "defaultSortingColumn": "auditDateTime",
        "defaultSortingOrder": "DESC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
        },
        "receiverFunction": "getDataFromFilterTable"
    },
    "dataTypeConfiguration": {
        "auditId": "link",
        "keyValues": "text",
        "auditUser": "text",
        "auditType": "text",
        "auditDateTime": "datetime",

    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};
const tableConfigurationMasterRoleAdGroupChangeLog = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.master_role_ad_group_change_log") !== null ? conversionLib.getLocalStorageValue("table.pagesize.master_role_ad_group_change_log") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": HeaderArrChangeLog,
        "defaultSortingColumn": "auditDateTime",
        "defaultSortingOrder": "DESC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
        },
        "receiverFunction": "getDataFromMasterRoleAdGroupTable"
    },
    "dataTypeConfiguration": {
        "auditId": "link",
        "keyValues": "text",
        "auditUser": "text",
        "auditType": "text",
        "auditDateTime": "datetime",

    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};

const tableConfigurationMasterRoleAuthAssignmentChangeLog = {
    "getRowDataConfiguration": {
        "enableRowData": false
    },
    "linkConfiguration": {
        "enableLink": false
    },
    "paginationConfiguration": {
        "enablePaging": true,
        "pageSize": conversionLib.getLocalStorageValue("table.pagesize.master_role_auth_assignment_change_log") !== null ? conversionLib.getLocalStorageValue("table.pagesize.master_role_auth_assignment_change_log") : 10,
        "pageSizeCombo": [5, 10, 15, 20]
    },
    "sortingConfiguration": {
        "enableSorting": true,
        "sortingColumns": HeaderArrChangeLog,
        "defaultSortingColumn": "auditDateTime",
        "defaultSortingOrder": "DESC"
    },
    "actionConfiguration": {
        "showAction": true,
        "actionItems": {
            "View": ['99000829', "View"],
        },
        "receiverFunction": "getDataFromMasterRoleAuthAssignmentTable"
    },
    "dataTypeConfiguration": {
        "auditId": "link",
        "keyValues": "text",
        "auditUser": "text",
        "auditType": "text",
        "auditDateTime": "datetime",

    },
    "multiSelectable": {
        "enabled": false,
        "showCheckboxes": false
    },
    "enableTrigger": false,
    "showHeaderSettings": false
};

class ChangeLog extends Component {

    constructor(props) {
        super();

        this.state = {
            currentChangeLog: [],
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            enableRoute: {},
            currentAction: '',
            showViewMasterRoleAdGroupChangeLogDialog: false,
            changeIdViewMasterRoleAdGroupChangeLog: null,
            showViewMasterRoleAuthAssignmentChangeLogDialog: false,
            changeIdViewMasterRoleAuthAssignmentChangeLog: null,
        };
        this.getDataFromTable = this.getDataFromTable.bind(this);
        this.getDataFromFilterTable = this.getDataFromFilterTable.bind(this);
        this.getDataFromMasterRoleAdGroupTable = this.getDataFromMasterRoleAdGroupTable.bind(this);
        this.getDataFromMasterRoleAuthAssignmentTable = this.getDataFromMasterRoleAuthAssignmentTable.bind(this);


        // Conditionally setting the breadcrumb data for each change log screen

        switch (props.dataType) {
            case 'user':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Users": {
                        "label": <LiMultiterm textId="99028960" textVal="Users" />,
                        "path": "/users_ng"
                    },
                    "Filtered change log": {
                        "label": <LiMultiterm textId="99030807" textVal="Filtered change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'users':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Users": {
                        "label": <LiMultiterm textId="99028960" textVal="Users" />,
                        "path": "/users_ng"
                    },
                    "Change log": {
                        "label": <LiMultiterm textId="99025943" textVal="Change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'site':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Sites": {
                        "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                        "path": "/sites_ng"
                    },
                    "Filtered change log": {
                        "label": <LiMultiterm textId="99030807" textVal="Filtered change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'sites':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Sites": {
                        "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                        "path": "/sites_ng"
                    },
                    "Change log": {
                        "label": <LiMultiterm textId="99025943" textVal="Change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'masterroleadgroup':
                this.dataObjBreadCrumbsSV = null;
                break;
            case 'masterroleauthassignment':
                this.dataObjBreadCrumbsSV = null;
                break;
            case 'role':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Roles": {
                        "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                        "path": "/roles_ng"
                    },
                    "Filtered change log": {
                        "label": <LiMultiterm textId="99030807" textVal="Filtered change log" />,
                        "path": "#"
                    }
                };
                break;
            case 'roles':
                this.dataObjBreadCrumbsSV = {
                    "User Management": {
                        "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                        "path": "/usermain_ng"
                    },
                    "Roles": {
                        "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                        "path": "/roles_ng"
                    },
                    "Change log": {
                        "label": <LiMultiterm textId="99025943" textVal="Change log" />,
                        "path": "#"
                    }
                };
                break;
            default:
                break;
        }

    }

    /**
    * Callback function that keeps the data from api into the state
    * convert auditType id to text
    */

    callbackWithArgFromChangeLog(responseData) {
        this.props.loader.showLoader({ "GET_change_log_loader": false });

        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData && jsonData !== undefined) {
                for (let i = 0; i < jsonData.length; i = i + 1) {
                    jsonData[i].auditDateTime = usrLib.formatDate(jsonData[i].auditDateTime)
                }

                let transObj = {}
                for (let i = 0; i < this.state.transTexts.length; i = i + 1) {
                    transObj[this.state.transTexts[i].number] = this.state.transTexts[i].text;
                }
                const transObj1 = {
                    "0": '99003381',
                    "1": '99024415',
                    "2": '99002987',
                    "3": '99008007',
                }

                jsonData.map(element => {
                    element.auditType = transObj[transObj1[element.auditType]]
                });

                this.setState({ currentChangeLog: jsonData });
            }
            else {
                this.setState({ currentChangeLog: [] });
            }
        }
        else if (responseData.state.response.status === 204) {
            this.setState({ currentChangeLog: [] });
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    async componentDidMount() {

        // Extracting the userId/entityid from the URL if present 
        let userId = (conversionLib.getParameterByName('userid', baseUrl.href) !== null && conversionLib.getParameterByName('userid', baseUrl.href) !== '') ? conversionLib.getParameterByName('userid', baseUrl.href) : null;
        let roleId = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : null;
        let entityid = (conversionLib.getParameterByName('entityid', baseUrl.href) !== null && conversionLib.getParameterByName('entityid', baseUrl.href) !== '') ? conversionLib.getParameterByName('entityid', baseUrl.href) : null;

        this.props.loader.showLoader({ "GET_change_log_loader": true });

        let num = [99002987, 99024415, 99008007, 99003381]
        let responseData = await getMultitermTexts(num)
        if (responseData.state.response !== undefined && responseData.state.response.data !== undefined && responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                transTexts: jsonData
            })
        }

        let siteName = getSiteName();

        // conditionally calling the APIs based on what changelog need to be shown

        switch (this.props.dataType) {
            case "user":
                userId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_Users_filteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "UserIdField": userId }));
                break;
            case "users":
                OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Users_audit(this.callbackWithArgFromChangeLog.bind(this));
                break;
            case "site":
                entityid !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_Sites_filteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "EntityIdField": entityid }));
                break;
            case "sites":
                OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites_audit(this.callbackWithArgFromChangeLog.bind(this));
                break;
            case "role":
                roleId !== null && OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_Roles_filteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "RoleIdField": roleId }));
                break;
            case "roles":
                OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_audit(this.callbackWithArgFromChangeLog.bind(this));
                break;
            case "masterroleadgroup":
                OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_RoleAdGroups_partialFilteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "roleId": roleId }));
                break;
            case "masterroleauthassignment":
                OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_RoleAuthAssgnmts_partialFilteredAudit(this.callbackWithArgFromChangeLog.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, { "roleId": roleId }));
                break;
            default:
                break;
        }
    }

    getDataFromMasterRoleAdGroupTable(reasonRowID, recData, actionName, dummy1, dummy2, dummy3, actions) {
        if (actions.key === "View") {
            this.setState({
                showViewMasterRoleAdGroupChangeLogDialog: true,
                changeIdViewMasterRoleAdGroupChangeLog: recData[reasonRowID].value.auditId,
            });
        }
    }

    getDataFromMasterRoleAuthAssignmentTable(reasonRowID, recData, actionName, dummy1, dummy2, dummy3, actions) {
        if (actions.key === "View") {
            this.setState({
                showViewMasterRoleAuthAssignmentChangeLogDialog: true,
                changeIdViewMasterRoleAuthAssignmentChangeLog: recData[reasonRowID].value.auditId,
            });
        }
    }

    getDataFromTable(rowID, recData, actionName, dummy1, dummy2, dummy3, actions) {
        if (actions.key === "View") {
            this.setState({ currentAction: 'View' });
            var enableRoute = this.state.enableRoute;
            switch (this.props.dataType) {
                case 'users':
                    enableRoute['View'] = { status: true, link: '/user_change_log_view_ng?auditId=' + recData[rowID].value.auditId }
                    break;
                case 'sites':
                    enableRoute['View'] = { status: true, link: '/site_change_log_view_ng?auditId=' + recData[rowID].value.auditId }
                    break;
                default:
                    break;

            }
            this.setState({ enableRoute });
        }

        if (actions.key === "Filtered Audit Trail") {
            this.setState({ currentAction: 'filteredList' });

            var enableRoute = this.state.enableRoute;
            switch (this.props.dataType) {
                case 'users':
                    enableRoute['filteredList'] = { status: true, link: '/user_filtered_change_log_ng?userid=' + JSON.parse(recData[rowID].value.keyValues)['UserIdField'] }
                    break;
                case 'sites':
                    enableRoute['filteredList'] = { status: true, link: '/site_filtered_change_log_ng?entityid=' + JSON.parse(recData[rowID].value.keyValues)['EntityIdField'] }
                    break;
                case 'roles':
                    enableRoute['filteredList'] = { status: true, link: '/role_filtered_change_log_ng?roleid=' + JSON.parse(recData[rowID].value.keyValues)['RoleIdField'] }
                    break;
                default:
                    break;


            }
            this.setState({ enableRoute });

        }
    }

    getDataFromFilterTable(rowID, recData, actionName, dummy1, dummy2, dummy3, actions) {
        if (actions.key === "View") {
            this.setState({ currentAction: 'View' });
            var enableRoute = this.state.enableRoute;

            switch (this.props.dataType) {
                case 'user':
                    enableRoute['View'] = { status: true, link: '/user_change_log_view_ng?auditId=' + recData[rowID].value.auditId }
                    break;
                case 'site':
                    enableRoute['View'] = { status: true, link: '/site_change_log_view_ng?auditId=' + recData[rowID].value.auditId }
                    break;
                default:
                    break;

            }
            this.setState({ enableRoute });
        }
    }

    showViewMasterRoleAdGroupChangeLogDialog() {
        return (
            <LiReactDialog
                enableStoring="user_management_master_role_ad_group_view_change_log"
                title={<LiMultiterm textId="" textVal="Master Role Ad Group View Change Log" />}
                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                onClose={this.closeMasterRoleAdGroupViewChangeLog.bind(this)}
                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                width={1000} height={600} buttons={
                    [
                        {
                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                            onClick: () => this.closeMasterRoleAdGroupViewChangeLog(),
                            className: "LiGeneral_raisedButton"
                        }
                    ]
                }
            >
                <ChangeLogView auditId={this.state.changeIdViewMasterRoleAdGroupChangeLog} dataType="masterroleadgroup" loader={this.props.loader} />
            </LiReactDialog>
        )
    }
    closeMasterRoleAdGroupViewChangeLog() {
        this.setState({ showViewMasterRoleAdGroupChangeLogDialog: false })
    }

    showViewMasterRoleAuthAssignmentChangeLogDialog() {
        return (
            <LiReactDialog
                enableStoring="user_management_master_role_auth_assignment_view_change_log"
                title={<LiMultiterm textId="" textVal="Master Role Auth Assignment View Change Log" />}
                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                onClose={this.closeMasterRoleAuthAssignmentViewChangeLog.bind(this)}
                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                width={1000} height={600} buttons={
                    [
                        {
                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                            onClick: () => this.closeMasterRoleAuthAssignmentViewChangeLog(),
                            className: "LiGeneral_raisedButton"
                        }
                    ]
                }
            >
                <ChangeLogView auditId={this.state.changeIdViewMasterRoleAuthAssignmentChangeLog} dataType="masterroleauthassignment" loader={this.props.loader} />
            </LiReactDialog>
        )
    }
    closeMasterRoleAuthAssignmentViewChangeLog() {
        this.setState({ showViewMasterRoleAuthAssignmentChangeLogDialog: false })
    }

    linkData(rowData) {
        this.setState({ currentAction: 'View' });

        var enableRoute = this.state.enableRoute;

        switch (this.props.dataType) {
            case 'user':
                enableRoute['View'] = { status: true, link: '/user_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'site':
                enableRoute['View'] = { status: true, link: '/site_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'users':
                enableRoute['View'] = { status: true, link: '/user_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'sites':
                enableRoute['View'] = { status: true, link: '/site_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'role':
                enableRoute['View'] = { status: true, link: '/role_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'roles':
                enableRoute['View'] = { status: true, link: '/role_change_log_view_ng?auditId=' + rowData.value }
                break;
            case 'masterroleadgroup':
                // enableRoute['View'] = { status: true, link: '/user_change_log_view_ng?auditId=' + rowData.value }
                this.setState({
                    showViewMasterRoleAdGroupChangeLogDialog: true,
                    changeIdViewMasterRoleAdGroupChangeLog: rowData.value,
                });
                break;
            case 'masterroleauthassignment':
                // enableRoute['View'] = { status: true, link: '/site_change_log_view_ng?auditId=' + rowData.value }
                this.setState({
                    showViewMasterRoleAuthAssignmentChangeLogDialog: true,
                    changeIdViewMasterRoleAuthAssignmentChangeLog: rowData.value,
                });
                break;
            default:
                break;

        }
        this.setState({ enableRoute });
    }


    render() {
        let openViewMasterRoleAdGroupChangeLogDialog = this.state.showViewMasterRoleAdGroupChangeLogDialog ? this.showViewMasterRoleAdGroupChangeLogDialog() : null
        let openViewMasterRoleAuthAssignmentChangeLogDialog = this.state.showViewMasterRoleAuthAssignmentChangeLogDialog ? this.showViewMasterRoleAuthAssignmentChangeLogDialog() : null

        return (
            <>
                {
                    this.state.enableRoute[this.state.currentAction] !== undefined && this.state.enableRoute[this.state.currentAction]['status'] === true ?
                        <Redirect to={this.state.enableRoute[this.state.currentAction]['link']} />
                        :
                        null
                }

                {this.dataObjBreadCrumbsSV && <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSV} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />}
                <br />
                <LiDivContainer containerHeight={parseInt(window.innerHeight - 150)} containerID="change.log" containerScrollY={true} containerScrollX={true} >
                    <LiTable
                        tableId={this.props.dataType}
                        config={
                            (this.props.dataType === "users" || this.props.dataType === "sites" || this.props.dataType === "roles") ? tableConfigurationChangeLog :
                                (this.props.dataType === "user" || this.props.dataType === "site" || this.props.dataType === "role") ? tableConfigurationFilteredChangeLog :
                                    this.props.dataType === "masterroleadgroup" ? tableConfigurationMasterRoleAdGroupChangeLog :
                                        this.props.dataType === "masterroleauthassignment" ? tableConfigurationMasterRoleAuthAssignmentChangeLog : null
                        }
                        colHeaderArr={HeaderArrChangeLog}
                        rowArr={this.state.currentChangeLog}
                        receiverFunction={
                            (this.props.dataType === "users" || this.props.dataType === "sites" || this.props.dataType === "roles") ? this.getDataFromTable :
                                (this.props.dataType === "user" || this.props.dataType === "site" || this.props.dataType === "role") ? this.getDataFromFilterTable :
                                    this.props.dataType === "masterroleadgroup" ? this.getDataFromMasterRoleAdGroupTable :
                                        this.props.dataType === "masterroleauthassignment" ? this.getDataFromMasterRoleAuthAssignmentTable : null
                        }
                        handleData={() => { }}
                        subTable={false}
                        enableColSearch={true}
                        linkData={this.linkData.bind(this)}
                    />
                    {openViewMasterRoleAdGroupChangeLogDialog}
                    {openViewMasterRoleAuthAssignmentChangeLogDialog}
                    <LiStatusContainer />
                </LiDivContainer>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        // sitemgmt: state.sitemgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLog);


