import React from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';

import UserMgmtMainNG from './app_modules/usermgmt_ng/components/usermgmtmain';
import UserMgmtRouteNG from './app_modules/usermgmt_ng/components/usrmgmtroute';

import UserMgmtIcon from './app_modules/usermgmt_ng/components/images/user_management.svg';
import alluserIcon from './app_modules/usermgmt_ng/components/images/all_users.svg';
import allSitesIcon from './app_modules/usermgmt_ng/components/images/all_sites.svg';
import allRolesIcon from './app_modules/usermgmt_ng/components/images/all_roles.svg';
import companyIcon from './app_modules/usermgmt_ng/components/images/company.svg';
import jobTitleIcon from './app_modules/usermgmt_ng/components/images/job_title.svg';
import appDetailIcon from './app_modules/usermgmt_ng/components/images/application_detail.svg';



export const usermgmt_main_ng = (param = {}) => {
    return (<UserMgmtMainNG changeOpen={param.settingsDialogOpen} loader={param.props} />);
}

export const usermgmt_route_ng = (param = {}) => {
    return (<UserMgmtRouteNG changeOpen={param.settingsDialogOpen} loader={param.props} />);
}

export const mapRoute = {
    "menu_usermgmt_main_ng": usermgmt_main_ng,
    "menu_usermgmt_route_ng": usermgmt_route_ng,

    "menu_usermgmt_allusers_ng": usermgmt_main_ng,
    "menu_usermgmt_addusers_ng": usermgmt_main_ng,
    "menu_usermgmt_viewusers_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_user_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_users_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_user_view_ng": usermgmt_main_ng,


    "menu_usermgmt_editusers_ng": usermgmt_main_ng,

    "menu_usermgmt_allsites_ng": usermgmt_main_ng,
    "menu_usermgmt_addsites_ng": usermgmt_main_ng,
    "menu_usermgmt_viewsites_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_sites_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_site_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_site_view_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_roles_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_role_view_ng": usermgmt_main_ng,
    "menu_usermgmt_change_log_role_ng": usermgmt_main_ng,

    "menu_usermgmt_editsites_ng": usermgmt_main_ng,

    "menu_usermgmt_allroles_ng": usermgmt_main_ng,
    "menu_usermgmt_addroles_ng": usermgmt_main_ng,
    "menu_usermgmt_viewroles_ng": usermgmt_main_ng,
    "menu_usermgmt_editroles_ng": usermgmt_main_ng,



    // "menu_usermgmt_allcompany_ng"      : usermgmt_main_ng,
    // "menu_usermgmt_addcompany_ng"      : usermgmt_main_ng,
    // "menu_usermgmt_viewcompany_ng"     : usermgmt_main_ng,
    // "menu_usermgmt_editcompany_ng"     : usermgmt_main_ng,

    // "menu_usermgmt_alljobtitle_ng"     : usermgmt_main_ng,
    // "menu_usermgmt_addjobtitle_ng"     : usermgmt_main_ng,
    // "menu_usermgmt_viewjobtitle_ng"    : usermgmt_main_ng,
    // "menu_usermgmt_editjobtitle_ng"    : usermgmt_main_ng,

    // NG-19840 (REMOVE - APPLICATION DETAILS MENU)
    // "menu_application_details_ng": usermgmt_main_ng,
    // "menu_application_details_add_ng": usermgmt_main_ng,
    // "menu_application_details_view_ng": usermgmt_main_ng,
    // "menu_application_details_edit_ng": usermgmt_main_ng,
}

export const menus = [
    {
        "key": "menu_usermgmt_route_ng", "menu": "User Management NG", "menuMultiterm": <LiMultiterm textId="99005135" textVal="User Management" />,
        "submenus": [
            { "key": "menu_usermgmt_allsites_ng", "menu": "Sites", "menuMultiterm": <LiMultiterm textId="99027118" textVal="Production Sites" />, "router_link": "/sites_ng", "menuIcon": allSitesIcon, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_addsites_ng", "menu": "Add Sites", "menuMultiterm": <LiMultiterm textId="99029979" textVal="Add Site" />, "router_link": "/site_add_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_viewsites_ng", "menu": "View Sites", "menuMultiterm": <LiMultiterm textId="99008209" textVal="View Sites" />, "router_link": "/site_view_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_site_ng", "menu": "Change Log Site", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Site" />, "router_link": "/site_filtered_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_sites_ng", "menu": "Change Log Sites", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Sites" />, "router_link": "/site_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_site_view_ng", "menu": "Change Log Site View", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Site View" />, "router_link": "/site_change_log_view_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },

            { "key": "menu_usermgmt_editsites_ng", "menu": "Edit Site", "menuMultiterm": <LiMultiterm textId="99029980" textVal="Edit Site" />, "router_link": "/site_edit_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },

            { "key": "menu_usermgmt_allroles_ng", "menu": "Roles", "menuMultiterm": <LiMultiterm textId="99029981" textVal="Roles" />, "router_link": "/roles_ng", "menuIcon": allRolesIcon, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_addroles_ng", "menu": "Add Roles", "menuMultiterm": <LiMultiterm textId="99029982" textVal="Add Roles" />, "router_link": "/role_add_ng", "menuIcon": allRolesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_viewroles_ng", "menu": "View Roles", "menuMultiterm": <LiMultiterm textId="99008209" textVal="View Roles" />, "router_link": "/role_view_ng", "menuIcon": allRolesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_editroles_ng", "menu": "Edit Roles", "menuMultiterm": <LiMultiterm textId="99029983" textVal="Edit roles" />, "router_link": "/role_edit_ng", "menuIcon": allRolesIcon, "showInMenu": false, "requiredAuthorization": [2] },

            { "key": "menu_usermgmt_allusers_ng", "menu": "Users", "menuMultiterm": <LiMultiterm textId="99028960" textVal="Users" />, "router_link": "/users_ng", "menuIcon": alluserIcon, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_addusers_ng", "menu": "Add Users", "menuMultiterm": <LiMultiterm textId="99025174" textVal="Add Users" />, "router_link": "/user_add_ng", "menuIcon": alluserIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_viewusers_ng", "menu": "View User", "menuMultiterm": <LiMultiterm textId="99008209" textVal="View User" />, "router_link": "/user_view_ng", "menuIcon": alluserIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_user_ng", "menu": "Change Log User", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log User" />, "router_link": "/user_filtered_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_users_ng", "menu": "Change Log Users", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Users" />, "router_link": "/user_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_user_view_ng", "menu": "Change Log User View", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log User View" />, "router_link": "/user_change_log_view_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },

            { "key": "menu_usermgmt_editusers_ng", "menu": "Edit User", "menuMultiterm": <LiMultiterm textId="99025173" textVal="Edit User" />, "router_link": "/user_edit_ng", "menuIcon": alluserIcon, "showInMenu": false, "requiredAuthorization": [2] },

            { "key": "menu_usermgmt_change_log_role_ng", "menu": "Change Log Role", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log User" />, "router_link": "/role_filtered_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_roles_ng", "menu": "Change Log Roles", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Roles" />, "router_link": "/role_change_log_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },
            { "key": "menu_usermgmt_change_log_role_view_ng", "menu": "Change Log Role View", "menuMultiterm": <LiMultiterm textId="" textVal="Change Log Role View" />, "router_link": "/role_change_log_view_ng", "menuIcon": allSitesIcon, "showInMenu": false, "requiredAuthorization": [2] },


            // {"key": "menu_usermgmt_allcompany_ng", "menu": "Company", "menuMultiterm": <LiMultiterm textId="99030918" textVal="Company Structure" />, "router_link": "/company_entities_ng", "menuIcon": companyIcon, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_addcompany_ng", "menu": "Add Company", "menuMultiterm": <LiMultiterm textId="99008209" textVal="Add Company" />, "router_link": "/company_add_ng", "menuIcon": companyIcon, "showInMenu" : false, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_viewcompany_ng", "menu": "View Company", "menuMultiterm": <LiMultiterm textId="99008209" textVal="View Company" />, "router_link": "/company_view_ng", "menuIcon": companyIcon, "showInMenu" : false, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_editcompany_ng", "menu": "Edit Company", "menuMultiterm": <LiMultiterm textId="99029984" textVal="Edit company" />, "router_link": "/company_edit_ng", "menuIcon": companyIcon, "showInMenu" : false, "requiredAuthorization": [2]},        

            // {"key": "menu_usermgmt_alljobtitle_ng", "menu": "Job Title", "menuMultiterm": <LiMultiterm textId="99029164" textVal="Job Title" />, "router_link": "/job_titles_ng", "menuIcon": jobTitleIcon, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_addjobtitle_ng", "menu": "Add Job Title", "menuMultiterm": <LiMultiterm textId="99029985" textVal="Add Job Title" />, "router_link": "/jobtitle_add_ng", "menuIcon": jobTitleIcon, "showInMenu" : false, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_viewjobtitle_ng", "menu": "View Job Titles", "menuMultiterm": <LiMultiterm textId="99008209" textVal="View Job Titles" />, "router_link": "/jobtitle_view_ng", "menuIcon": jobTitleIcon, "showInMenu" : false, "requiredAuthorization": [2]},
            // {"key": "menu_usermgmt_editjobtitle_ng", "menu": "Edit Job Titles", "menuMultiterm": <LiMultiterm textId="99029986" textVal="Edit Job Titles" />, "router_link": "/jobtitle_edit_ng", "menuIcon": jobTitleIcon, "showInMenu" : false, "requiredAuthorization": [2]},

            // NG-19840 (REMOVE - APPLICATION DETAILS MENU)
            // { "key": "menu_application_details_ng", "menu": "Application Detail", "menuMultiterm": <LiMultiterm textId="" textVal="Application Detail" />, "router_link": "/application_detail_ng", "menuIcon": appDetailIcon, "requiredAuthorization": [2] },
            // { "key": "menu_application_details_add_ng", "menu": "Add Application Detail", "menuMultiterm": <LiMultiterm textId="99008209" textVal="Application Detail" />, "router_link": "/application_detail_add_ng", "menuIcon": appDetailIcon, "showInMenu": false, "requiredAuthorization": [2] },
            // { "key": "menu_application_details_view_ng", "menu": "View Application Detail", "menuMultiterm": <LiMultiterm textId="99008209" textVal="Application Detail" />, "router_link": "/application_detail_view_ng", "menuIcon": appDetailIcon, "showInMenu": false, "requiredAuthorization": [2] },
            // { "key": "menu_application_details_edit_ng", "menu": "Edit Application Detail", "menuMultiterm": <LiMultiterm textId="99008209" textVal="Application Detail" />, "router_link": "/application_detail_edit_ng", "menuIcon": appDetailIcon, "showInMenu": false, "requiredAuthorization": [2] },
        ], "router_link": "/usermain_ng", "menuIcon": UserMgmtIcon, "requiredAuthorization": [2]
    },
];
