import React, { Component } from 'react';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { LiImageMenu } from 'liseccomponents';


const theme = (conversionLib.getLocalStorageValue("core.app.theme") !== null) ? conversionLib.getLocalStorageValue("core.app.theme") : "LightTheme";
const userActiveIcon = require('./images/' + theme + '/users_active.svg');
const userHoverIcon = require('./images/' + theme + '/users_hover.svg');
const siteActiveIcon = require('./images/' + theme + '/sites_active.svg');
const siteHoverIcon = require('./images/' + theme + '/sites_hover.svg');
const roleActiveIcon = require('./images/' + theme + '/roles_active.svg');
const roleHoverIcon = require('./images/' + theme + '/roles_hover.svg');
// const comapnyActiveIcon = require('./images/' + theme + '/company_active.svg');
// const companyHoverIcon = require('./images/' + theme + '/company_hover.svg');
// const jobTitleActiveIcon = require('./images/' + theme + '/job_title_active.svg');
// const jobTitleHoverIcon = require('./images/' + theme + '/job_title_hover.svg');
const appDetailActiveIcon = require('./images/' + theme + '/application_detail.svg');
const appDetailHoverIcon = require('./images/' + theme + '/application_detail_hover.svg');

class UserMgmtRoute extends Component {

    constructor() {
        super();
        this.state = {
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }


    render() {
        return (
            <div>
                <div className="LiUsrRouteMainDiv">

                    <div className="LiUsrRouteInnerDiv">
                        <LiImageMenu
                            liImageId="t2"
                            displayImage={siteActiveIcon}
                            hoverImage={siteHoverIcon}
                            imageTitle={<LiMultiterm textId="99027118" textVal="Production Sites" />}
                            imageDescription={<LiMultiterm textId="99030898" textVal="Add/Edit Site" />}
                            imageLinkTo="/sites_ng"
                        />
                    </div>
                    <div className="LiUsrRouteInnerDiv">
                        &#160;
                    </div>
                    <div className="LiUsrRouteInnerDiv">
                        <LiImageMenu
                            liImageId="t3"
                            displayImage={roleActiveIcon}
                            hoverImage={roleHoverIcon}
                            imageTitle={<LiMultiterm textId="99029981" textVal="Roles" />}
                            imageDescription={<LiMultiterm textId="99030899" textVal="Add/Edit Role" />}
                            imageLinkTo="/roles_ng"
                        />
                    </div>
                </div>
                <div className="LiUsrRouteMainDiv">
                    <div className="LiUsrRouteInnerDiv">
                        {/* <LiImageMenu 
                            liImageId="t4"
                            displayImage={comapnyActiveIcon}
                            hoverImage={companyHoverIcon}
                            imageTitle={<LiMultiterm textId="99030918" textVal="Company Structure" />}
                            imageDescription={<LiMultiterm textId="99030900" textVal="Add/Edit Entity" />}
                            imageLinkTo="/company_entities_ng"
                        /> */}
                        <LiImageMenu
                            liImageId="t1"
                            displayImage={userActiveIcon}
                            hoverImage={userHoverIcon}
                            imageTitle={<LiMultiterm textId="99028960" textVal="Users" />}
                            imageDescription={<LiMultiterm textId="99030897" textVal="Add/Edit User" />}
                            imageLinkTo="/users_ng"
                        />
                    </div>
                    <div className="LiUsrRouteInnerDiv">
                        {/* <LiImageMenu 
                            liImageId="t5"
                            displayImage={jobTitleActiveIcon}
                            hoverImage={jobTitleHoverIcon}
                            imageTitle={<LiMultiterm textId="99029164" textVal="Job Title" />}
                            imageDescription={<LiMultiterm textId="99030901" textVal="Add/Edit Job Title" />}
                            imageLinkTo="/job_titles_ng"
                        /> */}
                        &#160;

                    </div>
            {/* // NG-19840 (REMOVE - APPLICATION DETAILS MENU) */}

                    {/* <div className="LiUsrRouteInnerDiv">
                        <LiImageMenu
                            liImageId="t6"
                            displayImage={appDetailActiveIcon}
                            hoverImage={appDetailHoverIcon}
                            imageTitle={<LiMultiterm textId="99030950" textVal="Application Detail" />}
                            imageDescription={<LiMultiterm textId="99030961" textVal="Add/Edit Application Detail" />}
                            imageLinkTo="/application_detail_ng"
                        />
                    </div> */}
                </div>

            </div>
        );
    }
}

export default UserMgmtRoute;
