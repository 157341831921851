import { Link, Redirect } from "react-router-dom";
import * as SiteAction from '../actions/siteaction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiBody, MimeTypes, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiBreadCrumbs, LiDivContainer, LiButton, LiLineEdit, LiLabel, LiSwitch, LiComboBox, LiComboList, LiStatusManager, LiStatusContainer, LiReactDialog, LiForm } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';

const baseUrl = window.location;


class SiteEdit extends Component {

    constructor() {
        super();

        this.state = {
            currentSiteData: {},
            site_edit_confirm_open: false,
            site_edit_save_disabled: false,
            site_edit_delete_open: false,

            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            dbTypeVal: '',
            redirectPathSE: '',

            showInfoDialogSE: false,
            infoMessage: "",
            updateNewLicenseSiteApiKey: ''
        }
        this.secretKeyDummy = "kpw@135713jk$#jlp9176925!$tr";


        this.dataObjBreadCrumbsSE = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Sites": {
                "label": <LiMultiterm textId="99027118" textVal="Production Sites" />,
                "path": "/sites_ng"
            },
            "Edit Site": {
                "label": <LiMultiterm textId="99029980" textVal="Edit Site" />,
                "path": "#"
            }
        }

        this.handleDeleteDataFromSiteEditClose = this.handleDeleteDataFromSiteEditClose.bind(this);
    }

    callbackWithArgFromEditSite(responseData) {
        this.props.loader.showLoader({ "GET_Site_entityId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({
                    currentSiteData: jsonData,
                    dbTypeVal: jsonData.dbTypeField,
                    modifiedField: jsonData.modifiedField
                });
                if (jsonData.licenseSiteIdField !== undefined && jsonData.licenseSiteIdField !== null && jsonData.licenseSiteIdField !== '')
                    this.setState({ updateNewLicenseSiteApiKey: this.secretKeyDummy })
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForUpdateSiteData(responseData) {
        this.props.loader.showLoader({ "PUT_Site": false });

        if (responseData.state.response.status === 200) {
            this.setState({ redirectPathSE: "sites_ng" });
        }
        else if (responseData.state.response.status === 422) {
            LiStatusManager.showStatus(responseData.state.response.status, <LiMultiterm textId="99032202" textVal="The provided API token does not contain a valid subject!" />);
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.callBackForEnableButton.bind(this));
        }
    }

    callBackForEnableButton() {
        this.setState({ site_edit_save_disabled: false });
    }

    componentDidMount() {
        var siteID = (conversionLib.getParameterByName('entityid', baseUrl.href) !== null && conversionLib.getParameterByName('entityid', baseUrl.href) !== '') ? conversionLib.getParameterByName('entityid', baseUrl.href) : 2;
        this.props.loader.showLoader({ "GET_Site_entityId": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites_entityId(this.callbackWithArgFromEditSite.bind(this), siteID);
    }

    editHandleSubmitData(allTrue) {
        if (!allTrue) {
            return
        }

        let entityDescField = document.getElementById('entityDescField').value;
        let entityShdescField = document.getElementById('entityShdescField').value;
        // let hostIpField = document.getElementById('hostIpField').value;
        // let portOffsetField = document.getElementById('portOffsetField').value;
        let dbTypeField = this.state.dbTypeVal;
        let disabledField = (document.getElementById('disabledField').checked === true ? 1 : 0);
        // let licenseSiteIdField = document.getElementById('licenseSiteIdField') !== null ? document.getElementById('licenseSiteIdField').value : "";

        if (entityDescField.trim() === "" || entityShdescField.trim() === "") {
            this.setState({ showInfoDialogSE: true });
            return;
        }
        var dataVal = {
            "interfaceInfo": {
                "type": "MT_MASTER_DATA",
                "version": 1,
                "itemType": "ITEM_TYPE_MASTER_COMPANY_ENTITY"
            },
            "entityIdField": -1,
            "subEntityOfField": null,
        }

        if (this.state.currentSiteData.licenseSiteIdField !== undefined && this.state.currentSiteData.licenseSiteIdField !== null) {
            dataVal["licenseSiteIdField"] = this.state.currentSiteData.licenseSiteIdField;
        }

        if (this.state.updateNewLicenseSiteApiKey !== '' && this.state.updateNewLicenseSiteApiKey !== this.secretKeyDummy) {
            dataVal["licenseSiteApiKeyField"] = this.state.updateNewLicenseSiteApiKey;
        }


        dataVal.entityIdField = this.state.currentSiteData.entityIdField;
        dataVal.entityDescField = entityDescField;
        dataVal.entityShdescField = entityShdescField;
        dataVal.entityTypeField = 2;
        // dataVal.hostIpField = hostIpField;
        // dataVal.portOffsetField = parseInt(portOffsetField);
        dataVal.dbTypeField = dbTypeField;
        dataVal.disabledField = disabledField;
        dataVal.modifiedField = this.state.modifiedField;

        this.setState({ site_edit_save_disabled: true });
        this.props.loader.showLoader({ "PUT_Site": true });

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).PUT_Sites(this.callbackWithArgForUpdateSiteData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, dataVal));
    }

    handleDeleteDataFromEditOpen() {
        this.setState({ site_edit_delete_open: true });
    }

    handleDeleteDataFromSiteEditClose() {
        this.setState({ site_edit_delete_open: false });
    }

    handleDeleteData() {
        this.props.onDeleteAction(this.props.siteID);
    }

    handleChangeSiteEdit(eventSE) {
        const target = eventSE.target;
        const value = target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : target.value;
        const name = eventSE.target.name;
        const siteObj = this.state.currentSiteData;
        siteObj[name] = value;
        this.setState({
            currentSiteData: siteObj
        });
    }

    dbTypeChangeSiteEdit = (value, name, event) => {
        if (value.length !== 0) {
            this.setState({ dbTypeVal: parseInt(value[0]) });
        }
    }

    handleCloseResponseSiteEdit() {
        this.setState({ showInfoDialogSE: false });
    }

    OnUpdateNewLicenseSiteApiKey(eventSE) {
        const target = eventSE.target;

        this.setState({ updateNewLicenseSiteApiKey: target.value })

    }

    render() {
        const stateRequiredSE = [
            { "Name": [<LiMultiterm textId="6001104" textVal="Name" />, this.state.currentSiteData.entityDescField] },
            { "ShortDescription": [<LiMultiterm textId="99002657" textVal="Short description" />, this.state.currentSiteData.entityShdescField] },
        ]
        return (
            <div>
                {this.state.redirectPathSE !== undefined && this.state.redirectPathSE !== '' ? <Redirect to={this.state.redirectPathSE} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsSE} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />
                <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 220)} containerID="LiUsrMgmtSiteEditSub1" containerScrollY={true} containerScrollX={false} >
                    <div className="twoColumnMainGridContainer">
                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} isMandatory={true} /></div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="entityDescField" liEditName="entityDescField" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteEdit.bind(this)} liEditOnKeyUp={this.handleChangeSiteEdit.bind(this)} liEditValue={this.state.currentSiteData.entityDescField !== undefined ? this.state.currentSiteData.entityDescField : ''} />
                        </div>

                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short description" />} isMandatory={true} /></div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="entityShdescField" liEditName="entityShdescField" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteEdit.bind(this)} liEditOnKeyUp={this.handleChangeSiteEdit.bind(this)} liEditValue={this.state.currentSiteData.entityShdescField !== undefined ? this.state.currentSiteData.entityShdescField : ''} />
                        </div>

                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="99032736" textVal="License site ID" />} isMandatory={false} /></div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLineEdit liEditID="licenseSiteIdField" liEditName="licenseSiteIdField" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.handleChangeSiteEdit.bind(this)} liEditOnKeyUp={this.handleChangeSiteEdit.bind(this)} liEditValue={this.state.currentSiteData.licenseSiteIdField !== undefined ? this.state.currentSiteData.licenseSiteIdField : ''} liReadOnly={true} />
                        </div>

                        <div className="twoColumnMainGridContainerItem"><LiLabel labelText={<LiMultiterm textId="99032737" textVal="License site API key" />} isMandatory={false} /></div>
                            <div className="twoColumnMainGridContainerItem">
                                <LiLineEdit liEditID="licenseSiteApiKeyField" liEditName="licenseSiteApiKeyField" liEditStyle={{ 'width': '300px' }} liEditOnChange={this.OnUpdateNewLicenseSiteApiKey.bind(this)} liEditOnKeyUp={this.handleChangeSiteEdit.bind(this)} liEditValue={ this.state.currentSiteData.licenseSiteIdField == undefined && this.state.updateNewLicenseSiteApiKey === this.secretKeyDummy ? '' : this.state.updateNewLicenseSiteApiKey} liReadOnly={false} liPassword={true} />
                            </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                        </div>

                        {
                            this.state.currentSiteData.disabledField !== undefined ?
                                <div className="twoColumnMainGridContainerItem">
                                    <LiSwitch switchName="disabledField" switchId="disabledField" switchDisabled={false} switchVal="disabledField" switchOnChangeHandler={this.handleChangeSiteEdit.bind(this)} switchChecked={this.state.currentSiteData.disabledField === 1 ? true : false} />
                                </div>
                                :
                                ''
                        }
                    </div>
                </LiDivContainer>

                <LiDivContainer containerHeight="70" containerID="LiUsrMgmtUserEditSub2" containerScrollY={false} containerScrollX={false}>
                    <hr className={this.state.theme + " LiUsrMgmtBottom"} />
                    <div className="userMgmtGridContainerFull">
                        <div className="userMgmtGridContainerFullItem">
                            <LiForm
                                height={350}
                                formDialogTitle={<LiMultiterm textId="4000070" textVal="Warning" />}
                                formDialogCloseText={<LiMultiterm textId="99000136" textVal="OK" />}
                                requiredByState={stateRequiredSE}
                                requiredById={[]}
                                allRequiredFilled={this.editHandleSubmitData.bind(this)}
                                buttonLabel={<LiMultiterm textId="99004199" textVal="Save Changes" />}
                                formMessage={<LiMultiterm textId="99030257" textVal="Please fill all the required fields" />}
                            />
                            <Link to="/sites_ng">
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                />
                            </Link>
                        </div>
                    </div>

                    {
                        this.state.showInfoDialogSE
                            ?
                            <LiReactDialog
                                enableStoring="server.response.site.edit"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.handleCloseResponseSiteEdit()
                                    }]
                                }
                                onClose={this.handleCloseResponseSiteEdit.bind(this)}
                                isFooterEnable={false}
                            >
                                <p><LiMultiterm textId="99030257" textVal="Please fill all the required fields" />.</p>
                                <p><LiMultiterm textId="99030605" textVal="Mandatory fields are marked with" /> <span className="user_mandatoryFieldNote">*</span></p>
                            </LiReactDialog>
                            :
                            ""
                    }

                    <LiStatusContainer />
                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToPropsSiteEdit(state) {
    return {
        sitemgmt: state.sitemgmt
    };
}

function mapDispatchToPropsSiteEdit(dispatch) {
    return bindActionCreators({
        setDeleteDialogSite: SiteAction.setDeleteDialogSite,
    }, dispatch)
}

export default connect(mapStateToPropsSiteEdit, mapDispatchToPropsSiteEdit)(SiteEdit);