export const formatDate = (date) => {
    var today = new Date(date);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var min = today.getMinutes();
    var sec = today.getSeconds();

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    if (hour < 10) {
        hour = '0' + hour
    }

    if (min < 10) {
        min = '0' + min
    }

    if (sec < 10) {
        sec = '0' + sec
    }

    today = yyyy + '/' + mm + '/' + dd + ' ' + hour + ':' + min + ':' + sec;


    return (today);
}

export const convertToObject = (data, type) => {
    var opt = {};
    for (var i = 0; i < data[type].length; i++) {
        opt[i] = data[type][i];
    }
    return (opt);
}

