// import moment from 'moment';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import React, { Component } from 'react';

const initial = {
    myRowArr: {},
    view_open: false,
    edit_open: false,
    delete_open: false,
    add_open: false,
    confirm_open: false,
    userID: 0,
    userHeaderArr: {
        "userId": ["99001692","ID #"],"firstName": ["99024423","First Name"],"lastName": ["99024422","Last Name"],"userType": ["99024421","User Type"],"loginName": ["99010629","Login Name"]
    },
    userHeaderArrForView: {
        "userIdField": "User #","loginNameField": "Login Name","userTypeField": "User Type","lastNameField": "Last Name","firstNameField": "First Name","emailField": "Email"
    },
    rowID: 0,
    userRoleArr: [],
    userJobTitlesArr: [],
    userAddFieldObj: {
        "userIdField": -1, 
        "loginNameField": "", 
        "lockedYnField": 0, 
        "osuserYnField": 0, 
        "adminYnField": 0,
        "passwordField": "",
        "userTypeField": '',
        "lastNameField": "",
        "firstNameField": "", 
        "disabledField": 0, 
        "emailField": "",
        "pwAlgorithmField": "Sha3_512", 
        "personalNumberField": "", 
        "abbreviationField": "", 
        "birthdateField": "", 
        "entityIdField": '', 
        "dateRestrictionField": 0,
        "dateRestrictionFromField": "",
        "dateRestrictionToField": "", 
        "hwKeyField" : ""
    },
    userTypeObj: { "0": "Client Side", "1": "Server Side", "2": "Client + Server", "3": "Visu" },
    siteDeptArr: [],
    imageBinaryDataView: '',
    userRolesDragDrop: [],
    userAvailArr: [],
    userAssignArr: [],
    tempUserAvailArr: [],
    tempUserAssignArr: [],
    userExistingAssignArr: [],
    allUserRoleArr: [],
    flag: false,
    jobTitlesFlag: false,
    allRoleDetail: {},
    allUserJobTitles: {},
    eachUserJobTitles: [],
    fullEachUserJobTitles: [],
    userJobTitlesDragDrop: [],
    tempUserJobTitlesAvailArr: [],
    tempUserJobTitlesAssignArr: [],
    userJobTitlesAvailArr: [],
    userJobTitlesAssignArr: [],
    userJobTitlesDetailObj: {},
    WEEK_DAYS : {
        Sunday: <LiMultiterm textId="99003901" textVal="Sun" key="99003901" />,
        Monday: <LiMultiterm textId="99004397" textVal="Mon" key="99004397" />,
        Tuesday: <LiMultiterm textId="99009403" textVal="Tue" key="99009403" />,
        Wednesday: <LiMultiterm textId="99014982" textVal="Wed" key="99014982" />,
        Thursday: <LiMultiterm textId="99004398" textVal="Thu" key="99004398" />,
        Friday: <LiMultiterm textId="99003718" textVal="Fri" key="99003718" />,
        Saturday: <LiMultiterm textId="99015765" textVal="Sat" key="99015765" />
    },
    CALENDAR_MONTHS : [
        <LiMultiterm textId="99004393" textVal="January" />,
        <LiMultiterm textId="99004394" textVal="February" />,
        <LiMultiterm textId="99003719" textVal="March" />,
        <LiMultiterm textId="99015764" textVal="April" />,
        <LiMultiterm textId="99004403" textVal="May" />,
        <LiMultiterm textId="99003715" textVal="June" />,
        <LiMultiterm textId="99012503" textVal="July" />,
        <LiMultiterm textId="99004395" textVal="August" />,
        <LiMultiterm textId="99003716" textVal="September" />,
        <LiMultiterm textId="99004396" textVal="October" />,
        <LiMultiterm textId="99003717" textVal="November" />,
        <LiMultiterm textId="99009316" textVal="December" />
    ]
}

export default function (state = initial, action) {
    switch (action.type) {
        case "SET_RESPONSE_DATA_USER": state = { ...state, myRowArr: action.payload }
            break;

        case "SET_VIEW_DIALOG_USER": state = { ...state, view_open: action.payload }
            break;

        case "SET_ADD_DIALOG_USER": state = { ...state, add_open: action.payload }
            break;

        case "SET_EDIT_DIALOG_USER": state = { ...state, edit_open: action.payload }
            break;

        case "SET_DELETE_DIALOG_USER": state = { ...state, delete_open: action.payload }
            break;

        case "SET_USERID_USER": state = { ...state, userID: action.payload }
            break;

        case "SET_ROWID_USER": state = { ...state, rowID: action.payload }
            break;

        case "SET_CONFIRM_DIALOG_USER": state = { ...state,confirm_open: action.payload }
            break;

        case "SET_USER_ROLES": state = { ...state, userRoleArr: action.payload }
            break;

        case "SET_USER_JOB_TITLES": state = { ...state, userJobTitlesArr: action.payload }
            break;

        case "SET_USER_SITE_OR_DEPT": state = { ...state, siteDeptArr: action.payload }
            break;

        case "SET_USER_DATA_ADD": 
            const tempUserAddFieldObj = state.userAddFieldObj; 
            tempUserAddFieldObj[action.filedIDVar] = action.fieldValVar;
            
            state = { ...state, 
                userAddFieldObj: tempUserAddFieldObj
            }
            break;

        case "RESET_USER_DATA_ADD":
            state = {
                ...state,
                userAddFieldObj: {
                    "userIdField": 0,
                    "loginNameField": "",
                    "lockedYnField": 0,
                    "osuserYnField": 0,
                    "adminYnField": 0,
                    "passwordField": "",
                    "userTypeField": '',
                    "lastNameField": "",
                    "firstNameField": "",
                    "disabledField": 0,
                    "emailField": "",
                    "pwAlgorithmField": "Sha3_512",
                    "personalNumberField": "",
                    "abbreviationField": "",
                    "birthdateField": "",
                    "entityIdField": '',
                    "dateRestrictionField": 0,
                    "dateRestrictionFromField": "",
                    "dateRestrictionToField": "",
                    "hwKeyField" : ""
                },
                userRoleArr: [],
                imageBinaryDataView: ""
            }
            break;

        case "RESET_USER_DATA_ADD_OBJECT":
            state = {
                ...state,
                userAddFieldObj: action.userDataObj
            }
            break;

        case "SET_AVATAR_DATA":
            state = {
                ...state,
                imageBinaryDataView: action.avatadDataVal
            }
            break;

        case "SET_USER_ROLES_DRAG_DROP":

            state = {
                ...state,
                userRolesDragDrop: action.userRoles
            }

            state.tempUserAvailArr = [];
            state.tempUserAssignArr = [];

            state.userRolesDragDrop.forEach((t) => {
                t.category === "Available" ? state.tempUserAvailArr.push(t.id) : state.tempUserAssignArr.push(t.id)
            });
            break;

        case "SET_AVAIL_USER_ROLES_ARR":
            const tempUserAvailArr1 = state.userAvailArr;
            tempUserAvailArr1.push(action.availUser)
            state = {
                ...state,
                userAvailArr: tempUserAvailArr1
            }
            break;

        case "SET_ASSIGN_USER_ROLES_ARR":
            const tempUserAssignArr = state.userAssignArr;
            tempUserAssignArr.push(action.assignUser)
            state = {
                ...state,
                userAssignArr: tempUserAssignArr
            }
            break;

        case "SET_EXISTING_ASSIGN_USER_ROLES_ARR":
            state = {
                ...state,
                userExistingAssignArr: action.existingAssignUser,
                flag: true
            }
            break;

        case "SET_ALL_USER_ARR":
            const tempallUserRoleArr = state.allUserRoleArr;
            tempallUserRoleArr.push(action.allUserVal)
            state = {
                ...state,
                allUserRoleArr: action.allUserVal
            }
            break;

        case "SET_ROLE_DETAIL_OBJ":
            state = {
                ...state,
                allRoleDetail: action.roleDeatilObj,
                flag: true
            }
            break;

        case "SET_ALL_USER_JOB_TITLE":
            var jobTitlesValObj = {};
            Object.keys(action.payload).forEach(function (k, item) {
                jobTitlesValObj[action.payload[k].jobTitleIdField] = action.payload[k].jobTitleDescField;
            });

            state = {
                ...state,
                allUserJobTitles: action.payload,
                jobTitlesFlag: true,
                userJobTitlesDetailObj : jobTitlesValObj
            }
            break;

        case "SET_EACH_USER_JOB_TITLE":
            state = {
                ...state,
                eachUserJobTitles: action.payload,
                jobTitlesFlag: true,
            }
            break;

        case "SET_FULL_EACH_USER_JOB_TITLE":
            state = {
                ...state,
                fullEachUserJobTitles: action.payload,
            }
            break;

        case "SET_USER_JOB_TITLES_DRAG_DROP":
            state = {
                ...state,
                userJobTitlesDragDrop: action.payload
            }

            state.tempUserJobTitlesAvailArr = [];

            state.tempUserJobTitlesAssignArr = [];

            state.userJobTitlesDragDrop.forEach((t) => {
                if (t.category === "Available") {
                    state.tempUserJobTitlesAvailArr.push(t.id)
                }
                else {
                    state.tempUserJobTitlesAssignArr.push(t.id)
                }
            });
            break;

        case "SET_AVAIL_USER_JOB_TITLES_ARR":
            const tempUserJobTitlesAvailArr1 = state.userJobTitlesAvailArr;
            tempUserJobTitlesAvailArr1.push(action.payload)
            state = {
                ...state,
                userJobTitlesAvailArr: tempUserJobTitlesAvailArr1
            }
            break;

        case "SET_ASSIGN_USER_JOB_TITLES_ARR":
            const tempUserJobTitlesAssignArr = state.userJobTitlesAssignArr;
            tempUserJobTitlesAssignArr.push(action.payload)
            state = {
                ...state,
                userJobTitlesAssignArr: tempUserJobTitlesAssignArr
            }
            break;

        default:
            state = {
                ...state
            }
            break;
    }
    return state;
}
