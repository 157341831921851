import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sha3_512 } from 'js-sha3';
import * as UserAction from '../actions/useraction';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_choice_values, OpenApiBody, MimeTypes, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiDivContainer, LiButton, LiLineEdit, LiDatePicker, LiBreadCrumbs, LiSwitch, LiSelectList, LiFileUpload, LiComboBox, LiComboList, LiLabel, LiStatusManager, LiStatusContainer, LiReactDialog, LiForm } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';
import * as usrLib from './usr_lib';

const theme = (conversionLib.getLocalStorageValue("core.app.theme") !== null) ? conversionLib.getLocalStorageValue("core.app.theme") : "LightTheme";
const userAvatarIcon = require('./images/' + theme + '/Avatar.png');

const userAddDataVal = {
    "interfaceInfo": {
        "type": "MT_USER_DATA",
        "version": 1
    },
    "userData": {
        "interfaceInfo": {
            "type": "MT_MASTER_DATA",
            "version": 1,
            "item_type": "ITEM_TYPE_MASTER_USER"
        },
    },
    "avatar": {
        "key": {
            "useCase": 11,
            "dataSource": "avatar",
            "dataType": "CFCT_BINARY"
        },
        "fileName": "asdf.png",
    }
}

class AddUser extends Component {
    constructor() {
        super();

        this.dataObjBreadCrumbsUA = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Users": {
                "label": <LiMultiterm textId="99028960" textVal="Users" />,
                "path": "/users_ng"
            },
            "Add User": {
                "label": <LiMultiterm textId="99025174" textVal="Add User" />,
                "path": "/user_add_ng"
            }
        }

        this.state = {
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",

            allRole: [],
            allJobTitles: [],
            selectedRoleIDs: [],
            selectedJobTitleIDs: [],

            isSubmit: false,
            avatar_upload_open: false,
            comboChoice: {},
            redirectPathUA: '',
            infoDailog: false,
            showInfoDialogUA: false,
            noChoiceAvail: 0,
        };

        this.closeInfoDailog = this.closeInfoDailog.bind(this);
    }

    closeInfoDailog() {
        this.setState({ infoDailog: false })
    }

    callBackHandleUserAddData(responseData) {
        this.props.loader.showLoader({ "POST_Users": false });

        if (responseData.state.response.status === 200 || responseData.state.response.status === 201) {
            this.setState({
                isSubmit: true,
            });

            this.setState({ redirectPathUA: "users_ng" });
        }
        else {
            let errorData = conversionLib.convertToJSON(responseData.state.response.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.detail, this.enableSaveButtonUserAdd.bind(this));

            // LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message, this.enableSaveButtonUserAdd.bind(this));
        }
    }

    enableSaveButtonUserAdd() {
        this.setState({ isSubmit: false });
    }

    handleUserAddData(type, userIdField, allTrue) {

        if (!allTrue) {
            return
        }
        var firstNameFieldUA = this.props.usermgmt.userAddFieldObj.firstNameField;
        var lastNameFieldUA = this.props.usermgmt.userAddFieldObj.lastNameField;
        var loginNameFieldUA = this.props.usermgmt.userAddFieldObj.loginNameField;
        var personalNumberFieldUA = this.props.usermgmt.userAddFieldObj.personalNumberField;
        var abbreviationFieldUA = this.props.usermgmt.userAddFieldObj.abbreviationField;
        var pwAlgorithmFieldUA = this.props.usermgmt.userAddFieldObj.pwAlgorithmField;
        var emailFieldUA = this.props.usermgmt.userAddFieldObj.emailField;
        var osuserYnFieldUA = this.props.usermgmt.userAddFieldObj.osuserYnField;
        var adminYnFieldUA = this.props.usermgmt.userAddFieldObj.adminYnField;
        var lockedYnFieldUA = this.props.usermgmt.userAddFieldObj.lockedYnField;
        var disabledFieldUA = this.props.usermgmt.userAddFieldObj.disabledField;
        var dateRestrictionFieldUA = this.props.usermgmt.userAddFieldObj.dateRestrictionField;
        var birthdateFieldUA = this.props.usermgmt.userAddFieldObj.birthdateField;
        var dateRestrictionFromFieldUA = this.props.usermgmt.userAddFieldObj.dateRestrictionFromField;
        var dateRestrictionToFieldUA = this.props.usermgmt.userAddFieldObj.dateRestrictionToField;
        var userTypeFieldUA = this.props.usermgmt.userAddFieldObj.userTypeField;
        var entityIdFieldUA = this.props.usermgmt.userAddFieldObj.entityIdField;
        var hwKeyFieldUA = this.props.usermgmt.userAddFieldObj.hwKeyField;

        this.setState({ isSubmit: true });

        if (lastNameFieldUA.trim() === "" || firstNameFieldUA.trim() === "" || emailFieldUA.trim() === "" || loginNameFieldUA.trim() === "") {
            this.setState({ showInfoDialogUA: true });
            return;
        }

        if (userTypeFieldUA === -99 && this.state.noChoiceAvail === 0) {
            this.setState({ infoDailog: true });
            return;
        }

        if (userIdField !== '') {
            userAddDataVal.userData.userIdField = userIdField;
        }
        else {
            userAddDataVal.userData.userIdField = -1;
        }

        userAddDataVal.userData.firstNameField = firstNameFieldUA;
        userAddDataVal.userData.lastNameField = lastNameFieldUA;
        userAddDataVal.userData.loginNameField = loginNameFieldUA;
        userAddDataVal.userData.personalNumberField = personalNumberFieldUA;
        userAddDataVal.userData.abbreviationField = abbreviationFieldUA;
        userAddDataVal.userData.pwAlgorithmField = pwAlgorithmFieldUA;
        userAddDataVal.userData.emailField = emailFieldUA;
        userAddDataVal.userData.osuserYnField = osuserYnFieldUA;
        userAddDataVal.userData.adminYnField = adminYnFieldUA;
        userAddDataVal.userData.lockedYnField = lockedYnFieldUA;
        userAddDataVal.userData.disabledField = disabledFieldUA;
        userAddDataVal.userData.hwKeyField = hwKeyFieldUA;
        userAddDataVal.userData.dateRestrictionField = dateRestrictionFieldUA;

        if (dateRestrictionFieldUA !== 0) {
            userAddDataVal.userData.dateRestrictionFromField = dateRestrictionFromFieldUA;
            userAddDataVal.userData.dateRestrictionToField = dateRestrictionToFieldUA;
        }
        userAddDataVal.userData.birthdateField = birthdateFieldUA;
        userAddDataVal.userData.userTypeField = userTypeFieldUA;

        if (entityIdFieldUA !== 0) {
            userAddDataVal.userData.entityIdField = entityIdFieldUA;
        }

        if (this.editor) {
            var canvasData = this.editor.getImageScaledToCanvas().toDataURL();
            userAddDataVal.avatar.data = canvasData.replace(/^data:image\/[a-z]+;base64,/, "");
        }

        if (this.props.usermgmt.imageBinaryDataView !== '') {
            userAddDataVal.avatar.data = this.props.usermgmt.imageBinaryDataView.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        else {
            userAddDataVal.avatar.data = this.props.usermgmt.imageBinaryDataView;
        }

        var finalRoleArrUA = [];
        if (this.state.selectedRoleIDs.length > 0) {
            this.state.selectedRoleIDs.forEach((eachRoleID, index) => {
                var eachRoleObjUA = {
                    "roleIdField": parseInt(eachRoleID),
                    "userIdField": userIdField,
                    // "masterRoleSubdata": this.state.assignedRoleDetails[parseInt(eachRoleID)],
                    "interfaceInfo": {
                        "type": "MT_MASTER_DATA",
                        "version": 0,
                        "itemType": "ITEM_TYPE_MASTER_USER_ROLES"
                    }
                };

                finalRoleArrUA.push(eachRoleObjUA);
            });
        }

        userAddDataVal.userData.masterUserRolesSubdata = finalRoleArrUA;

        var finalJobTitleArrUA = [];
        if (this.state.selectedJobTitleIDs.length > 0) {
            var cntr = 1;
            this.state.selectedJobTitleIDs.forEach((eachJobTitleID, index) => {
                var eachJobTitleObjUA = {
                    "jobTitleIdField": parseInt(eachJobTitleID),
                    "userIdField": userIdField,
                    "fromDateField": "2018/08/08",
                    "jobTitleSeqField": cntr,
                    // "masterJobTitleSubdata": this.state.assignedJobTitleDetails[parseInt(eachJobTitleID)],
                    "interfaceInfo": {
                        "type": "MT_MASTER_DATA",
                        "version": 0,
                        "itemType": "ITEM_TYPE_MASTER_USER_JOB_TITLE"
                    }
                };

                finalJobTitleArrUA.push(eachJobTitleObjUA);
                cntr++;
            });
        }

        userAddDataVal.userData.masterUserJobTitleSubdata = finalJobTitleArrUA;

        if (type === 1) {
            var passwordField = this.props.usermgmt.userAddFieldObj.passwordField;

            if (passwordField.trim() === "") {
                this.setState({ showInfoDialogUA: true });
                return;
            }


            var arrayBuffer = sha3_512.arrayBuffer(passwordField);
            var base64StringPassword = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))

            userAddDataVal.userData.passwordField = base64StringPassword;

            this.props.loader.showLoader({ "POST_Users": true });

            const key = "usermgmt.userlist.postusers";
            window.downloadManager[key] = { 'name': null, "status": 1, "cancel": '' };


            let siteName = getSiteName();
            OpenApiClient_ng_user_management_v1.getClient(siteName).POST_Users(this.callBackHandleUserAddData.bind(this), new OpenApiBody(MimeTypes.MIME_APPLICATION_JSON, userAddDataVal), true, key);
        }
    }

    callbackForAllSiteOrDeptUserAdd(responseDataSiteDepUA) {
        this.props.loader.showLoader({ "GET_Sites": false });

        if (responseDataSiteDepUA.state.response.status === 200) {
            var jsonDataSiteDepUA = conversionLib.convertToJSON(responseDataSiteDepUA.state.response.data);
            if (jsonDataSiteDepUA !== undefined && Object.keys(jsonDataSiteDepUA).length !== 0) {
                var tempArrSiteDepUA = {};
                Object.keys(jsonDataSiteDepUA.companyEntities).forEach(function (k, item) {
                    tempArrSiteDepUA[jsonDataSiteDepUA.companyEntities[k].entityIdField] = jsonDataSiteDepUA.companyEntities[k].entityDescField
                })

                this.props.setUserSiteOrDept(tempArrSiteDepUA);
            }
            // let siteName = getSiteName();
            // OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserAdd.bind(this),['workstation']);
        }
        else {
            let errorData = conversionLib.convertToJSON(responseDataSiteDepUA.state.response.data);
            LiStatusManager.showStatus(responseDataSiteDepUA.state.response.status, errorData.detail);
            // LiStatusManager.showStatus(responseDataSiteDepUA.state.response.status, responseDataSiteDepUA.state.response.message);
        }
    }

    callbackWithArgForRoleObjUserAdd(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);

            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var tempRolesDetArr = []
                var tempAllRoleArr = {};
                var jsonRoles = jsonData.roles;

                jsonRoles.forEach((eachRole) => {
                    var tempAllRoleArr1 = {};
                    tempAllRoleArr1['desc'] = eachRole.roleDescField;
                    tempAllRoleArr1['checked'] = false;
                    tempAllRoleArr1['subdata'] = this.props.usermgmt.siteDeptArr[eachRole.entityIdField] !== undefined ? this.props.usermgmt.siteDeptArr[eachRole.entityIdField] : '-------';
                    tempRolesDetArr.push(eachRole)
                    tempAllRoleArr[eachRole.roleIdField] = tempAllRoleArr1;
                })
                this.props.setAllUserArr(tempRolesDetArr);
                this.setState({ allRole: tempAllRoleArr });
            }
        }
        else {
            let errorData = conversionLib.convertToJSON(responseData.state.response.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.detail);
            // LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForJobTitlesObjUserAdd(responseData) {
        this.props.loader.showLoader({ "GET_Sites": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                var tempJobTitlesDetArr = []
                var tempJobTitlesArr = {};

                var jsonJobTitles = jsonData.jobTitles;
                jsonJobTitles.forEach((eachJobTitles) => {
                    var tempJobTitlesArr1 = {};
                    tempJobTitlesArr1['desc'] = eachJobTitles.jobTitleDescField;
                    tempJobTitlesArr1['checked'] = false;
                    tempJobTitlesDetArr.push(eachJobTitles)
                    tempJobTitlesArr[eachJobTitles.jobTitleIdField] = tempJobTitlesArr1;
                })

                this.props.setAllUserJobTitle(tempJobTitlesDetArr);
                this.setState({ allJobTitles: tempJobTitlesArr });
            }
        }
        else {
            let errorData = conversionLib.convertToJSON(responseData.state.response.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.detail);
            // LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    // callbackWithChoices = (responseData) => {
    //     if (responseData.state.response.status === 200) {
    //         var result = conversionLib.convertToJSON((responseData.state.response.data));
    //         this.setChoices(result.choice);
    //     }
    //     else {
    //         let errorData = conversionLib.convertToJSON(responseData.state.response.data);
    //         LiStatusManager.showStatus(responseData.state.response.status, errorData.detail);
    //         this.setState({noChoiceAvail : 1})
    //         // LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
    //     }
    // }

    // setChoices = (choices) => {
    //     if (choices[0].choiceEntry !== undefined) {
    //         var choiceArr = {};
    //         choices[0].choiceEntry.forEach((ChoiceText, index) => {
    //             choiceArr[ChoiceText.keyInt] = [ChoiceText.displayToken[0].tokenTxtfallback,ChoiceText.displayToken[0].tokenTxtno]
    //         })
    //     }
    //     this.setState({comboChoice : choiceArr})
    // }

    componentDidMount() {
        this.props.loader.showLoader({ "GET_Sites": true });

        this.props.resetUserDataAdd();

        let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2107']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackForAllSiteOrDeptUserAdd.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_JobTitles(this.callbackWithArgForJobTitlesObjUserAdd.bind(this));
    }

    userTypeMenuItems() {

        // 2107 - 0 = "Default"                 99001446
        // 2107 - 1 = "Shopfloor Operator"      99030183
        // 2107 - 2 = "Shopfloor Workstation"   99030184


        // return Object.keys(this.state.comboChoice).map((name) => (
        //     <LiComboList key={name} value = {parseInt(name)} > {<LiMultiterm textId={String(this.state.comboChoice[name][1])} textVal={this.state.comboChoice[name][0]} />}</LiComboList>
        // ));
    }

    enitityMenuItemsUserAdd() {
        return Object.keys(this.props.usermgmt.siteDeptArr).map((name) => (
            <LiComboList key={name} value={parseInt(name)}> {this.props.usermgmt.siteDeptArr[name]}</LiComboList>
        ));
    }

    handleChangeUserTypeComboUserAdd = (value, name, event) => {
        this.props.setUserDataAdd('userTypeField', parseInt(value));
        this.setState({ allRole: [] });

        var roleTypeForUser = 'default';
        if (parseInt(value[0]) === 1) {
            roleTypeForUser = 'operator';
        }

        let siteName = getSiteName();
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles(this.callbackWithArgForRoleObjUserAdd.bind(this), [roleTypeForUser]);
    }

    handleChangeEntityComboUserAdd = (value, name, event) => {
        this.props.setUserDataAdd('entityIdField', parseInt(value));
    }

    handleChangeUserAdd(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked === true ? 1 : 0) : (target.value);
        const name = event.target.name;
        this.props.setUserDataAdd(name, value);
    }

    handleBirtDateChangeUserAdd = (date) => {
        this.props.setUserDataAdd('birthdateField', date);
    }

    handleDateRestrictionFromChangeUserAdd = (date) => {
        this.props.setUserDataAdd('dateRestrictionFromField', usrLib.formatDate(date));
    }

    handleDateRestrictionToChangeUserAdd = (date) => {
        this.props.setUserDataAdd('dateRestrictionToField', usrLib.formatDate(date));
    }

    formateTheDefaultDate = (date) => {
        usrLib.formatDate(date)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : ""
        });
    }


    handleUserRoleUserAdd(ev, value, isCheckall, tempp, allAvailable, currentStatus) {

        let roleIDArrUA = this.state.selectedRoleIDs;
        if (isCheckall === 1) {
            roleIDArrUA = allAvailable;
        }
        else if (isCheckall === 0) {
            roleIDArrUA = [];
        }
        else {
            if (currentStatus) {
                roleIDArrUA.push(parseInt(value)); // add item
            }
            else {
                let index = roleIDArrUA.indexOf(parseInt(value));
                if (index > -1) {
                    roleIDArrUA.splice(index, 1); // remove item
                }
            }
        }

        this.setState({ selectedRoleIDs: roleIDArrUA });
    }

    handleJobTitlesUserAdd(ev, value, isCheckall, tempp, allAvailable, currentStatus) {

        let jobTitleIDArrUA = this.state.selectedJobTitleIDs;
        if (isCheckall === 1) {
            jobTitleIDArrUA = allAvailable;
        }
        else if (isCheckall === 0) {
            jobTitleIDArrUA = [];
        }
        else {
            if (currentStatus) {
                jobTitleIDArrUA.push(parseInt(value)); // add item
            }
            else {
                let index = jobTitleIDArrUA.indexOf(parseInt(value));
                if (index > -1) {
                    jobTitleIDArrUA.splice(index, 1); // remove item
                }
            }
        }

        this.setState({ selectedJobTitleIDs: jobTitleIDArrUA });
    }

    onUploadCompleted = (file, content) => {
    }

    onAvatarUploaded = (file, content) => {
    }

    getAvatarImageUserAdd = (image) => {
        this.props.setAvatarData(image.replace(/^data:image\/[a-z]+;base64,/, ""));
        this.setState({
            avatar_upload_open: false
        });
    }

    handleUploadImageOpenUserAdd() {
        this.setState({
            avatar_upload_open: true,
        });
    }

    handleUploadImageCloseUserAdd() {
        this.setState({
            avatar_upload_open: false

        });
    }

    handleCloseResponseUserAdd() {
        this.setState({ isSubmit: false });
        this.setState({ showInfoDialogUA: false });
    }

    render() {
        const stateRequiredUA = [
            { "FirstName": [<LiMultiterm textId="99024423" textVal="First Name" />, this.props.usermgmt.userAddFieldObj.firstNameField] },
            { "LastName": [<LiMultiterm textId="99024422" textVal="Last Name" />, this.props.usermgmt.userAddFieldObj.lastNameField] },
            { "LoginName": [<LiMultiterm textId="99010629" textVal="Login Name" />, this.props.usermgmt.userAddFieldObj.loginNameField] },
            { "Password": [<LiMultiterm textId="6001858" textVal="Password" />, this.props.usermgmt.userAddFieldObj.passwordField] },
            { "Email": [<LiMultiterm textId="99021171" textVal="Email" />, this.props.usermgmt.userAddFieldObj.emailField] },
            { "UserType": [<LiMultiterm textId="99024421" textVal="User Type" />, this.props.usermgmt.userAddFieldObj.userTypeField] },
        ]

        return (
            <div>
                {this.state.redirectPathUA !== undefined && this.state.redirectPathUA !== '' ? <Redirect to={this.state.redirectPathUA} /> : null}
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsUA} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <LiDivContainer containerHeight={parseInt((window.innerHeight - 10) - 200)} containerID="LiUsrMgmtUserAddSub1" containerScrollY={true} containerScrollX={false} >

                    <div className={this.state.theme + " userMgmtGridContainer"} >
                        <div className="userMgmtGridContainerItem" style={{ textAlign: 'center' }}>
                            <img id="userAva" alt="avatar" src={this.props.usermgmt.imageBinaryDataView !== '' ? `data:image/jpeg;base64,${this.props.usermgmt.imageBinaryDataView}` : userAvatarIcon} border="0" onClick={this.handleUploadImageOpenUserAdd.bind(this)} />
                        </div>
                        <div className="userMgmtGridContainerItem">
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem" style={{ verticalAlign: 'center' }}>
                                    <LiLabel labelText={<LiMultiterm textId="99024423" textVal="First Name" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="firstNameField" liEditName="firstNameField" liEditValue={this.props.usermgmt.userAddFieldObj.firstNameField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99024422" textVal="Last Name" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="lastNameField" liEditName="lastNameField" liEditValue={this.props.usermgmt.userAddFieldObj.lastNameField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                            </div>

                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99010629" textVal="Login Name" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="loginNameField" liEditName="loginNameField" liEditValue={this.props.usermgmt.userAddFieldObj.loginNameField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="6001858" textVal="Password" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="passwordField" liEditName="passwordField" liEditValue={this.props.usermgmt.userAddFieldObj.passwordField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} liPassword={true} />
                                </div>
                            </div>

                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99028908" textVal="Personnel Number" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="personalNumberField" liEditName="personalNumberField" liEditValue={this.props.usermgmt.userAddFieldObj.personalNumberField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99023790" textVal="Abbreviation" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="abbreviationField" liEditName="abbreviationField" liEditValue={this.props.usermgmt.userAddFieldObj.abbreviationField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                {/* <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText="Password Algorithm" />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="pwAlgorithmField" liEditName="pwAlgorithmField" liEditValue={this.props.usermgmt.userAddFieldObj.pwAlgorithmField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)}  liReadOnly={true} />
                                </div> */}
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99021171" textVal="Email" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="emailField" liEditName="emailField" liEditValue={this.props.usermgmt.userAddFieldObj.emailField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>

                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99028796" textVal="Hardware ID" />} />
                                </div>

                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLineEdit liEditID="hwKeyField" liEditName="hwKeyField" liEditPlaceholder="" liEditValue={this.props.usermgmt.userAddFieldObj.hwKeyField} liEditOnChange={this.handleChangeUserAdd.bind(this)} liEditOnKeyUp={this.handleChangeUserAdd.bind(this)} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99028910" textVal="OS User" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiSwitch switchName="osuserYnField" switchId="osuserYnField" switchChecked={this.props.usermgmt.userAddFieldObj.osuserYnField} switchDisabled={false} switchVal="osuserYnField" switchOnChangeHandler={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99004030" textVal="Administrator" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiSwitch switchName="adminYnField" switchId="adminYnField" switchChecked={this.props.usermgmt.userAddFieldObj.adminYnField} switchDisabled={false} switchVal="adminYnField" switchOnChangeHandler={this.handleChangeUserAdd.bind(this)} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99028585" textVal="Locked" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiSwitch switchName="lockedYnField" switchId="lockedYnField" switchChecked={this.props.usermgmt.userAddFieldObj.lockedYnField} switchDisabled={false} switchVal="lockedYnField" switchOnChangeHandler={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99020812" textVal="Disabled" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiSwitch switchName="disabledField" switchId="disabledField" switchChecked={this.props.usermgmt.userAddFieldObj.disabledField} switchDisabled={false} switchVal="disabledField" switchOnChangeHandler={this.handleChangeUserAdd.bind(this)} />
                                </div>
                            </div>
                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99012483" textVal="Date Restriction" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiSwitch switchName="dateRestrictionField" switchId="dateRestrictionField" switchChecked={this.props.usermgmt.userAddFieldObj.dateRestrictionField} switchDisabled={false} switchVal="dateRestrictionField" switchOnChangeHandler={this.handleChangeUserAdd.bind(this)} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99028911" textVal="Date of Birth" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiDatePicker dateFormat="YYYY/MM/DD" id="birthdateField" onDateChanged={this.handleBirtDateChangeUserAdd.bind(this)} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                </div>
                            </div>

                            {
                                this.props.usermgmt.userAddFieldObj.dateRestrictionField === 1 ?
                                    <div className="userMgmtGridContainerInner">
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiLabel labelText={<LiMultiterm textId="99028926" textVal="Date Restriction From" />} />
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiDatePicker id="daterestrictionfrom" onDateChanged={this.handleDateRestrictionFromChangeUserAdd.bind(this)} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiLabel labelText={<LiMultiterm textId="99029990" textVal="Date restriction to" />} />
                                        </div>
                                        <div className="userMgmtGridContainerInnerItem">
                                            <LiDatePicker id="daterestrictionto" onDateChanged={this.handleDateRestrictionToChangeUserAdd.bind(this)} calendarMonths={this.props.usermgmt.CALENDAR_MONTHS} weekdays={this.props.usermgmt.WEEK_DAYS} todayLabel={<LiMultiterm textId="99001693" textVal="Today" />} />
                                        </div>
                                    </div>
                                    :
                                    ''
                            }

                            <div className="userMgmtGridContainerInner">
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} isMandatory={true} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiComboBox
                                        value={this.props.usermgmt.userAddFieldObj.userTypeField}
                                        id="userTypeField"
                                        name="userTypeField"
                                        multiSelect={false}
                                        onSelection={this.handleChangeUserTypeComboUserAdd.bind(this)}
                                        defaultText={<LiMultiterm textId="1000875" textVal="Select" />}
                                    >

                                        <LiComboList key={"0"} value={"0"}>{<LiMultiterm textId="99001446" textVal="Default" />}</LiComboList>
                                        <LiComboList key={"1"} value={"1"}>{<LiMultiterm textId="99030183" textVal="Shopfloor Operator" />}</LiComboList>
                                        <LiComboList key={"2"} value={"2"}>{<LiMultiterm textId="99030184" textVal="Shopfloor Workstation" />}</LiComboList>

                                        {/* {this.userTypeMenuItems()}    */}
                                    </LiComboBox>
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} />
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <LiComboBox value={this.props.usermgmt.userAddFieldObj.entityIdField} id="entityIdField" name="entityIdField" multiSelect={false} onSelection={this.handleChangeEntityComboUserAdd.bind(this)} defaultText={<LiMultiterm textId="1000875" textVal="Select" />}>
                                        {this.enitityMenuItemsUserAdd()}
                                    </LiComboBox>
                                </div>
                            </div>

                            <div className="userMgmtGridContainerInnerSub">
                                <div className="userMgmtGridContainerInnerItem">
                                    &#160;
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    <br />
                                    {/* {Object.keys(this.state.allRole).length > 0 ? */}
                                    <LiSelectList selectlistdata={this.state.allRole} selectListName="selectlist_roles" selectListTitle={<LiMultiterm textId="99029991" textVal="User roles" />} selectListonChange={this.handleUserRoleUserAdd.bind(this)} />
                                    {/* // :
                                    // ''
                                    // } */}
                                    <br />
                                    {/* {Object.keys(this.state.allJobTitles).length > 0 ? */}
                                    {/* <LiSelectList selectlistdata={this.state.allJobTitles} selectListName = "selectlist_job_titles" selectListTitle={<LiMultiterm textId="99029988" textVal="User Job Titles" />} selectListonChange={this.handleJobTitlesUserAdd.bind(this)} /> */}
                                    {/* :
                                    ''
                                    } */}
                                </div>
                                <div className="userMgmtGridContainerInnerItem">
                                    &#160;
                                </div>
                            </div>
                        </div>
                    </div>
                </LiDivContainer>

                <LiDivContainer containerHeight="70" containerID="LiUsrMgmtUserAddSub2" containerScrollY={false} containerScrollX={false} >
                    <hr className={this.state.theme + " LiUsrMgmtBottom"} />

                    <div className="userMgmtGridContainerFull">
                        <div className="userMgmtGridContainerFullItem">
                            {/* <LiButton
                                buttonOnClickHandler={this.handleUserAddData.bind(this, 1, -1)}
                                buttonLabel={<LiMultiterm textId="6001485" textVal="Save" />}
                            /> */}
                            <LiForm
                                height={350}
                                formDialogTitle={<LiMultiterm textId="4000070" textVal="Warning" />}
                                formDialogCloseText={<LiMultiterm textId="99000136" textVal="OK" />}
                                requiredByState={stateRequiredUA}
                                requiredById={[]}
                                allRequiredFilled={this.handleUserAddData.bind(this, 1, -1)}
                                buttonLabel={<LiMultiterm textId="6001485" textVal="Save" />}
                                formMessage={<LiMultiterm textId="99030257" textVal="Please fill all the required fields" />}
                            />
                            <Link to="/users_ng">
                                <LiButton
                                    buttonLabel={<LiMultiterm textId="6002006" textVal="Cancel" />}
                                />
                            </Link>
                        </div>
                    </div>

                    {
                        this.state.avatar_upload_open
                            ?
                            <LiReactDialog
                                enableStoring="li.user.avatar.upload"
                                title={<LiMultiterm textId="99031019" textVal="Upload Avatar" />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                modal={true}
                                hasCloseIcon={true}
                                onClose={this.handleUploadImageCloseUserAdd.bind(this)}
                                isResizable={true}
                                isDraggable={true}
                                height={650}
                                width={700}
                                buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="1001439" textVal="Close" />,
                                            onClick: () => this.handleUploadImageCloseUserAdd()
                                        }
                                    ]
                                }
                            >
                                <div className="LiuserEditMainDiv" style={{ width: "90%", margin: "auto" }}>
                                    <LiFileUpload
                                        onUploadCompleted={this.onUploadCompleted.bind(this)}
                                        openavatar={true}
                                        onAvatarUploaded={this.onAvatarUploaded.bind(this)}
                                        fileFormat={['image/gif', 'image/jpeg', 'image/png']}
                                        isMultiple={false} 
                                        theme={this.state.theme}
                                        getAvatarImage={this.getAvatarImageUserAdd.bind(this)}
                                    />

                                </div>
                            </LiReactDialog>
                            :
                            ""
                    }

                    {
                        this.state.infoDailog
                            ?
                            <LiReactDialog
                                type="Response"
                                enableStoring="li.user.add.infodailog"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.closeInfoDailog()
                                    }]
                                }
                                onClose={this.closeInfoDailog.bind(this)}
                                isFooterEnable={false}
                            >
                                <p><LiMultiterm textId="99030935" textVal="Select user type" /></p>
                            </LiReactDialog>
                            :
                            ""
                    }
                    {
                        this.state.showInfoDialogUA
                            ?
                            <LiReactDialog
                                enableStoring="server.response.user.add"
                                title={<LiMultiterm textId="99028766" textVal="Server Response" />}
                                modal={true}
                                isResizable={false}
                                hasCloseIcon={false}
                                width={300}
                                height={200}
                                buttons={
                                    [{
                                        text: <LiMultiterm textId="99000136" textVal="OK" />,
                                        onClick: () => this.handleCloseResponseUserAdd()
                                    }]
                                }
                                onClose={this.handleCloseResponseUserAdd.bind(this)}
                                isFooterEnable={false}
                            >
                                <p><LiMultiterm textId="99030257" textVal="Please fill all the required fields" />.</p>
                                <p><LiMultiterm textId="99030605" textVal="Mandatory fields are marked with" /> <span className="user_mandatoryFieldNote">*</span></p>
                            </LiReactDialog>
                            :
                            ""
                    }

                    <LiStatusContainer />

                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usermgmt: state.usermgmt
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setConfirmDialog: UserAction.setConfirmDialog,
        setUserRole: UserAction.setUserRole,
        setUserJobTitles: UserAction.setUserJobTitles,
        setUserDataAdd: UserAction.setUserDataAdd,
        resetUserDataAdd: UserAction.resetUserDataAdd,
        setAvatarData: UserAction.setAvatarData,
        setAllUserArr: UserAction.setAllUserArr,
        setAllUserJobTitle: UserAction.setAllUserJobTitle,
        setUserSiteOrDept: UserAction.setUserSiteOrDept

    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AddUser);