import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RolesAction from '../actions/rolesaction';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_choice_values, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { LiBreadCrumbs, LiDivContainer, LiLabel, LiStatusManager, LiStatusContainer } from 'liseccomponents';
import lightUserIcon from './images/LightTheme/user_management.svg';
import darkUserIcon from './images/DarkTheme/user_management.svg';

const choiceObject = {
    0: ['Default', 99001446],
    1: ['Shopfloor Operator', 99030183],
};

const baseUrl = window.location;
class RoleView extends Component {

    constructor() {
        super();
        this.state = {
            currentRoleDataIndividual: {},
            theme: conversionLib.getLocalStorageValue("core.app.theme") !== null ? conversionLib.getLocalStorageValue("core.app.theme") : "",
            comboChoice: {},
            addedRolesAdGroups: []
        };

        this.dataObjBreadCrumbsRV = {
            "User Management": {
                "label": <LiMultiterm textId="99005135" textVal="User Management" />,
                "path": "/usermain_ng"
            },
            "Roles": {
                "label": <LiMultiterm textId="99029981" textVal="Roles" />,
                "path": "/roles_ng"
            },
            "View Details": {
                "label": <LiMultiterm textId="99005780" textVal="View Details" />,
                "path": "#"
            }
        }
    }

    callbackGetRoleRoleView(responseData) {
        this.props.loader.showLoader({ "GET_Role_roleId": false });

        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (jsonData !== undefined && Object.keys(jsonData).length !== 0) {
                this.setState({
                    currentRoleDataIndividual: jsonData,
                });
            }
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    callbackWithArgForEntityComboRoleView(responseDataEntityComboRV) {
        this.props.loader.showLoader({ "GET_Role_roleId": false });

        if (responseDataEntityComboRV.state.response.status === 200) {
            var jsonDataEntityComboRV = conversionLib.convertToJSON(responseDataEntityComboRV.state.response.data);
            if (jsonDataEntityComboRV !== undefined && Object.keys(jsonDataEntityComboRV).length !== 0) {
                var tempArr = {};
                Object.keys(jsonDataEntityComboRV.companyEntities).forEach(function (k, item) {
                    tempArr[jsonDataEntityComboRV.companyEntities[k].entityIdField] = jsonDataEntityComboRV.companyEntities[k].entityDescField
                })
                this.props.setUserSiteOrDept(tempArr);
            }
        }
        else {
            LiStatusManager.showStatus(responseDataEntityComboRV.state.response.status, responseDataEntityComboRV.state.response.message);
        }
    }

    componentDidMount() {
        var roleIDRV = (conversionLib.getParameterByName('roleid', baseUrl.href) !== null && conversionLib.getParameterByName('roleid', baseUrl.href) !== '') ? conversionLib.getParameterByName('roleid', baseUrl.href) : 2;
        this.props.loader.showLoader({ "GET_Role_roleId": true });

        let siteName = getSiteName();
        // OpenApiClient_choice_values.getClient(siteName).GET_ComboChoices(this.callbackWithChoices.bind(this), ['2108']);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Roles_roleId(this.callbackGetRoleRoleView.bind(this), roleIDRV);
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_Sites(this.callbackWithArgForEntityComboRoleView.bind(this));
        OpenApiClient_ng_user_management_v1.getClient(siteName).GET_RoleAdGroups(this.callbackRolesAdGroupRoleView.bind(this), roleIDRV);
    }

    callbackRolesAdGroupRoleView(responseData) {
        if (responseData.state.response.status === 200) {
            var jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                addedRolesAdGroups: jsonData
            })
        }
        else {
            LiStatusManager.showStatus(responseData.state.response.status, responseData.state.response.message);
        }
    }

    render() {
        return (
            <div>
                <LiBreadCrumbs breadcrumbsdataObj={this.dataObjBreadCrumbsRV} rootImage={this.state.theme === 'LightTheme' ? lightUserIcon : darkUserIcon} />
                <br />

                <LiDivContainer containerHeight={parseInt(window.innerHeight - 150)} containerID="role.view" containerScrollY={true} containerScrollX={true} >
                    <div className="twoColumnMainGridContainer">
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99001692" textVal="ID #" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentRoleDataIndividual.roleIdField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="6001104" textVal="Name" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentRoleDataIndividual.roleDescField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99002657" textVal="Short Description" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentRoleDataIndividual.roleShdescField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99024421" textVal="User Type" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            {/* <LiLabel labelText={this.state.currentRoleDataIndividual.userTypeField !== undefined ? this.state.comboChoice[this.state.currentRoleDataIndividual.userTypeField] : '--'} /> */}

                            <LiLabel labelText={this.state.currentRoleDataIndividual.userTypeField !== undefined ? <LiMultiterm textId={String(choiceObject[this.state.currentRoleDataIndividual.userTypeField][1])} textVal={choiceObject[this.state.currentRoleDataIndividual.userTypeField][0]} /> : '--'} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99023501" textVal="Disabled" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.state.currentRoleDataIndividual.disabledField} />
                        </div>

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="" textVal="AD Groups" />} />
                        </div>

                        {
                            this.state.addedRolesAdGroups !== undefined ?
                                <div className="twoColumnMainGridContainerItem">
                                    {this.state.addedRolesAdGroups.map(data => {
                                        return <div className="RoleGroupADList"><LiLabel labelText={data.azureAdGroupName} /></div>
                                    })}
                                </div>
                                :
                                <div></div>
                        }

                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={<LiMultiterm textId="99027118" textVal="Production Sites" />} />
                        </div>
                        <div className="twoColumnMainGridContainerItem">
                            <LiLabel labelText={this.props.rolestore.siteDeptArr[this.state.currentRoleDataIndividual.entityIdField]} />
                        </div>

                    </div>

                    <LiStatusContainer />

                </LiDivContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rolestore: state.rolestore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserSiteOrDept: RolesAction.setUserSiteOrDept,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleView);
