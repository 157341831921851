import {combineReducers } from 'redux';
import UserStore        from './userstore';
import SiteStore        from './sitestore';
import RoleStore        from './rolestore';

const allReducers = combineReducers({
    usermgmt        : UserStore,
    sitemgmt        : SiteStore,
    rolestore       : RoleStore,
});

export default allReducers;